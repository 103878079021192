import React from "react";
import history from "siteHistory";

import { HyperlinkGoto, TelephoneNumber } from "siteComponents";

import BaseCard from "./BaseCard";

const OpexCard = (props) => {
	const { opexData, opexId, showPolicyTypes } = props;

	const data = opexData != undefined ? opexData : require("siteAssets/datafiles/opexData").default(opexId);

	const onClick = () => history.push(data.route);

	return (
		<BaseCard
			key={opexId}
			cardClasses="opex-card h-100"
			cardBodyOnClick={onClick}
			cardHeaderOnClick={onClick}
			headerClasses="clickable"
			headerContent={
				<>
					<span className="d-block rounded-icon">
						<span className={`opex-icon icon-opex-${data.groupCode}`}></span>
					</span>
					<span className="text-grey">
						{data.heading}
					</span>
				</>
            }
			cardBodyClasses="pb-0 clickable"
			cardContent={
				<div className="text-center">
					<div className="opex-description mb-3">{data.summary}</div>
					{showPolicyTypes && (
						<div>
							Available for: {data.policyTypes.join(" & ").replace("PC", "Car").replace("GV", "Van")}
						</div>
					)}
					
				</div>
			}
			footerClasses="pb-3"
			footerContent={
				<>
					<div className="mb-3">
						<TelephoneNumber
							phonenumber={data.claimNumbers[0]}
							isLink={true}
							altLabel={`Call ${data.claimNumbers[0]}`}
							className="text-black"
						/>
					</div>
					<HyperlinkGoto className="card-link" route={data.route}>
						Find out more
					</HyperlinkGoto>
				</>
			}

		/>		
	);
};

export default OpexCard;