import React from "react";

import { TickList } from "siteComponents";

const WhyChooseUs = (props) => {
	const { isCorporate = false } = props;

	const title = isCorporate
		? "Why Freedom Brokers?"
		: "Why choose Freedom Brokers?";

	const listData = isCorporate
		? [
				"Policies that are tailor-made for your customers",
				"Access to a panel of leading insurers",
				"An industry-leading claims management service",
				"Online self-service capability",
		  ]
		: [
				"24-hour claims helpline.", //#2724
				"A fantastic range of policy upgrades, including roadside breakdown, for added peace of mind.",
				"Straight-forward, uncomplicated insurance with a first class claims service.",
				"Policies tailor-made for you with access to a range of insurers.",
				"Access to your personal online account to make changes to your policy and view your policy documents, 24 hours a day, 365 days a year.",
		  ];

	return (
		<>
			<h2 className="text-purple h3 pb-3">{title}</h2>
			<TickList className="gap-3 ps-3 pe-4" listData={listData} />
		</>
	);
};

export default WhyChooseUs;
