// https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-to-a-single-page-application

import React from 'react';
import { ExternalLink } from "siteComponents";

const TrustBoxx = (props) => {

    // Create a reference to the <div> element which will represent the TrustBox
    const ref = React.useRef(null);
    React.useEffect(() => {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(ref.current, true);
        }
    }, []);

    const { template, theme = "light" } = props;
    // TrustBox widgets: https://businessapp.b2b.trustpilot.com/trustbox/

    const fallbackLink = (<ExternalLink href="https://www.trustpilot.com/review/freedombrokers.co.uk" label="Trustpilot" />);

    const templateToUse = !template ? "" : template;

    switch (templateToUse) {
        case "mini": {
            return (
                <div
                    ref={ref} // We need a reference to this element to load the TrustBox in the effect.
                    className="trustpilot-widget tb-mini" // Renamed this to className.
                    data-locale="en-GB"
                    data-businessunit-id="5c9e9a640668fb00011f055a"

                    data-template-id="53aa8807dec7e10d38f59f32"
                    data-style-height="120px"
                    data-style-width="100%"
                    data-theme={theme}
                    data-stars="4,5"
                    data-review-languages="en"
                >
                    {fallbackLink}
                </div>
            );
        }

        case "microstar": {
            return (
                <div className="tb-microstar">
                    <div
                        ref={ref}
                        className="trustpilot-widget"
                        data-locale="en-GB"
                        data-template-id="5419b732fbfb950b10de65e5"
                        data-businessunit-id="5c9e9a640668fb00011f055a"
                        data-style-height="24px"
                        data-style-width="100%"
                        data-theme={theme}
                        data-stars="4,5"
                        data-review-languages="en"
                    >
                        {fallbackLink}
                    </div>

                </div>
            );
        }

        case "stars": {
            return (
                <div
                    ref={ref} // We need a reference to this element to load the TrustBox in the effect.
                    className="trustpilot-widget tb-stars" // Renamed this to className.
                    data-locale="en-GB"
                    data-businessunit-id="5c9e9a640668fb00011f055a"

                    data-template-id="53aa8912dec7e10d38f59f36"
                    data-style-height="200px"
                    data-style-width="100%"
                    data-theme={theme}
                    data-stars="4,5"
                    data-review-languages="en"
                >
                    {fallbackLink}
                </div>
            );
        }
    }

    return (
        // Uses the List trustbox layout, but hides the reviews by setting the wrapper to 10px smaller
        // than the widget and hiding the overflow.
        <div className="tb-list-hr">
            <div
                ref={ref} // We need a reference to this element to load the TrustBox in the effect.
                className="trustpilot-widget" // Renamed this to className.
                data-locale="en-GB"
                data-businessunit-id="5c9e9a640668fb00011f055a"

                data-template-id="539ad60defb9600b94d7df2c"
                data-style-height="160px"
                data-style-width="100%"
                data-theme={theme}
                data-stars="4,5"
                data-review-languages="en"
            >
                {fallbackLink}
            </div>
        </div>
    );
};
export default TrustBoxx;