import React, { useState } from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { HyperlinkRoute, ExternalLink } from "siteComponentsLibrary/Buttons";
import { TelephoneNumber } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("customer-support");

const ListItem = (props) => {
	const { children, heading, iconClassName } = props;
	return (
		<div className="row pt-3 pb-4 pb-md-3">
			<div className="col col-md-auto text-center">
				<span className="d-inline-block rounded-icon me-3">
					<span className={`customer-support-icon icon ${iconClassName}`} />
				</span>
			</div>
			<div className="col-12 col-md">
				<div className="h4">{heading}</div>
				{children}
			</div>
		</div>
	);
};

const ContactRow = (props) => {
	const { className, title, content } = props;
	
	const _className = [
		"d-flex",
		className
	].filter(Boolean).join(" ");
	
	return (
		<div className={_className}>
			<dt className="col-4 col-sm-3 col-md-2">{title}</dt>
			<dd className="mb-0">{content}</dd>
		</div>
	)
}

const ContactInfo = (props) => {

	const { title, subtitle, info, website, websiteLabel, phoneNumber, phoneNumberInfo } = props;

	return (
		<div className="mb-3">
			<div className="item-header mb-1">
				{title}
				{subtitle && (
					<span className="ms-2 d-inline-block text-black small fw-regular">{subtitle}</span>
				)}
			</div>
			<div>
				<div className="info mb-1">{info}</div>
				<div>
					<dl>
						{phoneNumber && (
							<ContactRow
								className="mb-1"
								title="Telephone:"
								content={
									<>
										<TelephoneNumber
											phonenumber={phoneNumber}
											isLink={true}
											isStrong={false}
										/>
										{phoneNumberInfo && (
											<span className="d-inline-block ms-2">{phoneNumberInfo}</span>
										)
										}
									</>
								}
							/>
						)}
						{website && (
							<ContactRow
								title="Website:"
								content={<ExternalLink href={website} label={websiteLabel}/>}
							/>
						)}						
					</dl>
				</div>

			</div>
		</div>
	)
}

const SubHeading = ({children}) => (
	<div className="item-header text-center text-md-start">{children}</div>
);

const CustomerSupportView = (props) => {
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			<ContainerMaxWidth>
				<Panel className="cs-panel" header={assets.panelHeader}>
					<PanelSection>
						<div>
							<strong>
								We want to make sure you're getting the right support when you need it.
							</strong>
						</div>
						<div>
							If you want to make us aware of any personal circumstances so that we can support you 
							in the best way we can, please contact us using any of the methods below.
						</div>

						<div className="customer-support">
							<ListItem
								heading="Give us a call"
								iconClassName="icon-telephone-fill"
							>
								Call our customer service team on{" "}
								<TelephoneNumber
									phonenumber={"0333 220 5445"}
									isLink={true}
									isStrong={false}
								/>
								<br />
								<small>
									Our opening hours are 9:00 - 18:30 Monday to Friday and 9:00 -
									13:00 on Saturday.
								</small>
							</ListItem>
							<ListItem
								heading="Talk to us on Live Chat"
								iconClassName="icon-bubbles"
							>
								Our live chat option is also available for you to ask a question.
								Look to the bottom right of this page and click on the button to
								start.
							</ListItem>
							<ListItem
								heading="Send us a message"
								iconClassName="bi bi-card-text"
							>
								You can do this on our{" "}
								<HyperlinkRoute 
									route="/contact-us" 
									className="btn btn-link btn-link-inline">
									Contact us
								</HyperlinkRoute>{" "}
								page.
							</ListItem>
						</div>
						
						<div>
							Life changes can often leave you feeling vulnerable, requiring emotional, legal or 
							financial support and guidance. To assist you during such times, we have complied a 
							list of resources that may provide the help and assistance you need.
						</div>
						
					</PanelSection>

					<PanelSection
						heading="Vision and cognition"
					>
						<div>
							If you have a visual impairment or disability, changing the
							settings of your internet browser or using voice-to-speech
							technology may help.
						</div>

						<div>
							To increase the size of the text on the screen or to magnify the
							screen, go to the 'Settings' menu on your internet browser.
							Depending on which browser you use (e.g. Internet Explorer,
							Safari, Google Chrome), you'll find the option to increase the
							text size under 'Preferences' or 'View'.
						</div>

						<div>
							Most browsers will also have a text-to-speech function within
							their 'Settings' menu that will read out the text on the screen,
							as well as descriptions of the images.
						</div>
					</PanelSection>

					<PanelSection
						heading="Hearing and Speech"
					>
						<div>
							If you have a hearing impairment, or you have difficulty with your
							speech, you can access the Relay UK service by{" "}
							<ExternalLink
								href="https://www.relayuk.bt.com/"
								label="downloading the App"
								nowrap={false}
							/>{" "}
							or by dialling '18001' before our number (so to dial our customer
							services, dial:{" "}
							<TelephoneNumber
								phonenumber={"18001 0333 220 5445"}
								isLink={true}
								isStrong={false}
							/>
							).
						</div>

						<div>
							You'll be connected to a relay assistant who will join the call
							between you and us. They'll read out the text you've typed using
							your text phone or other device.
						</div>

						<div>
							The relay assistants are bound by confidentiality and any details
							you share with them, such as identification details or financial
							information are secure and protected.
						</div>

						<div>
							Alternatively, you can contact us using our live chat facility,
							which can be found at the bottom right of this page.
						</div>
					</PanelSection>
					
					<PanelSection
						heading="Money Worries"
						className="cs-section"
					>
						<div>
							It's important that you keep up to date with payments, but we know that sometimes 
							circumstances change and it might not be possible.
						</div>
						<div>
							If you are experiencing financial difficulties please do not hesitate to contact 
							us using the options stated above to discuss how we may be able to help you.
						</div>
						<div className="mb-3">
							There are also several charities available to offer help and support when you need it.
						</div>

						<ContactInfo 
							title="Stepchange"
							info="Provide the UK’s most comprehensive debt advice service. Since 1993, they have helped
								7.5 million people with debt problems take back control of their finances and their
								lives."
							website="https://www.stepchange.org"
							websiteLabel="www.stepchange.org"
							phoneNumber="0800 138 1111"
						/>

						<ContactInfo 
							title="Citizens Advice Bureau (CAB)"
							info="Provide advice on debt solutions, the cost of living and budgeting."
							website="https://www.citizensadvice.org.uk/debt-and-money/"
							websiteLabel="www.citizensadvice.org.uk/debt-and-money/"
						/>

						<ContactInfo 
							title="National Debtline"
							info="Provide free debt advice by phone and online to people across the UK."
							website="https://www.nationaldebtline.org"
							websiteLabel="www.nationaldebtline.org"
							phoneNumber="0808 808 4000"
						/>

					</PanelSection>

					<PanelSection
						heading="Mental Health"
						className="cs-section"
					>
						<div>
							At Freedom Brokers, we consider mental health a priority, and we
							know that if you’re going through a tough time then it can be hard
							to get things done. If you need help and support, there are lots
							of resources out there to help.
						</div>
						<div className="mb-3">
							First and foremost, make sure you’re speaking with a GP. There are
							also lots of dedicated online resources and charities who can
							help, a few of which are listed below.
						</div>

						<ContactInfo 
							title="MIND"
							info="Provide advice and support to empower anyone experiencing a mental health problem."
							website="https://www.mind.org.uk"
							websiteLabel="www.mind.org.uk"
							phoneNumber="0300 102 1234"
						/>

						<ContactInfo 
							title="SANE"
							info="Work with anyone affected by mental illness, including families, friends and carers."
							website="https://www.sane.org.uk"
							websiteLabel="www.sane.org.uk"
							phoneNumber="0300 304 7000"
						/>

						<ContactInfo 
							title="CALM"
							subtitle="(Campaign Against Living Miserably)"
							info="A suicide prevention helpline open every day, offering free, anonymous support to anyone affected by suicide or suicidal thoughts."
							website="https://www.thecalmzone.net"
							websiteLabel="www.thecalmzone.net"
							phoneNumber="0800 58 58 58"
						/>

						<ContactInfo 
							title="Samaritans"
							info="Confidential support for people experiencing feelings of distress or despair."
							website="https://www.samaritans.org"
							websiteLabel="www.samaritans.org"
							phoneNumber="116 123"
							phoneNumberInfo="(24 hour helpline)"
						/>

					</PanelSection>

					<PanelSection
						heading="Health Conditions"
						className="cs-section"
					>
						<ContactInfo 
							title="MacMillan Cancer Support"
							info="Cancer specialists providing free, confidential advice."
							website="https://www.macmillan.org.uk"
							websiteLabel="www.macmillan.org.uk"
							phoneNumber="0808 808 00 00"
						/>

						<ContactInfo 
							title="Alzheimer's Society"
							info="Provides support, advice, research and campaigning for people living with 
								dementia and their families."
							website="https://www.alzheimers.org.uk"
							websiteLabel="www.alzheimers.org.uk"
							phoneNumber="0300 150 3456"
						/>

					</PanelSection>

					<PanelSection
						heading="Bereavement"
					>
						<div>
							Losing a loved one can be devastating and we know that dealing with grief can be 
							overwhelming. If you'd like to make us aware of a bereavement, please contact us. 
							We will always do whatever possible to support you during this difficult time.
						</div>
						<div>
							You can also nominate a trusted contact who can be authorised on your policy 
							to talk on your behalf if for any reason you are unable to.
						</div>
						<div> You just need to contact us and provide -</div>
						<ul>
							<li>Your full name</li>
							<li>Your policy number</li>
							<li>The name and contact details for your alternative contact</li>
						</ul>
						
						<div>
							If you need to make us aware of the death of the Policy holder, we
							understand that this can be an upsetting time and are here to
							support you.
						</div>
						<div>You just need to contact us and provide -</div>
						<ul>
							<li>The policy holders full name</li>
							<li>The policy number</li>
							<li>A copy of the death certificate</li>
						</ul>

						<ContactInfo
							title="Cruse Bereavement Support"
							info="Cruse help people through one of the most painful times in life - with bereavement support, information and campaigning."
							website="https://www.cruse.org.uk"
							websiteLabel="www.cruse.org.uk"
							phoneNumber="0808 808 1677"
						/>
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default CustomerSupportView;
