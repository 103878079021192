import React from "react";
import history from "siteHistory";
import { matchPath, useLocation } from "react-router-dom";

export * from "libraryComponents/Buttons";

// *********************************************************
// **** Custom && Overrides (replaces the export above) ****
// *********************************************************
import * as LibComponents from "libraryComponents/Buttons";
import { Pulse } from "./Animated";

export const ButtonToggle = ({ className, ...otherProps }) => (
	<LibComponents.ButtonToggle
		className={[className, "site-button-toggle"].toClassName()}
		{...otherProps}
	/>
);

export const Button = ({ className, ...otherProps }) => (
	<LibComponents.Button
		className={[className, "site-button"].toClassName()}
		LoadingComponent={() => <Pulse size="6px" colour="" />}
		{...otherProps}
	/>
);

export const IconButtonLabel = (props) => (
	<LibComponents.IconButtonLabel
		{...props}
	/>
);

export const HyperlinkRoute = ({route, ...otherProps}) => (
	<LibComponents.Hyperlink
		onClick={() => history.push(route)}
		{...otherProps}
	/>
);

export const PolicyDocsLink = (props) => {

	const { pdf, isReplaced = false, ...otherProps } = props;

	const baseUrl = !isReplaced ? require("config").default.links.docs.baseUrl : require("config").default.links.docs.replacedUrl;
	const href = `${baseUrl}${pdf}`;
	
	return (
		<LibComponents.ExternalLink
			href={href}
			{...otherProps}
			/>
	);
}

export const MakeAClaimButton = (props) => {
	return (
		<NavLink
			label="Make a claim"
			routePath="/make-a-claim"
			className="btn btn-warning"
		/>
	);
}

export const ExistingCustomersButton = (props) => {
	const { isDiv, navItemClass } = props;

	const items = [
		{ label: "Online Account", type: "heading" },
		{ label: "Log In", routePath: require("config").default.links.portal.login },
		{
			label: "Register",
			routePath: require("config").default.links.portal.register,
		},
		{ label: "Find out more", routePath: "/my-account" },
		{ label: "Make a payment", routePath: "/payments" },
		{ label: "---" },
		{ label: "Make a claim", routePath: "/make-a-claim" },
		{ label: "Optional Cover", routePath: "/optional-cover" },
		{ label: "Green card", routePath: "/green-card" },
	];

	return (
		<NavDropdown
			isDiv={isDiv}
			navItemClass={navItemClass}
			linkText="Existing Customers"
			className="btn btn-outline-primary"
			items={items}
			id="existingCustomers"
		/>
	);
}
	
export const NavLink = (props) => {
	const CheckCurrentPage = (routePath) => {
		const { pathname } = useLocation();
		const isCurrentPage = matchPath(pathname, {
			path: routePath,
			exact: true,
			strict: false,
		});

		if (isCurrentPage) {
			return { ariaCurrent: "page", isCurrent: true };
		}

		return { ariaCurrent: undefined, isCurrent: false };
	}

	const { label, routePath, className, dataBsToggle, dataBsTarget } = props;

	const linkClassName = !className ? "nav-link" : className;

	const currentPage = CheckCurrentPage(routePath);
	const linkClass = `${linkClassName} ${currentPage.isCurrent ? "active" : ""}`;

	return (
		<HyperlinkRoute
			label={label}
			route={routePath}
			className={linkClass}
			ariaCurrent={currentPage.ariaCurrent}
			dataBsToggle={dataBsToggle}
			dataBsTarget={dataBsTarget}
		/>
	);
}

export const NavDropdown = (props) => {
	const { isDiv, navItemClass, linkText, className, items, id } = props;
	if (!id) throw `Error in NavDropdown -- missing "id"`;

	const _navItemClass = [
		"nav-item",
		"dropdown",
		navItemClass
	].filter(Boolean).join(" ");

	if (isDiv) {
		return (
			<div className={_navItemClass}>
				<NavDropdownContent
					linkText={linkText}
					className={className}
					items={items}
					id={id}
				/>
			</div>
		);
	}

	return (
		<li className={_navItemClass}>
			<NavDropdownContent
				linkText={linkText}
				className={className}
				items={items}
				id={id}
			/>
		</li>
	);
}

const NavDropdownContent = (props) => {		
	const { linkText, className, items, id } = props;

	const linkClassName =
		!className
			? "nav-link dropdown-toggle"
			: `dropdown-toggle ${className}`;

	return (
		<>
			<a
				className={linkClassName}
				href="#"
				id={id}
				role="button"
				data-bs-toggle="dropdown"
				aria-expanded="false"
			>
				{linkText}
			</a>
			<ul className="dropdown-menu text-end" aria-labelledby={id}>
				{items.map((x, i) => {
					if (x.label === "---") {
						return (
							<li key={i}>
								<hr className="dropdown-divider" />
							</li>
						);
					}

					if (x.type === "heading") {
						return (
							<li key={i} className="nav-section-header text-uppercase px-3">
								{x.label}
							</li>
						);
					}

					if (x.routePath.indexOf("http") > -1) {
						return (
							<li key={i} className="">
								<LibComponents.ExternalLink
									href={x.routePath}
									label={x.label}
									className="dropdown-item"
								/>
							</li>
						);
					}

					return (
						<li key={i}>
							<NavLink
								label={x.label}
								routePath={x.routePath}
								className="dropdown-item"
							/>
						</li>
					);
				})}
			</ul>
		</>
	);
};