import { useEffect, useState, useRef } from "react";
// import "detect-autofill/dist/detect-autofill.js";
import "detect-autofill";
import hash from "object-hash";
import _ from "lodash";
// ** getEventListeners(document);

const useAutofill = (fnOnAutoFill, htmlKey = "autocompleteid") => {
	if (!fnOnAutoFill) throw `Error in useAutofill() -- missing fnOnAutoFill()`;

	const fnLog = (...args) => console.log("useAutoFill()", ...args);

	const [autoCompleteId, setAutoCompleteId] = useState(undefined);
	const functionRef = useRef();

	useEffect(() => {
		// Register the autoCompleteId
		const generatedAutoCompleteId = [
			_.uniqueId("autofill"),
			hash(fnOnAutoFill),
		].join("_");

		fnLog("REGISTERING", generatedAutoCompleteId);
		setAutoCompleteId(generatedAutoCompleteId);

		// The handler function
		const fnHandleChange = (e) => {
			if (!e.target.hasAttribute("autocompleted")) return;
			if (e.target.getAttribute(htmlKey) !== generatedAutoCompleteId) return;

			if (e.target.value === "") {
				e.preventDefault();
				return;
			}

			fnLog("AUTOFILLING:", generatedAutoCompleteId, e.target.value);
			fnOnAutoFill(e.target.value);
		};

		// Set a ref, so that we can both ADD and REMOVE EVENTS for the same function
		functionRef.current = fnHandleChange;

		// Register the function
		document.addEventListener("onautocomplete", functionRef.current);

		//TIDY UP
		return () => {
			document.removeEventListener("onautocomplete", functionRef.current);
		};
	}, []);

	return { [htmlKey]: autoCompleteId };
};

export default useAutofill;
