//** compooseWebService

import React, {
	useCallback,
	useState,
	useRef,
	useEffect,
	useMemo,
} from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

const DefaultInit = () => <p>Init..</p>;
const DefaultLoading = () => <p>Loading...</p>;
const DefaultNoData = () => <p></p>;
const DefaultErrorComponent = () => <p>Error.</p>;

const compose = ({
	DataComponent,
	dataKey,
	LoadingComponent = DefaultLoading,
	InitComponent = DefaultInit,
	NoDataComponent = DefaultNoData,
	ErrorComponent = DefaultErrorComponent,
	defaultItemKey: _defaultItemKey = false,
}) => {
	if (!DataComponent)
		throw `Error in composeWebService -- missing DataComponent`;
	if (!dataKey) throw `Error in composeWebService -- missing dataKey`;
	const defaultPostProcessFunction = (value) => value;

	//******************************
	// Return Component
	//******************************
	const ReturnComponent = (props) => {
		const {
			onItemDataLoaded,
			DataFunction, //The function for geting the data
			DataFunctionArguments = {}, // the props needed for the datafunction
			debounceTime = 0,
			postProcessFunction = defaultPostProcessFunction,

			value,
			onChange,
			defaultItemKey = _defaultItemKey, // default to first item if "value" is not found in the ItemData
			emptyLabel,
			...otherProps
		} = props;

		if (!DataFunction) {
			console.log("ERROR:", props);
			throw `Error in composeWebService -- missing DataFunction`;
		}
		const baseList = useCallback(() => {
			if (emptyLabel === undefined) return [];
			return [{ label: emptyLabel, value: undefined }];
		}, [emptyLabel])();

		const finalDebounceTime =
			DataFunctionArguments.debounceTime || debounceTime || 0;

		const [isError, setIsError] = useState(false);
		const [isLoading, setIsLoading] = useState(true);
		const [curData, setCurData] = useState(undefined);

		//console.log("xxxx value", value);
		// const dataArgs = useMemo(() => {
		// 	let ret = {};
		// 	DataFunctionArguments.forEach((x) => (ret[x] = props[x]));
		// 	console.log("generating dataArgs", ret, "DataFunctionArguments:", DataFunctionArguments);

		// 	return ret;
		// }, [value]);
		//	const PrevDataFunctionArgumentsRef = useRef();
		// const dataArgs = (function () {
		// 	let _args = {};
		// 	DataFunctionArguments.forEach((curArg) => {
		// 		_args[curArg] = props[curArg];
		// 	});
		// 	return _args;
		// })();

		// function for calling the webservice

		const callDataFunction = (args, value) => {
			DataFunction(args).then((response) => {
				//console.log("dddd", props, response);
				// if (!response || response.isError) {
				// 	setCurData(undefined);
				// 	setIsError(true);
				// 	setIsLoading(false);
				// } else if (!("data" in response)) {
				// 	throw `Error in webservice can't find data`;
				// } else

				{
					setIsError(false);
					setIsLoading(false);

					const itemData =
						response && response.length !== 0
							? [...baseList, ...response]
							: undefined;

					setCurData(itemData);

					if (
						defaultItemKey &&
						itemData &&
						itemData.length !== 0 &&
						!itemData.some(
							(x) =>
								x.value === value ||
								(x.value === undefined && value === undefined)
						)
					) {
						onChange(itemData[0].value);
					}
					if (onItemDataLoaded) onItemDataLoaded({ [dataKey]: itemData });
				}
			});
		};

		const delayedDataFunction = useCallback(
			_.debounce(callDataFunction, finalDebounceTime),
			[finalDebounceTime, DataFunction]
		);

		// If any of the datafunctionArgs have changed, then refresh the data
		// const dataArgs = {};

		useDeepCompareEffect(() => {
			setIsLoading(true);
			delayedDataFunction(DataFunctionArguments, value);
		}, [DataFunctionArguments]);

		//	console.log("DataFunctionArguments",DataFunctionArguments)
		if (isError) return <ErrorComponent />;
		if (isLoading) return <LoadingComponent />;

		//	if (!isInit) return <InitComponent />;
		if (curData === undefined) return <NoDataComponent />;

		let dataProps = { [dataKey]: postProcessFunction(curData) };

		// if (dataKey in otherProps)
		// 	dataProps[dataKey] = [
		// 		...otherProps[dataKey],
		// 		...(dataProps[dataKey] || []),
		// 	];
		return (
			<DataComponent
				value={value}
				onChange={onChange}
				{...otherProps}
				{...dataProps}
			/>
		);
	};

	ReturnComponent;
	ReturnComponent.displayName =
		DataComponent.displayName || "Webservice Container";
	ReturnComponent.propTypes = {
		onItemDataLoaded: PropTypes.func,
		...DataComponent.propTypes,
	};

	return ReturnComponent;
};
export default compose;
