import PropTypes from "prop-types";

import React, { useCallback } from "react";

import "siteAssets/css/mainStyle";
import history from "siteHistory";

import logo from "siteAssets/images/logo.png";

import { Hyperlink } from "siteComponents";

const gotoRoute = (route) => history.push(route);

import { routeData } from "../../Routes";

// console.log(
// 	"xxxx",
// 	routeData.map((x) => x.route).sort()
// );

const Sitemap = (props) => {
	const { children } = props;
	return (
		<div className="col-12 footer-links p-2">
			<div className="row">
				{routeData
					.filter(({ sitemapHeading }) => sitemapHeading)
					.sort((a, b) => a.sitemapHeading.localeCompare(b.sitemapHeading))
					.map(({ sitemapHeading, route, externalLink }) => (
						<div key={sitemapHeading} className="col-6 col-md-4">
							{route && (
								<Hyperlink
									label={sitemapHeading}
									onClick={useCallback(() => gotoRoute(route), [])}
								/>
							)}
							{externalLink && (
								<a key={sitemapHeading} href={externalLink}>
									{sitemapHeading}
								</a>
							)}
						</div>
					))}
			</div>
		</div>
	);
};

export default Sitemap;
Sitemap.displayName = "Sitemap";
Sitemap.propTypes = {
	children: PropTypes.any,
};
