import React from "react";

import data from "./data";
import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { Accordion } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("faqs");

const Faqs = (props) => {

	const accordionId = "faqAccordion";

	return (
		<PageLayout
			pageTitle={assets.pageTitle}
			headingText={assets.headingText}
		>
			<ContainerMaxWidth>
				<Accordion accordionId={accordionId} data={data} />
			</ContainerMaxWidth>
			
		</PageLayout>
	);
};

export default Faqs;
