import React from "react";

const BaseCard = (props) => {
	const {
		hasShadow,
		centerTitle = true,
		cardHighlight = false,
		addBottomMargin = true,
		cardClasses = "",
		cardOnClick,
		headerClasses = "",
		cardHeaderOnClick,
		imageTopUrl,
		imageTopAlt,
		headerContent,
		titleContent,
		cardContent,
		cardBodyClasses = "",
		cardBodyOnClick,
		cardBodyTwo,
		footerContent,
		footerClasses = "",
		footerOnClick,
		fragmentHeader,
		fragmentBody,
		fragmentFooter,
	} = props;

	return (
		<div
			className={`card ${cardHighlight ? "card-highlight" : ""} ${
				addBottomMargin ? "mb-3" : ""
			} ${hasShadow ? "card-shadow" : ""} ${cardClasses}`}
			onClick={cardOnClick}
		>
			{fragmentHeader && <div className="card-header" onClick={cardHeaderOnClick}>{fragmentHeader}</div>}

			{headerContent && (
				<div className={`card-header text-center text-grey ${headerClasses}`} onClick={cardHeaderOnClick}>
					<h3 className="fw-bold fs-5 mt-2">{headerContent}</h3>
				</div>
			)}

			{imageTopUrl && (
				<img src={imageTopUrl} className="card-img-top" alt={imageTopAlt} />
			)}

			{fragmentBody && <div className="card-body">{fragmentBody}</div>}

			{(cardContent || titleContent) && (
				<div
					className={`card-body ${cardBodyClasses}`}
					onClick={cardBodyOnClick}
				>
					{titleContent && (
						<div className={`${centerTitle ? "row text-center" : ""}`}>
							<h3
								className={`card-title fw-bold fs-5 text-grey ${
									centerTitle ? "p-0" : ""
								}`}
							>
								{titleContent}
							</h3>
						</div>
					)}
					{cardContent}
				</div>
			)}

			{cardBodyTwo && (
				<div className={`card-body ${cardBodyClasses}`}>{cardBodyTwo}</div>
			)}

			{fragmentFooter && <div className="card-footer">{fragmentFooter}</div>}

			{footerContent && (
				<div
					className={`card-footer text-center px-xl-0 ${footerClasses}`}
					onClick={footerOnClick}
				>
					{footerContent}
				</div>
			)}
		</div>
	);
};

export default BaseCard;
