import React from "react";

const Indicators = (props) => {
    const { target, slides } = props;

    return (
        <div className="carousel-indicators position-relative mb-0 pt-1">
            {slides.map((slide, i) => {
                return (
                    <button key={i} type="button"
                        data-bs-target={target}
                        data-bs-slide-to={`${i}`}
                        className={`${(i === 0 ? "active" : "")}`}
                        aria-current="true"
                        aria-label={`Slide ${i + 1}`}>
                    </button>
                );
            })}
        </div>
    );
}

const Controls = (props) => {
    const { target, showControls = false, showIndicators = true, slides } = props;

    if (showControls) {
        return (
            <div className="d-flex justify-content-center mb-4">
                <button
                    className="carousel-control-prev position-relative"
                    type="button"
                    data-bs-target={target}
                    data-bs-slide="prev"
                >
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>

                {showIndicators && (
                    <Indicators target={target} slides={slides} />
                )}

                <button
                    className="carousel-control-next position-relative"
                    type="button"
                    data-bs-target={target}
                    data-bs-slide="next"
                >
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        );
    }

    if (showIndicators) {
        return (
            <Indicators target={target} slides={slides} />
        );
    }

    return null;
}

const Carousel = (props) => {
    const { carouselId, className = "", slides, slideInterval = "5000", controlPosition = 1, showControls = true, showIndicators = true } = props;

    if (slides == undefined) {
        return null;
    }

    const controlsBottom = controlPosition === 1 && (showControls == true || showIndicators == true);
    const controlsTop = controlPosition === 2 && (showControls == true || showIndicators == true);
    
    const carouselTarget = `#${carouselId}`;

    return (
        
        <div id={carouselId} className={`carousel slide carousel-dark ${className}`} data-bs-ride="carousel">

            {controlsTop && (
                <Controls target={carouselTarget} slides={slides} showControls={showControls} showIndicators={showIndicators} />
            )}
           
            <div className="carousel-inner py-4">

                {slides.map((slide, i) => {
                    return (
                        <div key={i} className={`carousel-item ${(i === 0 ? "active" : "")}`} data-bs-interval={slideInterval}>
                            {slide}
                        </div>
                    );
                })}
            </div>

            {controlsBottom && (
                <Controls target={carouselTarget} slides={slides} showControls={showControls} showIndicators={showIndicators} />
            )}


        </div>
    );
};

export default Carousel;