import React from "react";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";
import StickyLibrary from "react-sticky-el";
import { Help, HelpFurther } from "./Display";
import { ExternalLink, Hyperlink } from "./Buttons";

export const ContainerMaxWidth = (props) => {
	const { children, className } = props;

	const _className = [
		"container-fluid",
		"container-lg",
		"layout-max-width",
		className
	]
		.filter(Boolean)
		.join(" ");

	return (
		<div className={_className}
			 {...generateCypressProps("container", props)}
		>
			{children}
		</div>
	);
}

export const FormGroup = (props) => {
	const {
		value,
		hidden,
		error = {},
		hasError: props_hasError = false,
		errorMessage: props_errorMessage = undefined,
		children,
		className,
		classNameHasError,
		classNameLabel,
		classNameContent,
		errorShow = false,
		label,
		labelFor,
		helpText,
		helpTextFurther,
	} = props;

	if (hidden) return null;

	const errorList = Object.values(error);
	const hasError = props_hasError || (errorShow && errorList.length >= 1);
	const errorMessage = props_errorMessage || errorList.find((x) => x != ""); // find the first message
	const hasHelpText = helpText ? true : false;

	// console.log("RENDERING FormGroup", label, props);

	return (
		<fieldset
			className={[
				"row",
				"form-group",
				"panel-section",
				className,
				hasError && classNameHasError,
			]
				.filter(Boolean)
				.join(" ")}
			{...generateCypressProps("form-group", props)}
		>
			{label && (
				<label
					className={[
						"col-12",
						"form-label",
						classNameLabel,
						hasHelpText ? "has-help-text" : "",
					]
						.filter(Boolean)
						.join(" ")}
					data-cy="form-label"
					htmlFor={labelFor}
				>
					{label}
				</label>
			)}

			{hasHelpText && (
				<Help value={helpText} className="col-12" data-cy="help-text" />
			)}

			{helpTextFurther && (
				<HelpFurther
					value={helpTextFurther}
					className="col-12"
					data-cy="help-text-expand"
				/>
			)}

			<div
				className={[
					`col-${props.xsCols ? props.xsCols : "12"}`,
					classNameContent,
				]
					.filter(Boolean)
					.join(" ")}
				data-cy="form-children"
			>
				{children}
			</div>

			{errorMessage && (
				<div className="col-12 form-text error-text" data-cy="feedback-error">
					{errorMessage}
				</div>
			)}
		</fieldset>
	);
};

export const PanelSection = (props) => {
	const { children, heading, className, classNameChildren, relatesToFormGroup = false } = props;

	const _className = [
		"row",
		"panel-section",
		// If this section relates closely to the form group that follows it,
		// we don't need the extra spacing below.
		relatesToFormGroup ? "mb-0" : "",
		className
	]
		.filter(Boolean)
		.join(" ");

	const _classNameChildren = [
		"col",
		"section-children",
		classNameChildren,
	]
		.filter(Boolean)
		.join(" ");

	return (
		<div
			className={_className}
			{...generateCypressProps("panel-section", props)}
		>
			{heading && (
				<div className="col-12 section-heading" data-cy="section-heading">
					<div className="underline">{heading}</div>
				</div>
			)}
			<div className={_classNameChildren} data-cy="section-children">
				{children}
			</div>
		</div>
	);
};

export const PanelHeader = (props) => {
	const {
		leftContent,
		rightContent,
		
		icon,
		iconImage,
		iconContainerClass,
		
		className = "",
		leftContentClassName = "",
		rightContentClassName = "",
	} = props;

	const _className = [
		"d-flex",
		"justify-content-between",
		"align-items-center",
		className,
	].filter(Boolean).join(" ");
	
	const _leftContentClassName = [
		leftContentClassName,
	].filter(Boolean).join(" ");

	const _rightContentClassName = [
		"right-content",
		"text-end",
		rightContentClassName,
	].filter(Boolean).join(" ");
	
	const _iconContainerClass = [
		"icon-image-container",
		"d-inline-block",
		iconContainerClass,
	].filter(Boolean).join(" ");

	const _displayRight =
		icon ?
			<span className={_iconContainerClass}>
					<span className={`icon ${icon}`}/>
				</span>
			:
			iconImage ?
				<span className={_iconContainerClass}>{iconImage}</span>
				: rightContent;

	return (
		<div className={_className}>
			<div className={_leftContentClassName}>
				<div className="left-content">
					{leftContent}
				</div>
			</div>
			<div className={_rightContentClassName}>
				{_displayRight}
			</div>
		</div>
	);
}

export const Panel = (props) => {
	const {
		children,
		header,
		footer,
		className,
		classNameBody,
		classNameHeader,
		classNameFooter,
		hasBorder = true,
	} = props;

	if ("heading" in props)
		throw `Error in Panel -- found a prop "heading". Did you mean "header"?`;

	const fnGenerateClassName = (arr = []) => arr.filter(Boolean).join(" ");

	return (
		<div
			className={fnGenerateClassName(["panel", "card", footer ? "has-footer" : "", className])}
			{...generateCypressProps("panel", props)}
		>
			{header && (
				<div
					className={fnGenerateClassName(["card-header", classNameHeader])}
					data-cy="panel-header"
				>
					{header}
				</div>
			)}
			<div
				className={fnGenerateClassName(["card-body", classNameBody])}
				data-cy="panel-body"
			>
				{children}
			</div>
			{footer && (
				<div
					className={fnGenerateClassName(["card-footer", classNameFooter])}
					data-cy="panel-footer"
				>
					{footer}
				</div>
			)}
		</div>
	);
};


export const Sticky = (props) => {
	const {
		children,
		stickyClassName,
		className,

		topOffset,
		onChange = () => null,
	} = props;

	const _onChange = (newIsSticky) => {
		onChange(newIsSticky);
	};

	const _className = [className].filter(Boolean).join(" ");

	return (
		<StickyLibrary
			onFixedToggle={_onChange}
			className={_className}
			stickyClassName={stickyClassName}
			topOffset={topOffset}
		>
			{children}
		</StickyLibrary>
	);
};

export const SiteFooter = (props) => {
	const {
		brandImage,
		legalFooterText,
		copyright,

		unwrap = "lg",
		unwrapLeft = 4,
		outerClassName,
		leftClassName,
		rightClassName,

		menuData,
		socialMedia,
		linksStackOnMobile = false,
		goToRoute,
	} = props;

	const _outerClassName = ["footer", "row", outerClassName]
		.filter(Boolean)
		.join(" ");

	const _leftClassName = [
		"footer-links",
		menuData ? `col-12 col-${unwrap}-${unwrapLeft}` : "d-none",
		leftClassName,
	]
		.filter(Boolean)
		.join(" ");

	const _rightClassName = [
		"legal-footer",
		"col-12",
		menuData ? `col-${unwrap}-${12 - unwrapLeft}` : "",
		rightClassName,
	]
		.filter(Boolean)
		.join(" ");

	const _linksClassName = [
		"nav",
		linksStackOnMobile ? "flex-column" : `flex-${unwrap}-column`,
	]
		.filter(Boolean)
		.join(" ");

	return (
		<footer className="mt-auto" data-cy="layout-footer">
			{" "}
			{/* The 'mt-auto' class is what allows it to 'stick' to the bottom on large screens */}
			<div className={`container-fluid container-${unwrap} layout-max-width`}>
				{" "}
				{/* This allows us to position the content in line with the rest of the site */}
				<div className={_outerClassName}>
					<div className={_leftClassName} data-cy="footer:links">
						{menuData && (
							<nav className={_linksClassName}>
								{menuData.map((x, i) => {
									if (x.href) {
										return (
											<ExternalLink
												key={x.id}
												href={x.href}
												label={x.label}
												title={x.label}
												className="nav-link"
												data-cy={`ext-link:${x.id}`}
											/>
										);
									}
									if (x.route) {
										return (
											<Hyperlink
												key={x.id}
												onClick={() => goToRoute(x.route)}
												label={x.label}
												title={x.label}
												className="nav-link"
												data-cy={`route-link:${x.id}`}
											/>
										);
									}
									return null;
								})}
								{socialMedia && (
									<div className="social-media-links">
										{socialMedia.map((x, i) => {
											return (
												<ExternalLink
													key={x.id}
													href={x.link}
													label={
														<span>
															<i className={`bi bi-${x.icon}`}></i>
															<span className="visually-hidden">{x.label}</span>
														</span>
													}
													title={x.label}
													className="social btn btn-link-outline"
													data-cy={`social:${x.id}`}
												/>
											);
										})}
									</div>
								)}
							</nav>
						)}
					</div>
					<div className={_rightClassName} data-cy="footer:legal">
						<div data-cy="footer:brand-img">{brandImage}</div>
						<div className="copyright" data-cy="footer:copyright">{copyright}</div>
						<div className="legal-text" data-cy="footer:legal-text">{legalFooterText}</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export const SiteFooterLogoLeft = (props) => {
	const {
		brandImage,
		legalFooterText,
		copyright,

		unwrap = "lg",
		unwrapLeft = 7,

		linkColumns = 2,
		menuData,
		socialMedia,
		goToRoute,
	} = props;

	const _brandImageClassName = [
		"col-12",
		`col-${unwrap}-3`,
		"order-2",
		`order-${unwrap}-1`,
		"pt-3",
		`pt-${unwrap}-0`,
	].filter(Boolean).join(" ");

	const _linksClassName = [
		"col-12",
		`col-${unwrap}-${unwrapLeft}`,
		"order-1",
		`order-${unwrap}-2`,
	].filter(Boolean).join(" ");

	return (
		<footer className="mt-auto footer-2" data-cy="layout-footer">
			{/* The 'mt-auto' class is what allows it to 'stick' to the bottom on large screens */}

			<div className="container-fluid footer">
				<div className={`container-fluid container-${unwrap} layout-max-width`}>
					<div className="row">
						<div className={_brandImageClassName}>
							<div data-cy="footer:brand-img">{brandImage}</div>
						</div>
						<div className={_linksClassName}>
							<div className={`mb-1 d-none d-${unwrap}-block`}><strong>Quick links</strong></div>
							<div className={`nav row-cols-${linkColumns}`}>
								{menuData.map((x, i) => {
									if (x.href) {
										return (
											<ExternalLink
												key={x.id}
												href={x.href}
												label={x.label}
												title={x.label}
												className="nav-link"
												data-cy={`ext-link:${x.id}`}
											/>
										);
									}
									if (x.route) {
										return (
											<Hyperlink
												key={x.id}
												onClick={() => goToRoute(x.route)}
												label={x.label}
												title={x.label}
												className="nav-link"
												data-cy={`route-link:${x.id}`}
											/>
										);
									}
									return null;
								})}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container bg-transparent py-3">
				<div className={`container-fluid container-${unwrap} layout-max-width`}>
					<div className="row">
						<div className={`col-12 text-${unwrap}-center`}>
							<div className="legal-text mb-3" data-cy="footer:legal-text">{legalFooterText}</div>
							<div className="copyright" data-cy="footer:copyright">{copyright}</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export const SiteHeader = (props) => {
	const {
		brandImage,
		brandImageAlt,
		brandImageOnClick,

		contactDetails,

		unwrap = "lg",
		outerClassName,
		leftClassName,
		rightClassName,
	} = props;

	const _outerClassName = [
		"layout-header",
		"d-flex",
		"align-items-center",
		outerClassName,
	]
		.filter(Boolean)
		.join(" ");

	const _leftClassName = [
		"col-6 d-flex align-items-center justify-content-start nav-left",
		leftClassName,
	]
		.filter(Boolean)
		.join(" ");

	const _rightClassName = [
		"col-6 d-flex align-items-center justify-content-end nav-right",
		rightClassName,
	]
		.filter(Boolean)
		.join(" ");

	return (
		<header className={_outerClassName} data-cy="layout-header">
			<div className={`container-fluid container-${unwrap} layout-max-width`}>
				<div className="row" data-cy="site-header">
					{/* Top left image */}
					<div className={_leftClassName} data-cy="site-header-image">
						<img
							style={{ cursor: "pointer" }}
							className="brand-img img-fluid"
							src={brandImage}
							alt={brandImageAlt}
							onClick={brandImageOnClick}
						/>
					</div>

					{/* Top right contact details */}
					<div className={_rightClassName}>{contactDetails}</div>
				</div>
			</div>
		</header>
	);
};

export const SiteHeaderWithNav = (props) => {

	const {
		brandImage,
		brandImageAlt,
		brandImageOnClick,

		unwrap = "lg",
		stickyClass = "sticky-top",
		navbarId = "headerNavBarContent",
		navbarStyle = "light",

		linksMain,
		goToRoute,
		navLinksData,
		mobileText,

		outerClassName,
	} = props;

	const _outerClassName = [
		"layout-header",
		"layout-header-nav",
		outerClassName,
		stickyClass,
	]
		.filter(Boolean)
		.join(" ");

	const _navbarContainerClassName = [
		"container-fluid",
		`container-${unwrap}`,
		"layout-max-width",
	]
		.filter(Boolean)
		.join(" ");

	const _navbarClassName = [
		"navbar",
		`navbar-expand-${unwrap}`,
		`navbar-${navbarStyle}`,
		"site-header-nav"
	]
		.filter(Boolean)
		.join(" ");

	const navbarTarget = `#${navbarId}`;
	const navbarTargetCloseOnly = `#${navbarId}.show`;
	const navbarToggle = "collapse";

	return (
		<header className={_outerClassName} data-cy="layout-header">
			<div className="nav-main d-flex align-items-center">
				<div className={_navbarContainerClassName}>
					<nav className={_navbarClassName}>
						<div className="container-fluid">
							<div className="col-4 col-lg-2">
								<img
									style={{cursor: "pointer"}}
									className="brand-img img-fluid"
									src={brandImage}
									alt={brandImageAlt}
									onClick={brandImageOnClick}
									data-bs-toggle={navbarToggle}
									data-bs-target={navbarTargetCloseOnly}
								/>
							</div>
							<div className="col-8 col-lg-10 d-flex justify-content-end">
								<div className={`d-none d-${unwrap}-inline-block`}>
									<ul className="navbar-nav flex-row btn-nav gap-2">
										{linksMain}
									</ul>
								</div>

								<button
									className="navbar-toggler"
									type="button"
									data-bs-toggle={navbarToggle}
									data-bs-target={navbarTarget}
									aria-controls={navbarId}
									aria-expanded="false"
									aria-label="Toggle navigation"
								>
									<span className="navbar-toggler-icon"></span>
									<span className="navbar-close">X</span>
								</button>
							</div>
						</div>
					</nav>

				</div>
			</div>

			<div className="nav-links d-flex align-items-center">
				<div className={_navbarContainerClassName}>
					<nav className={_navbarClassName}>
						<div className={`collapse navbar-collapse`} id={navbarId}>
							<ul className="navbar-nav navbar-nav-scroll flex-grow-1 justify-content-between">
								{navLinksData.map((x, i) => {
									if (x.href) {
										return (
											<li className="nav-item" key={x.id}>
												<ExternalLink
													href={x.href}
													label={x.label}
													title={x.label}
													className="nav-link"
													data-cy={`ext-link:${x.id}`}
												/>
											</li>
										);
									}
									if (x.route) {
										return (
											<li className="nav-item" key={x.id}>
												<Hyperlink
													onClick={() => goToRoute(x.route)}
													label={x.label}
													title={x.label}
													className="nav-link"
													data-cy={`route-link:${x.id}`}
												/>
											</li>
										);
									}
									return null;
								})}
							</ul>
						</div>
					</nav>
				</div>
			</div>
			<div className={`mobile-text d-flex d-${unwrap}-none align-items-center`}>
				<div className={_navbarContainerClassName}>
					<div className="row">
						<div className="col-12">
							{mobileText}
						</div>
					</div>
				</div>
			</div>
		</header>
	);

}

export const CookieBannerAlert = (props) => {
	const {enabled, showBanner, unwrap = "lg"} = props;

	if (!enabled || !showBanner) return <>{props.children}</>;

	const _btnClassName = ["btn", props.buttonClassName]
		.filter(Boolean)
		.join(" ");

	return (
		<>
			<div
				className="cookie-consent"
				{...generateCypressProps("cookie-consent-banner", props)}
			>
				<div className="row justify-content-center">
					<div className="col-12 px-2 pt-3 px-lg-5">
						<div className="alert alert-primary">
							<div className="row align-items-center">
								<div
									className="col-12 col-md consent-text"
									data-cy="cookie-consent-text"
								>
									{props.text}
								</div>
								<div className="col-12 col-md-auto consent-btn text-end">
									<button
										type="button"
										className={_btnClassName}
										onClick={props.onClick}
										data-cy="btn:cookie-consent"
									>
										{props.buttonText}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{props.children}
		</>
	);
};

export const CookieBannerFullWidth = (props) => {
	const {enabled, showBanner, unwrap = "lg"} = props;

	if (!enabled || !showBanner) return <>{props.children}</>;

	const _btnClassName = ["btn", "w-100", props.buttonClassName]
		.filter(Boolean)
		.join(" ");

	return (
		<>
			<div
				className="cookie-consent cookie-consent-fw"
				{...generateCypressProps("cookie-consent-banner", props)}
			>
				<div className={`container-fluid container-${unwrap} layout-max-width`}>
					<div className="row align-items-center g-3">
						<div
							className="col-12 col-md pe-md-0 consent-text"
							data-cy="cookie-consent-text"
						>
							{props.text}
						</div>
						<div className="col-12 col-md-2 consent-btn text-end">
							<button
								type="button"
								className={_btnClassName}
								onClick={props.onClick}
								data-cy="btn:cookie-consent"
							>
								{props.buttonText}
							</button>
						</div>
					</div>
				</div>
			</div>

			{props.children}
		</>
	);
};

export const PageHeaderWithIcon = (props) => {
	const {heading, icon} = props;

	if (!icon) return <div className="text">{heading}</div>;

	return (
		<div className="d-flex align-items-center icon-header">
			<div className="flex-shrink-0 d-flex flex-column align-items-center">
				<span className={["icon", icon].toClassName()}></span>
			</div>
			<div className="text">{heading}</div>
		</div>
	);
};
