import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";

const BuildItem = (props) => {
	
	const { label, pdf } = props;
	
	const href = `${require("config").default.links.docs.baseUrl}${pdf}`;
	
	return (
		<div className="mb-3">
			<ExternalLink href={href} label={label} />
		</div>
	);
	
}

const assets = require("siteAssets/pageManager").default("terms-of-business");

const TermsOfBusiness = (props) => {
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection>
						You can find the terms of business for our products below.
					</PanelSection>
					
					<PanelSection
						heading="Motor Insurance"
					>
						<BuildItem 
							label={"Private Car & Commercial Vehicle"} 
							pdf={require("config").default.links.docs.toba.motor} 
						/>
						
						<BuildItem 
							label={"Telematics - Private Car & Commercial Vehicle"} 
							pdf={require("config").default.links.docs.toba.telematics} 
						/>
					</PanelSection>

					<PanelSection
						heading="Commercial Insurance"
					>
						<BuildItem 
							label={"GAP Insurance"} 
							pdf={require("config").default.links.docs.toba.gap} 
						/>
						
						<BuildItem 
							label={"Commercial Risk"} 
							pdf={require("config").default.links.docs.toba.commercial} 
						/>
						
						<BuildItem 
							label={"Employers Liability & Public Liability"} 
							pdf={require("config").default.links.docs.toba.liability}
						/>
					</PanelSection>
					
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default TermsOfBusiness;
