import React from "react";
import _ from "lodash";

const AccordianItem = (props) => {
	const { index, heading, data, parentId, classType } = props;

	const headingId = `${parentId.replace("#", "")}heading${index}`;
	const collapseId = `${parentId.replace("#", "")}collapse${index}`;

	if (classType === "ListHeading")
		return (
			<div className="accordion-item">
				<div className="fs-4 px-3 py-3">{heading}</div>
			</div>
		);

	return (
		<div className="accordion-item">
			<h2 className="accordion-header" id={headingId}>
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#${collapseId}`}
					aria-expanded="false"
					aria-controls={collapseId}
				>
					{heading}
				</button>
			</h2>
			<div
				id={collapseId}
				className="accordion-collapse collapse"
				aria-labelledby={headingId}
				data-bs-parent={parentId}
			>
				<div className="accordion-body">{data}</div>
			</div>
		</div>
	);
};

const Accordian = (props) => {
	const { accordianId, data } = props;

	const cleanId = accordianId
		.replaceAll(" ", "")
		.replaceAll(")", "") // Theo (ThingCo)
		.replaceAll("(", "") // Theo (ThingCo)
		.replaceAll("&", "") // Fees, Payments & Charges
		.replaceAll(",", ""); // Fees, Payments & Charges

	if (!_.isArray(data[0])) {
		return (
			<div className="accordion" id={cleanId}>
				{data.map((x, i) => (
					<AccordianItem
						key={i}
						index={i}
						heading={x.title}
						data={x.data}
						classType={x.classType}
						parentId={`#${cleanId}`}
					/>
				))}
			</div>
		);
	}

	return (
		<div className="accordion" id={cleanId}>
			{data.map(([heading, data], i) => (
				<AccordianItem
					key={i}
					index={i}
					heading={heading}
					data={data}
					parentId={`#${cleanId}`}
				/>
			))}
		</div>
	);
};

export default Accordian;
