import React from "react";

import { TelephoneNumber } from "siteComponentsLibrary/Display";
import { HyperlinkRoute, ExternalLink } from "siteComponentsLibrary/Buttons";

import { BaseCard } from "siteComponents";

const MakeAClaimDetails = (props) => {

	const { claimType } = props;

	switch (claimType) {

		case "MOTOR": {

			const number = require("config").default.claimsContacts.motor.number;

			return (
				<>
					<BaseCard
						headerContent="Need to make a claim?"
						cardContent={
							<>
								<p>
									We'll help you in the event of an accident - that's why we're here.
								</p>
								<p>
									Simply call us on{" "}
									<TelephoneNumber phonenumber={number} isLink={true}/>
									{" "}to speak to
									one of our experienced claims handlers. It's best to get in contact
									as soon as possible.
								</p>
								<p>
									Even if you're not claiming for yourself, it's important to get in
									touch so we can advise and deal with any claims made against you.
								</p>
								<p>
									It would be helpful if you could provide us with the following
									details when you first report the claim to us:
								</p>
								<ul>
									<li>Your policy number</li>
									<li>The registration number of your vehicle</li>
									<li>The details of anybody else involved in the accident</li>
									<li>
										As many details as you can about the event - this will help speed
										up your claim
									</li>
								</ul>
								<p>
									Once the details are logged our dedicated claims team will be with
									you every step of the way to resolve the claim as quickly and
									efficiently as possible.
								</p>

								<p className="alert alert-info">
									If you need to make a claim for one of your policy upgrades, please visit the&nbsp;
									<HyperlinkRoute className="card-link" route="/make-a-claim">
										make a claim page
									</HyperlinkRoute> for contact details.
								</p>
							</>
                        }
					/>
				</>
			);
		}

		case "HOME": {

			const url = require("config").default.claimsContacts.home.url;

			return (
				<>
					<BaseCard
						headerContent="Need to make a claim?"
						cardContent={
							<>
								<p>
									If the worst happens and you need to make a claim, we will be there for you. 
								</p>
								<p>
									Our UK claims team are available 24/7 to help you out when you need it.
								</p>
								<p>
									Visit <ExternalLink href={url} label="the Homelyfe website" /> to get started with your claim.
								</p>
							</>
						}
					/>
				</>
			);
		}

		case "GAP": {

			const number = require("config").default.claimsContacts.gap.number;

			return (
				<>
					<BaseCard
						cardHighlight={true}
						headerContent="Need to make a claim?"
						cardContent={
							<>
								<p className="text-center">
									If you need to make a claim, please call us on{" "}
									<TelephoneNumber phonenumber={number} />
								</p>								
							</>
						}
					/>
				</>
			);
		}

		case "PL": {

			const number = require("config").default.claimsContacts.pl.number;

			return (
				<>
					<BaseCard
						cardHighlight={true}
						headerContent="Need to make a claim?"
						cardContent={
							<>
								<p className="text-center">
									If you need to make a claim, please call us on{" "}
									<TelephoneNumber phonenumber={number} />
								</p>
							</>
						}
					/>
				</>
			);
		}


		case "TRADE": {

			const number = require("config").default.claimsContacts.pl.number;

			return (
				<>
					<BaseCard
						cardHighlight={true}
						headerContent="Need to make a claim?"
						cardContent={
							<>
								<p className="text-center">
									If you need to make a claim, please call us on{" "}
									<TelephoneNumber phonenumber={number} />
								</p>
							</>
						}
					/>
				</>
			);
		}

		case "PET": {

			const url = require("config").default.claimsContacts.pet.url;

			return (
				<>
					<BaseCard
						headerContent="Need to make a claim?"
						cardContent={
							<>
								<p>
									If your pet is unwell, please use the form on{" "}
									<ExternalLink href={url} label="the Healthy Pets website" />{" "}
									to get started with your claim.
								</p>
							</>
						}
					/>
				</>
			);
		}
	}

	return null;
	
};

export default MakeAClaimDetails;
