import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";

import PageLayout from "siteLayouts/Page";
import { ContainerMaxWidth } from "siteComponentsLibrary/Layout";

import Landing from "./components/Landing";
import Error from "./components/Error";
import Success from "./components/Success";

const Payment = (props) => {
	const { search: urlSearchParams } = useLocation();

	const defaultWebReference =
		require("@library/common/helpers/misc/paramExtractor")(
			urlSearchParams,
			"webref"
		);

	const defaultSrc = require("@library/common/helpers/misc/paramExtractor")(
		urlSearchParams,
		"src"
	);

	const baseRoute = "/payments";

	const urlFull = `${window.location.origin}${baseRoute}`;

	const urlPostfix = `${window.location.search}`;

	const assets = require("siteAssets/pageManager").default("payments");
	
	const landingBaseArguments = {
		urlPaymentSuccess: `${urlFull}/success`,
		urlBack: `${urlFull}${urlPostfix}`, // add webref, use existing params
		urlPaymentError: `${urlFull}/error`,

		urlRefused: `${urlFull}/tryagain${urlPostfix}`,
		urlTimeout: `${urlFull}/error`, //#3390
		urlReferred: `${urlFull}/tryagain${urlPostfix}`,
		urlDeclined: `${urlFull}/tryagain${urlPostfix}`,

		urlTechnicalError: `${urlFull}/error`,

		defaultWebReference,
		defaultSrc,
		errorMessageFailedLookup:
			"Sorry, we couldn't find your policy. Please check your details and try again.",
		
		panelHeader: assets.panelHeader,
		layoutBreakpoint: assets.layoutBreakpoint,
	};

	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>

			<ContainerMaxWidth>
				
				<Switch>
					<Route path={`${baseRoute}`} exact={true}>
						<Landing {...landingBaseArguments} />{" "}
					</Route>

					<Route path={`${baseRoute}/tryagain`} exact={true}>
						<Landing
							errorMessage="Sorry, your payment has not been authorised. If you'd like to try again please continue below."
							{...landingBaseArguments}
						/>
					</Route>

					<Route path={`${baseRoute}/error`} exact={true}>
						<Error panelHeader={assets.panelHeader} />
					</Route>

					<Route path={`${baseRoute}/success`} exact={true}>
						<Success panelHeader={assets.panelHeader} />
					</Route>

					<Route>
						<Redirect to={`${baseRoute}`} />
					</Route>
				</Switch>
				
			</ContainerMaxWidth>
			
		</PageLayout>
	);
};

export default Payment;
