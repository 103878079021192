import React from "react";

const SplitPanel = (props) => {
	const { children, sidecontent, breakpoint, leftClass = "", rightClass = "" } = props;
	return (
		<div className="row">
			<div className={`col-12 col-${breakpoint}-8 ${leftClass}`}>{children}</div>
			<div className={`col-12 col-${breakpoint}-4 ${rightClass}`}>{sidecontent}</div>
		</div>
	);
};

export default SplitPanel;
