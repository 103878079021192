import TagManager from "react-gtm-module";

const dataLayerName = "dataLayer";
const gtmId = require("config").default.google.gtmId;

if (!module.hot) {
	if (window[dataLayerName] || dataLayerName === "_dataLayer")
		throw `Error in createAnalyticsManager -- ${dataLayerName} already exists`;
}

TagManager.initialize({
	gtmId,
	dataLayerName,
});

const _dataLayer = window[dataLayerName];

export const logRoute = (route) => {
	console.log("route change:", route);
	_dataLayer.push({
		event: "route_change",
		route: `${route}`,
	});
};

export default { logRoute };
