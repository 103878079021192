import React from "react";
export * from "libraryComponents/Display";

import * as LibComponents from "libraryComponents/Display";
import { HyperlinkRoute, ExternalLink } from "./Buttons";
import { Panel, PanelSection } from "./Layout";

//**** Custom && Overrides (replaces the export above) ****

export const DisplayItem = ({ ...otherProps }) => (
    <LibComponents.DisplayItemFlex
        {...otherProps}
    />
);

export const TwoStylePageTitle = ({iconContainerClass, leftTextClass, ...otherProps }) => (
    
    <LibComponents.TwoStylePageTitle
        iconContainerClass={
            [ 
                "rounded-icon", 
                iconContainerClass,
            ].filter(Boolean).join(" ")
        }
        leftTextClass={
            [
                "fw-semibold",
                leftTextClass,
            ].filter(Boolean).join(" ")
        }
        {...otherProps}
    />
)

export const OpexBreakdownTable = (props) => {

    const { coverLevels, benefitsData, documents } = props;

    return (

        <div className="row breakdown-table">

            {/* The title row */}
            <div className="col-3">{/* SPACER DIV */}</div>
            <div className="col-3 title text-center">
                {coverLevels[0].name}
            </div>
            <div className="col-3 title text-center">
                {coverLevels[1].name}
            </div>
            <div className="col-3 title text-center">
                {coverLevels[2].name}
            </div>

            {benefitsData.map((data, i) => {
                return (
                    <BenefitRow key={i} data={data} isShaded={(i % 2) !== 0} />
                );
            })}

            {documents && (
                <>
                    {documents.map((doc, i) => {
                        return <DocumentRow doc={doc} key={i} />
                    })}
                </>
            )}

        </div>
    );
};

const CenterInCol = "d-flex flex-row align-items-center justify-content-center";

const BenefitTick = (props) => {

    const { isIncluded, coverLevelId, className } = props;

    const _className = [
        "col-3",
        coverLevelId,
        className,
    ].filter((x) => x).join(" ");

    if (!isIncluded) {
        return (
            <div className={`${_className} ${CenterInCol}`}>
                <span className="cross-start"></span>
            </div>
        );
    }

    return (
        <div className={`${_className} ${CenterInCol}`}>
            <span className="tick-start"></span>
        </div>
    );
}

const BenefitRow = (props) => {

    const { data, isShaded } = props;
    const { id, name, levelIncludes, info } = data;

    const infoId = id + "_info";
    const shadedClass = isShaded ? " shaded" : "";

    return (
        <>
            <div className={[
                info ? "col-2" : "col-3",
                "benefit-text",
                shadedClass,
            ].filter((x) => x).join(" ")}>
                <span type="button" data-bs-toggle="collapse" data-bs-target={`#${infoId}`} aria-expanded="false" aria-controls={infoId}>{name}</span>
            </div>
            {info && (
                <div className={`col-1 px-0 ${CenterInCol} ${shadedClass}`}>
					<span className="ms-1 bi bi-info-circle collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${infoId}`} aria-expanded="false" aria-controls={infoId}>
					</span>
                </div>
            )}

            {levelIncludes.map((inc, i) => {
                return <BenefitTick key={i} isIncluded={inc.included} coverLevelId={inc.coverLevelId} className={shadedClass} />
            })}

            {info && (
                <div id={infoId} className={"col-12 benefit-info alert-info collapse"}>
                    <span>{info}</span>
                </div>
            )}

        </>
    );
}

const DocumentRow = (props) => {
    const { doc } = props;

    const showDateRow = doc.endDate;

    const rowClass = showDateRow ? "shaded" : "";

    return (
        <>
            {showDateRow && (
                <div className={"col-12 py-2 " + rowClass}>
                    <small>If your insurance policy {doc.endDateText ? doc.endDateText : "start date was"} <strong>before {doc.endDate}</strong>:</small>
                </div>
            )}

            <div className={"col-12 col-sm-3 py-1 fw-bold d-flex flex-row align-items-center " + rowClass}>Policy Documents</div>
            <div className={"col-3 d-sm-none " + rowClass}>{/* SPACER DIV */}</div>
            <div className={"col-9 py-3 " + rowClass}>
                <div className="row">
                    {doc.ipids.map((ipid, idx) => {
                        return (
                            <div key={idx} className="col-4 text-center pb-1 border-bottom">
                                <small><ExternalLink href={ipid} label={<span>Product Information</span>} nowrap={false} /></small>
                            </div>
                        );
                    })}

                    {doc.policyWordings.map((pw, idx) => {
                        return (
                            <div key={idx} className="col-4 text-center pt-1">
                                <small><ExternalLink href={pw} label="Policy Wording" nowrap={false} /></small>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

export const MakeAClaimDisplay = (props) => {

    const { claimType } = props;
    
    switch (claimType) {
        case "MOTOR-PANEL": {
            const number = require("config").default.claimsContacts.motor.number;

            return (
                <Panel 
                    className="has-thick-border"
                    header="Need to make a claim?"
                >
                    <PanelSection>
                        <div>
                            <strong>
                                We'll help you in the event of an accident - that's why we're here.
                            </strong>
                        </div>
                        <div>
                            Simply call us on{" "}
                            <LibComponents.TelephoneNumber phonenumber={number} isLink={true} />{" "}
                            to speak to one of our experienced claims handlers. It's best to get in contact
                            as soon as possible.
                        </div>
                        <div>
                            Even if you're not claiming for yourself, it's important to get in touch so we can
                            advise and deal with any claims made against you.
                        </div>
                        <div>
                            It would be helpful if you could provide us with the following details when you
                            first report the claim to us:
                        </div>
                        <LibComponents.ListItemsFlex
                            className="my-3"
                            bullet={<span className="bi bi-circle-fill"></span>}
                            listData={
                                [
                                    {content: <span>Your policy number</span>,},
                                    {content: <span>The registration number of your vehicle</span>,},
                                    {content: <span>The details of anybody else involved in the accident</span>,},
                                    {content: <span>As many details as you can about the event - this will help speed up your claim</span>,},
                                ]
                            }
                        />
                        <div>
                            Once the details are logged our dedicated claims team will be with you every step
                            of the way to resolve the claim as quickly and efficiently as possible.
                        </div>
                        <div>
                            <strong>
                                If you need to make a claim for one of your policy upgrades, please visit the{" "}
                                <HyperlinkRoute
                                    route="/make-a-claim"
                                    label="make a claim page"
                                    className="card-link"
                                    data-cy={`route-link:make-a-claim`}
                                /> for contact details.
                            </strong>
                        </div>
                    </PanelSection>
                </Panel>
            );
        }
        
        case "COMMERCIAL": {
            const number = require("config").default.claimsContacts.pl.number;
            
            return (
                <Panel
                    header="Need to make a claim?"
                    className="has-border"
                >
                    If you need to make a claim, please call us on{" "}
                    <LibComponents.TelephoneNumber phonenumber={number} isLink={true} />
                </Panel>
            );
        }
    }
}

export const PortalLinks = (props) => {
    const { login, register, className = "col-md-4 col-lg-3" } = props;
    
    const _className = [
        "mb-3",
        "col-12",
        className
    ].filter(Boolean).join(" ");
    
    return (
        <>
            <div className={_className}>
                <ExternalLink
                    href={login}
                    label="Log in"
                    className="btn btn-outline-primary w-100"
                    data-cy={`ext-link:portal-login`}
                />
            </div>
            <div>
                Don't have an account yet?{" "}
                <ExternalLink
                    href={register}
                    label="Create one"
                    data-cy={`ext-link:portal-register`}
                />.
            </div>
        </>
    )
}