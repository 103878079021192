import React, { useCallback, useMemo } from "react";

import HyperlinkOrig from "libraryComponentsForm/Hyperlink";
import ButtonOrig from "libraryComponentsForm/Button";
import history from "siteHistory";

export * from "./common";
export * from "./custom";

export const DocsBaseUrl =
	"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/";
export const DocsPreviousBaseUrl = DocsBaseUrl + "Replaced/";
export const OracleDocsBaseUrl =
	"https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/OracleDocuments/";

export const TwoStylePageTitle = require("./TwoStylePageTitle").default;
export const Section = require("./Section").default;
export const Breadcrumb = require("./Breadcrumb").default;
export const NavLink = require("./NavLink").default;
export const NavDropdown = require("./NavDropdown").default;

export const BaseCard = require("./BaseCard").default;
export const PolicyTypeCard = require("./PolicyTypeCard").default;
export const QuoteStartButton = require("./QuoteStartButton").default;
export const CoverLevelsCard = require("./CoverLevelsCard").default;
export const PolicyTierCard = require("./PolicyTierCard").default;
export const AccountCard = require("./AccountCard").default;
export const PolicyTypeOpexCards = require("./PolicyTypeOpexCards").default;
export const InsurersCarousel = require("./InsurersCarousel").default;
export const GapCoverCard = require("./GapCoverCard").default;
export const WhyChooseUs = require("./WhyChooseUs").default;

export const OpexBreakdownTable = require("./OpexBreakdownTable").default;
export const MakeAClaimDetails = require("./MakeAClaimDetails").default;
export const ClaimTableRow = require("./ClaimTableRow").default;

export const TickList = require("./TickList").default;
export const Accordian = require("./Accordian").default;
export const SlideIn = require("./SlideIn").default;
export const TrustBoxx = require("./TrustBox").default;

export const Button = (props) => {
	const {
		className = "",
		classNameDisabled = "",
		label,
		children,
		...otherProps
	} = props;

	return (
		<ButtonOrig
			className={[`btn`, className || ""].join(" ")}
			classNameDisabled={["disabled", classNameDisabled].join(" ")}
			label={label || children}
			children={children}
			{...otherProps}
		/>
	);
};

export const ExternalLink = (props) => {
	const {
		href,
		label,
		className = "",
		target = "_blank",
		nowrap = true,
	} = props;

	const nowrapClass = nowrap ? "whitespace-no-wrap" : "";

	return (
		<a
			className={`ext-link ${nowrapClass} ${className}`}
			target={target}
			rel="noopener noreferrer"
			href={href}
		>
			{label}
		</a>
	);
};

export const EmailLink = (props) => {
	const {
		emailAddress,
		altLabel,
		className = "",
		nowrap = true,
		isStrong = true,
	} = props;

	const nowrapClass = nowrap ? "whitespace-no-wrap" : "";
	const displayText = altLabel != undefined ? altLabel : emailAddress;
	const display = isStrong ? (
		<strong>{displayText}</strong>
	) : (
		<>{displayText}</>
	);

	return (
		<a
			className={`email-link  ${nowrapClass} ${className}`}
			href={`mailto:${emailAddress}`}
		>
			{display}
		</a>
	);
};

export const TelephoneNumber = (props) => {
	const {
		phonenumber,
		altLabel,
		className = "",
		isLink = false,
		isStrong = true,
	} = props;

	if (!isLink) {
		if (isStrong) {
			return (
				<strong className="tel-no-link whitespace-no-wrap">
					{phonenumber}
				</strong>
			);
		}
		return (
			<span className="tel-no-link whitespace-no-wrap">{phonenumber}</span>
		);
	}

	const displayText = altLabel != undefined ? altLabel : phonenumber;
	const display = isStrong ? (
		<strong>{displayText}</strong>
	) : (
		<span>{displayText}</span>
	);

	const linkNumber = phonenumber.replaceAll(" ", "");
	return (
		<a
			className={`tel-link whitespace-no-wrap ${className}`}
			href={`tel:${linkNumber}`}
		>
			{display}
		</a>
	);
};

export const Hyperlink = (props) => {
	const { className = "", ...otherProps } = props;
	return (
		<HyperlinkOrig
			className={`freedom-hyperlink ${className}`}
			{...otherProps}
		/>
	);
};

export const HyperlinkGoto = (props) => {
	const { className = "", route, ...otherProps } = props;

	const finalProps = useMemo(() => {
		let retObj = {
			className: ["freedom-hyperlink", className]
				.filter((x) => x !== undefined)
				.join(" "),
		};
		if (route) retObj.onClick = () => history.push(route);
		// if (href) retObj.href = href;

		return retObj;
	}, [route, className]);

	return <HyperlinkOrig {...otherProps} {...finalProps} />;
};
