import React, { useLayoutEffect, useRef, useState } from "react";


const SlideIn = (props) => {

	const { children, slideType, divId } = props;

    const ourRef = useRef(null);

    const [show, doShow] = useState({ showThis: true });
    const [height, changeHeight] = useState({ currentHeight: "100%" });

    useLayoutEffect(() => {
        const topPos = element => element.getBoundingClientRect().top;
        const getHeight = element => element.getBoundingClientRect().height;
        const divPos = topPos(ourRef.current);
        const divHeight = getHeight(ourRef.current);

        //console.log(ourRef.current);
        //console.log("Current Height", divHeight);
        //console.log("Slider TOP", divPos);

        const onScroll = () => {
            const scrollPos = window.scrollY + window.innerHeight;
            
            const scrollOffset = divHeight / 2;
            const scrollCheck = scrollPos - scrollOffset;

            if (divPos < scrollCheck) {
                doShow(state => ({ ...state, showThis: true }));
            }
            else {
                doShow(state => ({ ...state, showThis: false }));
            }

            if (slideType === "unroll") {
                // How many pixels of the element are visible
                const visiblePart = scrollCheck - divPos;

                // Less than zero: Element if off the bottom of the viewport
                if (visiblePart < 0) {
                    // Set the height to zero
                    changeHeight(state => ({ ...state, currentHeight: "0" }));
                }
                // Greater than element height: Element is fully visible (or off top of viewport)
                else if (visiblePart > divHeight) {
                    // Set the height to 100%;
                    changeHeight(state => ({ ...state, currentHeight: "100%" }));
                }
                else {
                    // Set the height to 100%;
                    changeHeight(state => ({ ...state, currentHeight: "100%" }));
                    //// Calculate percentage of the element that is visible
                    //const visiblePercent = (visiblePart / divHeight) * 100;   
                    //// Set the height to the calculated percentage
                    //changeHeight(state => ({ ...state, currentHeight: `${visiblePercent.toFixed(2)}%` }));
                }
            }
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
        /* 
           remove the event listener in the cleanup function 
           to prevent memory leaks
        */
    }, []);

    switch (slideType) {
        
        case "unroll": {
            //console.log("Calculated Height", height.currentHeight);
            
            return (
                <div ref={ourRef}
                    style={{ height: height.currentHeight }}
                    className={`animation-element ${slideType} ${show.showThis ? "in-view" : ""}`}>
                    {children}
                </div>
            );
        }

        default: {
            return (
                <div ref={ourRef} className={`animation-element ${slideType} ${show.showThis ? "in-view" : ""}`}>
                    {children}
                </div>
            );
        }
    }

};

export default SlideIn;
