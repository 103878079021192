import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { logRoute } from "siteManagers/analytics";

const RouteLogger = (props) => {
	const { children } = props;
	const { pathname } = useLocation();

	useEffect(() => {
		logRoute(pathname);
	}, [pathname]);

	return <>{children}</>;
};

export default RouteLogger;
