import React from "react";

import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ErrorWithIcon } from "siteComponentsLibrary/Feedback";

const PaymentError = (props) => {
	
	const { panelHeader } = props;
	
	return (
		<Panel header={panelHeader}>
			<PanelSection>
				<ErrorWithIcon>
					Sorry, we've had a technical problem with your payment. Please don't
					try to pay again and call us on 03332 205 445 and we'll be happy to
					help.
				</ErrorWithIcon>
			</PanelSection>
		</Panel>
	);
};

export default PaymentError;
