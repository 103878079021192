import React from "react";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

import { TelephoneNumber } from "./Display";

export const Card = (props) => {
    const {
        className,
        classNameHeader,
        classNameBody,
        classNameFooter,

        header,
        children,
        footer,

        imageSrc,
        imageAlt,
        imageLocation = "top",

    } = props;

    const classData = {
        container: ["lctr-card", "card", className].filter(Boolean).join(" "),
        header: ["lctr-card-header", "card-header", classNameHeader].filter(Boolean).join(" "),
        body: ["lctr-card-body", "card-body", classNameBody].filter(Boolean).join(" "),
        footer: ["lctr-card-footer", "card-footer", classNameFooter].filter(Boolean).join(" "),
    };

    return (
        <div
            className={classData.container}
            {...generateCypressProps("card", props)}
        >
            {imageSrc && imageLocation === "top" && (
                <img src={imageSrc} className="card-img-top" alt={imageAlt}/>
            )}

            {header && (
                <div className={classData.header} data-cy="card-header">
                    {header}
                </div>
            )}
            {children && (
                <div className={classData.body} data-cy="card-body">
                    {children}
                </div>
            )}

            {imageSrc && imageLocation === "bottom" && (
                <img src={imageSrc} className="card-img-bottom" alt={imageAlt}/>
            )}
            
            {footer && (
                <div className={classData.footer} data-cy="card-footer">
                    {footer}
                </div>
            )}

        </div>
    );
};

export const ImageOverlayCard = (props) => {
    const {
        className,
        classNameOverlay,
        classNameHeader,
        classNameBody,
        classNameFooter,
        
        header,
        children,
        footer,

        imageSrc,
        imageAlt,

    } = props;

    const classData = {
        container: ["lctr-card", "card", className].filter(Boolean).join(" "),
        overlay: ["card-img-overlay", classNameOverlay].filter(Boolean).join(" "),
        header: ["lctr-card-header", "card-header", classNameHeader].filter(Boolean).join(" "),
        body: ["lctr-card-body", "card-body", classNameBody].filter(Boolean).join(" "),
        footer: ["lctr-card-footer", "card-footer", classNameFooter].filter(Boolean).join(" "),
    };

    return (
        <div
            className={classData.container}
            {...generateCypressProps("card-overlay", props)}
        >
            {imageSrc && (
                <img src={imageSrc} className="card-img" alt={imageAlt}/>
            )}
            
            <div className={classData.overlay}>
                {header && (
                    <div className={classData.header} data-cy="card-header">
                        {header}
                    </div>
                )}
                {children && (
                    <div className={classData.body} data-cy="card-body">
                        {children}
                    </div>
                )}
                {footer && (
                    <div className={classData.footer} data-cy="card-footer">
                        {footer}
                    </div>
                )}
            </div>
        </div>
    );
}

export const OpexCard = (props) => {

    const { opexId, opexData, findOutMore } = props;

    if (!opexData || !opexData.summaryCard)
        return null;
    
    if (opexData.summaryCard.hideOnSummary)
        return null;

    const _className = "opex-card border-primary h-100";
    const _classNameHeader = "bg-transparent border-bottom-0 pt-3";
    const _classNameFooter = "bg-transparent border-top-0 text-center pb-3";

    const _header = <div>{opexData.heading}</div>;
    const _footer = (
        <div>
            <div className="mb-2">
                {findOutMore}
            </div>
            <div className="">
                To make a claim call <TelephoneNumber phonenumber={opexData.claimNumbers[0]}/>
            </div>
        </div>
    );

    if (opexData.summaryCard.imageOverlay && opexData.summaryCard.imgSrc)
        return (
            <ImageOverlayCard
                className={_className}
                classNameOverlay="p-0 d-flex flex-column"
                classNameHeader={_classNameHeader}
                classNameBody=""
                classNameFooter={_classNameFooter}
                header={_header}
                footer={_footer}                
                imageSrc={opexData.summaryCard.imgSrc}
            >
                {opexData.summaryCard.summaryText}
            </ImageOverlayCard>
        );

    return (
        <Card
            className={_className}
            classNameHeader={_classNameHeader}
            classNameBody=""
            classNameFooter={_classNameFooter}
            header={_header}
            footer={_footer}
        >
            {opexData.summaryCard.summaryText}            
        </Card>
    );
    
}

export const TelematicsCard = (props) => {
    const { title, data } = props;
    
    const { className, content, imgSrc, imgAlt } = data;
    
    const _className = [
        "telematics-card",
        className
    ].filter(Boolean).join(" ")
    
    return (
        <>
            <Card
                className={_className}
                classNameFooter="img-container"
                header={<strong>{title}</strong>}
                footer={
                    <img className="img-fluid" src={imgSrc} alt={imgAlt}/>
                }
            >
                <div className="description">{content}</div>
            </Card>
            {data.footnote && (
                <div className={`col-12 mt-2 ${data.footnoteClassName}`}>
                    <small>
                        {data.footnote}
                    </small>
                </div>
            )}
        </>
    );
}