import { useEffect } from "react";
import config from "config";

const useLiveChat = () => {
	const liveChatConfig = config.liveChat;
	
	const fnInit = () => {
		(function (g, e, n, es, ys) {
			g["_genesysJs"] = e;
			g[e] =
				g[e] ||
				function () {
					(g[e].q = g[e].q || []).push(arguments);
				};
			g[e].t = 1 * new Date();
			g[e].c = es;
			ys = document.createElement("script");
			ys.async = 1;
			ys.src = n;
			ys.charset = "utf-8";
			document.head.appendChild(ys);
		})(
			window,
			"Genesys",
			"https://apps.euw2.pure.cloud/genesys-bootstrap/genesys.min.js",
			{
				environment: "prod-euw2",
				deploymentId: "61ec1566-01d4-47cd-adbd-09f7971e2ed6",
			}
		);
	};

	useEffect(() => {
		if (!liveChatConfig) return;
		if (!liveChatConfig.useLiveChat) return;
		if (!liveChatConfig.environment) return;
		if (!liveChatConfig.deploymentId) return;
		if (window.Genesys) return; // Already ran

		fnInit();
	}, []);
};

export default useLiveChat;
