import React from "react";

import { HyperlinkGoto, TickList, ExternalLink } from "siteComponents";
import BaseCard from "./BaseCard";

const AccountCard = (props) => {

	const { hasShadow = true, cardHighlight = false, isGeneric = false } = props;

	const portalLogin = require("config").default.links.portal.login;
	const portalRegister = require("config").default.links.portal.register;

	const cardClass = "";

	return (
		<BaseCard
			cardClasses={cardClass}
			hasShadow={hasShadow}
			headerContent="Existing Customers"
			cardHighlight={cardHighlight}
			//titleContent="Existing Customers"
			cardContent={(
				<>
					<p>You can manage your {(isGeneric ? "car or van" : "")} policy online</p>
					<TickList
						className="mb-3"
						listData={[
							"View your documents",
							"Upload your no claims bonus",
							"Make changes to your policy"
						]}
					/>
					<div className="text-center">
						<HyperlinkGoto className="card-link" route="/my-account">
							Find out more
						</HyperlinkGoto>
					</div>
					{isGeneric && (
						<div className="text-center mt-3">
							<HyperlinkGoto className="card-link" route="/optional-cover">
								Need more information on your optional cover?
							</HyperlinkGoto>
						</div>
					)}
					
				</>
			)}
			footerContent={[
				<ExternalLink key="account-card-login" href={portalLogin} label="Log in" className="btn btn-primary" nowrap={false} />,
				<ExternalLink key="account-card-register" href={portalRegister} label="Register" className="btn btn-primary" nowrap={false} />
			]}
		/>
	);

};

export default AccountCard;
