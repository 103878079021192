import React from "react";
import { Helmet } from "react-helmet";

import { ContainerMaxWidth } from "siteComponentsLibrary/Layout";
import { TrustBox } from "siteComponentsLibrary/Misc";

import PageHeader from "./PageLayouts/PageHeaderV2";

const PageLayout = (props) => {
    const {
        children,
        pageId,
        pageTitle,
        pageClass,
        headingContent,
        headingText,
        subHeadingText,
        className,
        showTrustboxCarousel = true
    } = props;

    const _pageClass = [
        "page-v2",
        pageClass
    ]
        .filter(Boolean)
        .join(" ");

    const _className = [
        "main-panel",
        className
    ]
        .filter(Boolean)
        .join(" ");


    return (

        <div className={_pageClass}>

            <Helmet>
                <title>{!pageTitle ? headingText : pageTitle}</title>
            </Helmet>

            <PageHeader
                pageId={pageId}
                mainHeading={headingText}
                subHeading={subHeadingText}
            >
                {headingContent}
            </PageHeader>

            <div className={_className}>
                {children}

                {showTrustboxCarousel && (
                    <div className="container-fluid white-full-width trust-panel mt-5">
                        <ContainerMaxWidth>
                            <div className="row justify-content-center">
                                <div className="col-12 text-center mb-4">
                                    <h3 className="h4">What our customers are saying about us</h3>
                                </div>
                                <div className="col-12 text-center">
                                    {/* <!-- TrustBox widget - Carousel --> */}
                                    <TrustBox
                                        businessUnitId={require("config").default.trustpilot.businessUnitId}
                                        templateId={require("config").default.trustpilot.templates.carousel}
                                        fallbackLinkUrl={require("config").default.trustpilot.fallbackLink}
                                    />
                                    {/* <!-- End TrustBox widget --> */}
                                </div>
                            </div>
                        </ContainerMaxWidth>
                    </div>
                )}

            </div>
        </div>
    );
};

export default PageLayout;