import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

import { PriceContainer, PriceCardStaticDual, PriceCardStickyFull, PriceCardStickyMonthly } from './PriceDisplay/PriceContainers';
import { ExternalLink, Hyperlink } from "./Buttons";
import _ from "lodash";

export * from './PriceDisplay/PriceContainers';
export * from './PriceDisplay/PriceCards';
export * from './PriceDisplay/getPriceDetails';
export * from './PriceDisplay/IptPanels';

export const TelephoneNumber = (props) => {
	const {
		phonenumber,
		altLabel,
		className = "",
		isLink = false,
		isStrong = true,
	} = props;
	
	const _className = ["whitespace-no-wrap", className]
		.filter(Boolean)
		.join(" ");

	if (!isLink) {
		const noLinkClassName = ["tel-no-link", _className]
			.filter(Boolean)
			.join(" ");

		if (isStrong) {
			return (
				<strong
					className={noLinkClassName}
					{...generateCypressProps("telephone-number", props)}
				>
					{phonenumber}
				</strong>
			);
		}

		return (
			<span
				className={noLinkClassName}
				{...generateCypressProps("telephone-number", props)}
			>
				{phonenumber}
			</span>
		);
	}

	const displayText = altLabel !== undefined ? altLabel : phonenumber;
	const display = isStrong ? (
		<strong>{displayText}</strong>
	) : (
		<span>{displayText}</span>
	);

	const linkNumber = phonenumber.replaceAll(" ", "");
	return (
		<a
			className={["tel-link", _className].filter(Boolean).join(" ")}
			href={`tel:${linkNumber}`}
			{...generateCypressProps("telephone-number", props)}
		>
			{display}
		</a>
	);
};

export const EmailLink = (props) => {
	const {
		emailAddress,
		altLabel,
		className = "",
		nowrap = true,
		isStrong = true,
	} = props;

	const nowrapClass = nowrap ? "whitespace-no-wrap" : "";
	const displayText = altLabel !== undefined ? altLabel : emailAddress;
	const display = isStrong ? (
		<strong>{displayText}</strong>
	) : (
		<>{displayText}</>
	);

	return (
		<a
			className={`email-link  ${nowrapClass} ${className}`}
			href={`mailto:${emailAddress}`}
		>
			{display}
		</a>
	);
};

export const Help = (props) => {
	const { value, children, className = "", id } = props;

	if (!value && !children) return null;

	const _className = ["form-text", "help-text", className]
		.filter(Boolean)
		.join(" ");

	return (
		<div id={id} className={_className}>
			{value || children}
		</div>
	);
};

export const HelpFurther = (props) => {
	const { value, children, className, id } = props;
	const [isDisplayed, setIsDisplayed] = useState(false);

	if (!value && !children) return null;

	const _className = ["help-furtherinfo", className]
		.filter(Boolean)
		.join(" ");

	if (!isDisplayed)
		return (
			<div
				className={_className}
				{...generateCypressProps("help-further", props)}
			>
				<button
					className="btn btn-link btn-sm fw-bold ps-0"
					type="button"
					onClick={() => setIsDisplayed(true)}
				>
					More info
				</button>
			</div>
		);

	return (
		<Help id={id} className={_className}>
			{value || children}
		</Help>
	);
};

export const DisplayItemFlex = (props) => {
	const {
		dataCy,
		label,
		children,

		outerClass = "",
		labelClass = "",
		valueClass = "",

		isHorizontal = true,
		isHorizontalMobile = true,
		
		unwrap  = 'md'
		
	} = props;
	
	const _flexing = [
		isHorizontal && isHorizontalMobile && "flex-row", // Two columns on all breakpoints
		!isHorizontal && !isHorizontalMobile && `flex-column`, // Vertical stacked on all breakpoints
		!isHorizontal && isHorizontalMobile && `flex-row flex-${unwrap}-column`, // Two columns on mobile, stacked at unwrap
		isHorizontal && !isHorizontalMobile && `flex-column flex-${unwrap}-row`, // Stacked on mobile, two columns at unwrap
	].filter(Boolean).join(" ");
	
	const _horizontalIdClasses = [
		isHorizontalMobile ? `hz-mb` : ``,
		isHorizontal ? `hz-dp` : "",
	].filter(Boolean).join(" ");

	const _classNameOuter = [
		"display-item",
		"d-flex",
		_flexing,
		outerClass,
	].filter(Boolean).join(" ");

	const _classNameLabel = [
		"data-label",
		_horizontalIdClasses,		
		labelClass,
	].filter(Boolean).join(" ");

	const _classNameValue = [
		"data-value",
		_horizontalIdClasses,		
		valueClass,
	].filter(Boolean).join(" ");

	return (
		<div className={_classNameOuter} data-cy={dataCy}>
			{label && (
				<div className={_classNameLabel}>{label}</div>
			)}
			<div className={_classNameValue}>{children}</div>
		</div>
	);
}

export const DisplayItem = (props) => {
	const {
		dataCy,
		label,
		children,
		
		outerClass = "",
		labelClass = "",
		valueClass = "",

		isHorizontalFormat = true,
		isHzntlMobile = true,
		hzntDataCols = 7,
		hzntSmDataCols = 7,
		hzntMdDataCols = 7,
		hzntXlDataCols = 8,
	} = props;

	const _classNameOuter = [
		"row",
		"display-item",
		outerClass,
	].filter(Boolean).join(" ");

	const _classNameLabel = [
		isHzntlMobile ? `col-${12 - hzntDataCols} col-sm-${12 - hzntSmDataCols} hz-mb` : "col-12",
		isHorizontalFormat ? `col-md-${12 - hzntMdDataCols} col-xl-${12 - hzntXlDataCols} hz-dp` : "",
		"data-label",
		labelClass,
	].filter(Boolean).join(" ");

	const _classNameValue = [
		isHzntlMobile ? `col-${hzntDataCols} col-sm-${hzntSmDataCols} hz-mb` : "col-12",
		isHorizontalFormat ? `col-md-${hzntMdDataCols} col-xl-${hzntXlDataCols} hz-dp` : "",
		"data-value",
		valueClass,
	].filter(Boolean).join(" ");

	return (
		<div className={_classNameOuter} data-cy={dataCy}>
			{label && (
				<div className={_classNameLabel}>{label}</div>
			)}
			<div className={_classNameValue}>{children}</div>
		</div>
	);
};

export const VehicleCard = (props) => {
	
	const fnFormatCurrency = (v) =>
		new Intl.NumberFormat("en-GB", {
			style: "currency",
			currency: "GBP",
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
		}).format(v);


	const { regNumber, vehicleDescription, doors, seats, fuel, transmission, engineSize, value, valuationService = false, vehicleType } = props;
	
	const vehicleDetailsDisplay = [
		doors && `${doors} door`,
		seats && `${seats} seats`,
		fuel,
		transmission,
		engineSize && `${engineSize}cc`,
	].filter(Boolean).join(", ");
	
	return (
		<div className="card" data-cy="vehicle-summary">
			<div className="card-body">
				<div
					className="px-md-4 d-flex flex-column flex-wrap justify-content-start align-items-start flex-md-row flex-md-nowrap justify-content-md-between align-items-md-center"
				>
					<div className="order-1 order-md-2">
						<span
							className="vehicle-registration text-center d-inline-block overflow-wrap-normal mb-3 mb-md-0" data-cy="vehicle-reg">
							{regNumber}
						</span>
					</div>
					<div className="order-2 order-md-1 flex-md-fill">
						<div className="vehicle-description" data-cy="vehicle-description">{vehicleDescription}</div>
						<div data-cy="vehicle-details">{vehicleDetailsDisplay}</div>
					</div>
				</div>
				{value && 
					<div className="px-md-4">
						<div className="vehicle-found-value" data-cy="vehicle-found-value">
							Current value: <strong>{fnFormatCurrency(value)}</strong>
						</div>
						{valuationService && (
							<div className="help-text small">
								We've used an independent vehicle valuation service to value your {vehicleType}.
							</div>
						)}

					</div>
				}
          </div>
		</div>
	);
}

export const PriceDetails = (props) => {
	const {
		allowPayInFull, 
		allowPayMonthly,
		isSticky = false,
		selectedPayMethod,
		isStickyAnimationEnabled,
		payInFullPremium,
		instalmentDetails,
		postFixMonth,
		postFixYear,
	} = props;

	const isSelectedMonthly = selectedPayMethod === "M";

	if (!isSticky)
		return (
			<PriceContainer
				dataCy="price-dual"
				isStickyAnimationEnabled={isStickyAnimationEnabled}
			>
				<PriceCardStaticDual
					allowPayInFull={allowPayInFull}
					allowPayMonthly={allowPayMonthly}
					selectedPayMethod={selectedPayMethod}
					payInFullPremium={payInFullPremium}
					instalmentDetails={instalmentDetails}
					postFixMonth={postFixMonth}
					postFixYear={postFixYear}
				/>
			</PriceContainer>
		);

	if (allowPayMonthly && isSelectedMonthly)
		return (
			<PriceContainer
				dataCy="price-sticky-monthly"
				isStickyAnimationEnabled={isStickyAnimationEnabled}
			>
				<PriceCardStickyMonthly
					instalmentDetails={instalmentDetails}
					postFixMonth={postFixMonth}
				/>
			</PriceContainer>
		);

	if (!isSelectedMonthly)
		return (
			<PriceContainer
				dataCy="price-sticky-annual"
				isStickyAnimationEnabled={isStickyAnimationEnabled}
			>
				<PriceCardStickyFull
					allowPayMonthly={allowPayMonthly}
					payInFullPremium={payInFullPremium}
					instalmentDetails={instalmentDetails}
					postFixYear={postFixYear}
				/>
			</PriceContainer>
		);

	// Should never reach this part
	return undefined;
};

export const FinanceProvider = (props) => {
	const { provider = "pcl", imageSrc, className, classNameImg, classNameAddress } = props;
	
	const premiumCredit = (
		<address data-cy="address:premium-credit">
			<span className="provider-name">Premium Credit Limited</span>
			<span className="d-block">Premium Credit House</span>
			<span>60 East Street, Epsom</span>
			<span className="d-block d-md-inline"><span className="d-none d-md-inline">, </span>Surrey KT17 1HB</span>
		</address>
	);
	
	const providerAddress = provider === "pcl" ? premiumCredit : null;

	return (
		<div className="finance-provider d-flex">
			<div className="">
				<strong className="fw-bold">Finance is provided by</strong>
				{providerAddress}
			</div>
			<div className="d-flex align-items-center">
				<img
					src={imageSrc}
					alt="Direct Debit Logo"
					className={"img-fluid dd-img"}
				/>
			</div>
		</div>
	);
};

export const HeaderImages = (props) => {
	
	const { data, goToRoute } = props;
	
	if (!data)
		return null;
	
	const { desktopImage, mobileImage, desktopContent, mobileContent } = data;
	if (!desktopImage && !mobileImage)
		return null;
			
	return (
		<>
			<Carousel
				className="header-images"
				interval={null}
				wrap={false}
				controls={false}
				indicators={false}
				touch={false}
				fade={false}
				slide={false}
		>
			{desktopImage && (
				<Carousel.Item
					className="header-image desktop-img d-none d-md-block"
					style={{
						backgroundImage: "url(" + desktopImage + ")",
					}}
				>
					{desktopContent && (
						<Carousel.Caption>
							<div className="container-fluid container-lg layout-max-width">
								<div className="row">
									<div className="col-12 strap-line">
										{desktopContent.strapLine}
									</div>
									<div className="col-12 links d-flex gap-4">
										{desktopContent.links.map((x, i) => {
											return (
												<HeaderImageButton key={x.id} data={x} goToRoute={goToRoute} />
											);											
										})}
									</div>
								</div>
							</div>
						</Carousel.Caption>
					)}
				</Carousel.Item>
			)}
			{mobileImage && (
				<Carousel.Item
					className="header-image mobile-img d-block d-md-none"
					style={{
						backgroundImage: "url(" + mobileImage + ")",
					}}
				>
					{mobileContent && (
						<Carousel.Caption className="h-100">
							<div className="container d-flex h-100">
								<div className="row align-content-end">
									<div className="col-12 d-flex flex-wrap">
										{mobileContent.links.map((x, i) => {
											return (
												<HeaderImageButton key={x.id} data={x} goToRoute={goToRoute} />
											);											
										})}
									</div>
								</div>
							</div>
						</Carousel.Caption>
					)}
				</Carousel.Item>
			)}

			</Carousel>
		</>
	);
}

const HeaderImageButton = (props) => {
	const { goToRoute } = props;
	const { id, className, label, href, route, phoneNumber, breakAfter, } = props.data;

	if (!href && !route && !phoneNumber)
		return null;
	
	const _className = [
		"btn",
		"btn-primary",
		className
	].filter(Boolean).join(" ");
	
	const flexBreak = breakAfter && <div className="flex-break"></div>
	
	if (href) {
		return (
			<>
				<ExternalLink
					key={id}
					href={href}
					label={label}
					title={label}
					className={_className}
					data-cy={`ext-link:${id}`}
				/>
				{flexBreak}
			</>
			
		);
	}
	
	if (route) {
		return (
			<>
				<Hyperlink
					key={id}
					onClick={() => goToRoute(route)}
					label={label}
					title={label}
					className={_className}
					data-cy={`route-link:${id}`}
				/>
				{flexBreak}
			</>			
		);
	}
	
	if (phoneNumber) {
		return (
			<>
				<TelephoneNumber
					key={id}
					phonenumber={phoneNumber}
					altLabel={label}
					className={_className}
					isLink={true}
					data-cy={`tel-link:${id}`}
				/>
				{flexBreak}
			</>
		);
	}
	
	return null;
}

export const ListItemsFlex = (props) => {
	
	const { listData, className, bullet } = props;
	
	if (!listData)
		return null;

	const _className = [
		"list-style-flex",
		"d-flex",
		"flex-column",
		className
	].filter(Boolean).join(" ");

	return (
		<div 
			className={_className}
			{...generateCypressProps("list-style-flex", props)}
		>
			{listData.map((item, i) => {
				
				const itemBullet = 
					item.bullet ? item.bullet : item.hideBullet ? <></> : bullet;
				
				return (
					<div key={i} className="d-flex list-item">
						<span className="bullet align-self-stretch">{itemBullet}</span>
						<div className="d-inline">
							{item.content ?? item}
						</div>
					</div>
				);
			})}
		</div>
	);
}

export const TickList = (props) => {
	const { listData, className = "" } = props;

	return (
		<div className={`list-style-ticks d-flex flex-column ps-1 ${className}`}>
			{listData.map((item, i) => {
				return (
					<div key={i} className="d-flex">
						<span className="icon icon-tick text-success pe-2 align-self-stretch"></span>
						<div className="d-inline">
							{item}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export const AccordionItem = (props) => {
	const { index, heading, data, parentId, classType } = props;

	const headingId = `${parentId.replace("#", "")}heading${index}`;
	const collapseId = `${parentId.replace("#", "")}collapse${index}`;

	if (classType === "ListHeading")
		return (
			<div className="accordion-item">
				<div className="fs-4 px-3 py-3">{heading}</div>
			</div>
		);

	return (
		<div className="accordion-item">
			<h2 className="accordion-header" id={headingId}>
				<button
					className="accordion-button collapsed"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target={`#${collapseId}`}
					aria-expanded="false"
					aria-controls={collapseId}
				>
					{heading}
				</button>
			</h2>
			<div
				id={collapseId}
				className="accordion-collapse collapse"
				aria-labelledby={headingId}
				data-bs-parent={parentId}
			>
				<div className="accordion-body">{data}</div>
			</div>
		</div>
	);
}

export const Accordion = (props) => {
	const { accordionId, data } = props;

	const cleanId = accordionId
		.replaceAll(" ", "")
		.replaceAll(")", "") // Theo (ThingCo)
		.replaceAll("(", "") // Theo (ThingCo)
		.replaceAll("&", "") // Fees, Payments & Charges
		.replaceAll(",", ""); // Fees, Payments & Charges

	if (!_.isArray(data[0])) {
		return (
			<div className="accordion" id={cleanId}>
				{data.map((x, i) => (
					<AccordionItem
						key={i}
						index={i}
						heading={x.title}
						data={x.data}
						classType={x.classType}
						parentId={`#${cleanId}`}
					/>
				))}
			</div>
		);
	}

	return (
		<div className="accordion" id={cleanId}>
			{data.map(([heading, data], i) => (
				<AccordionItem
					key={i}
					index={i}
					heading={heading}
					data={data}
					parentId={`#${cleanId}`}
				/>
			))}
		</div>
	);
}

export const TwoStylePageTitle = (props) => {
	const {
		leftText,
		rightText,
		icon,
		iconImage,
		iconContainerClass,
		leftTextClass = "",
		rightTextClass = "",
	} = props;
	
	const _iconContainerClass = [
		"icon-image-container",
		"d-inline-block",
		"me-3",
		iconContainerClass,
	].filter(Boolean).join(" ");
	
	const _leftTextClass = [		
		leftTextClass,
	].filter(Boolean).join(" ");

	const _rightTextClass = [
		"ms-2",
		rightTextClass,
	].filter(Boolean).join(" ");

	if (icon) {
		return (
			<span className={_leftTextClass}>
				<span className={_iconContainerClass}>
					<span className={`icon ${icon}`} />
				</span>

				<span className="me-2">
					{leftText}&nbsp;
					<small className={_rightTextClass}>{rightText}</small>
				</span>
			</span>
		);
	}

	if (iconImage) {
		return (
			<span className={_leftTextClass}>
				<span className={_iconContainerClass}>{iconImage}</span>
				<span className="me-2">
					{leftText}&nbsp;
					<small className={_rightTextClass}>{rightText}</small>
				</span>
			</span>
		);
	}

	return (
		<span className={_leftTextClass}>
			{leftText}&nbsp;
			<small className={_rightTextClass}>{rightText}</small>
		</span>
	);
};

export const AppLinks = (props) => {
	const { apple, google } = props;

	if (!apple && !google) throw `Error in AppLinks`;

	return (
		<div className="app-links d-flex gap-3">
			{apple && (
				<ExternalLink
					href={apple.linkUrl}
					label={
						<img
							className=""
							alt="Download on the App Store"
							src={apple.appImage}
						/>
					}
					className="app-badge apple-badge"
					data-cy="app-links:apple"
				/>
			)}

			{google && (
				<ExternalLink
					href={google.linkUrl}
					label={
						<img
							className=""
							alt="Get it on Google Play"
							src={google.appImage}
						/>
					}
					className="app-badge google-badge"
					data-cy="app-links:google"
				/>
			)}
		</div>
	);
};