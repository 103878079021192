import React from "react";

import { ExternalLink } from "siteComponents";

const QuoteStartButton = (props) => {

	const { policyType, className, linkText, url } = props;

	const linkClass = `btn btn-lg btn-policytype py-3 fs-6 ${className}`;
	const iconClass = `icon icon-policytype-${policyType} d-block mb-2`;

	return (
			<ExternalLink
				href={url}
				label={(
					<>
						<span className={iconClass} />
						{linkText}
					</>
				)}
				className={linkClass}
			/>
	);
}

export default QuoteStartButton;