import React, { useCallback } from "react";
import history from "siteHistory";

import { Hyperlink, HyperlinkGoto, ExternalLink } from "siteComponents";

const gotoRoute = (route) => history.push(route);

const data = [
	[
		"Experiencing Financial Difficulty?",
		<div>
			<p>
				If you are experiencing financial difficulty because of the current
				coronavirus situation, please call our customer service team on{" "}
				<strong>03332 205 445</strong> to see what options are available to you.
			</p>
		</div>,
	],

	[
		"What number should I call to make a claim?",
		<div>
			<p>
				If you need to make a claim, please visit the&nbsp;
				<HyperlinkGoto className="card-link" route="/make-a-claim">
					make a claim page
				</HyperlinkGoto>{" "}
				for contact details.
			</p>
		</div>,
	],

	[
		"How do I register for my portal?",
		<div>
			<p>
				You need to ensure that you are using the same email address that you
				have provided on your policy.
			</p>
			<p>
				<img
					src={require("siteAssets/images/views/FAQs/portalregistration.jpg")}
					alt="portal"
				/>
			</p>
			<p>
				Once you have filled in your information, you will receive an activation
				email from us to proceed with logging into your account.
			</p>
			<p>
				You also need to ensure that Postcode, Date of Birth and Web reference
				coincide with what is stated on your policy.
			</p>
			<p>
				<img
					src={require("siteAssets/images/views/FAQs/portalregistration2.jpg")}
					alt=""
				/>
			</p>
			<p>
				You will be able to find your Web reference number on the Welcome Email
				that we sent to you when you purchased your policy.
			</p>
			<p>
				<ExternalLink
					href={require("config").default.links.portal.register}
					label={<strong>Go to the online account registration page</strong>}
				/>
			</p>
		</div>,
	],

	[
		"I have forgotten my security questions, what should I do?",
		<div>
			<p>
				If you have forgotten your security questions when attempting to reset
				your account details, please call us on <strong>03332 205445</strong>{" "}
				and we can reset this for you.
			</p>
		</div>,
	],

	[
		"I have forgotten my password, what should I do?",
		<div>
			<p>
				If you have forgotten your password to access your online account,
				please use the 'Forgotten your password' link on{" "}
				<ExternalLink
					href={require("config").default.links.portal.login}
					label={<strong>the login page</strong>}
				/>{" "}
				of your online portal.
			</p>
			<p>
				You will need to ensure that you are using the same email address and
				Postcode as provided on your policy.
			</p>
			<p>
				<img
					src={require("siteAssets/images/views/FAQs/portalregistration3.jpg")}
					alt=""
				/>
			</p>
			<p>
				You will need to answer your security questions to receive a Password
				Reset link to your email.
			</p>
			<p>
				<ExternalLink
					href={require("config").default.links.portal.login}
					label={<strong>Go to the online account login page</strong>}
				/>
			</p>
		</div>,
	],

	[
		"How do I send my documents?",
		<div>
			<p>
				In order to send your documents to us, please{" "}
				<ExternalLink
					href={require("config").default.links.portal.login}
					label={<strong>login to your online portal</strong>}
				/>
				. Here you will be able to use the 'Upload Your Documents' button to
				upload the requested documents.
			</p>
			<p>
				Please ensure that you are following the steps displayed on your portal
				to upload your documents.
			</p>
		</div>,
	],

	[
		"Where do I get my web reference number from?",
		<div>
			<p>
				To find your web reference number, you will need to read the Welcome
				Email we sent to you when your first purchased your policy. The web
				reference number is located at the top of this email.
			</p>
		</div>,
	],

	[
		"I have not received a call before my policy was cancelled?",
		<div>
			<p>
				We will always aim to communicate through your chosen method of
				communication from us, whether this be Email or Post.
			</p>
		</div>,
	],

	[
		"Does my policy cover driving other cars?",
		<div>
			<p>
				In order to see whether you are covered for driving other cars, you will
				need to check your <strong>Schedule and/or Certificate</strong> that was
				sent to you when you purchased the policy.
			</p>
		</div>,
	],

	[
		"Premium Credit – payment/amendment queries",
		<div>
			<p>
				If you are wanting to discuss your payments, please contact Premium
				Credit Ltd on <strong>0344 736 9836</strong>. If you have received any
				correspondance from Premium Credit Ltd, please look to contact them
				first to discuss the matter before contacting us.
			</p>
		</div>,
	],

	[
		"Can I use my no claims discount on more than one policy/vehicle?",
		<div>
			<p>
				You are unable to use your No Claims Discount on more than one
				policy/vehicle, as this can only be used for one active policy at a
				time.
			</p>
		</div>,
	],

	[
		"Why do we need to know about a gap in cover?",
		<div>
			<p>
				We need to be informed of any gap in cover you may have had to conform
				with the Continuous Insurance Enforcement Law. You can find out more
				information{" "}
				<ExternalLink
					href="https://www.gov.uk/government/publications/contiuous-insurance-enforcement-information"
					label="on the gov.uk website"
				/>
				.
			</p>
		</div>,
	],

	[
		"Why would I need to SORN my vehicle if it is on my driveway?",
		<div>
			<p>
				You will need to SORN your vehicle to ensure that you are able to stop
				taxing and insuring the vehicle.
			</p>
			<p>You must make a SORN in any of the following situations: </p>
			<ul>
				<li>Your vehicle isn't taxed</li>
				<li>
					Your vehicle isn't insured (even for a short time, for example because
					there's a delay renewing your policy)
				</li>
				<li>You want to break a vehicle down for parts before you scrap it</li>
				<li>
					You buy or receive a vehicle and want to keep it off the road (you
					can't transfer a SORN from the previous keeper).
				</li>
			</ul>
		</div>,
	],

	[
		"Can I have a receipt or invoice for what I have paid?",
		<div>
			<p>
				We do not issue receipts or invoices in regards to any payments that
				have been made to us. There is no VAT charged on an insurance policy.
			</p>
		</div>,
	],

	[
		"Do you offer multi vehicle policies?",
		<div>
			<p>
				We currently do not offer multi vehicle policies, but we do offer a
				range of mirrored no claims bonus schemes.
			</p>
		</div>,
	],

	[
		"Do I have a cooling off period of 14 days to cancel my policy?",
		<div>
			<p>
				You are entitled to a cooling off period of 14 days, however Terms and
				Conditions apply. To find out more, see our{" "}
				<Hyperlink
					label="terms of business"
					onClick={() => gotoRoute("/terms-of-business")}
				/>
				.
			</p>
		</div>,
	],

	[
		"I am unable to amend my current registration number to a cherished/private plate on the portal?",
		<div id="hello25">
			<p>
				If you want to change your vehicle registration number on your online
				account, you will need to ensure that the registration number has been
				registered with the DVLA.
			</p>
			<p>
				If you have not yet registered your new registration number, you will
				need to contact us on <strong>03332 205445</strong> and we will amend
				this for you.
			</p>
		</div>,
	],

	[
		"Can I put my insurance on hold whilst I am away/not using the vehicle?",
		<div id="hello9">
			<p>
				You are unable to place your insurance policy on hold with us if you are
				away or not using your vehicle. The policy that is provided to you is an{" "}
				<strong>annual term (12 month term)</strong> policy, therefore meaning
				that we will provide you with the policy for a total amount of 12 months
				until your policy end date.
			</p>
			<p>
				You are able to cancel the policy with us if necessary, however this
				will incur fees that will be charged to you.
			</p>
		</div>,
	],

	[
		"How do I get my proof of no claims bonus?",
		<div id="hello10">
			<p>
				In order to get your Proof of NCB from us, we can send you an email
				stating the amount of NCB years that you have or we are able to send you
				a Proof of NCB letter to your address.
			</p>
			<p>
				If your policy is due for renewal, you will automatically be sent a copy
				of your Proof of NCB. We are only able to provide your Proof of NCB when
				your policy has ended/due for renewal.
			</p>
		</div>,
	],

	[
		"When can I expect to receive a renewal price?",
		<div id="hello11">
			<p>
				We will look to send you a renewal price <strong>21 days</strong> before
				your policy is due to end. This will be sent to you in a letter to your
				address and you will receive an email from us advising that your policy
				is due for renewal.
			</p>
			<p>
				If you are aware that your policy is coming to an end and you have not
				received any correspondence from us, please call us on{" "}
				<strong>03332 205 445</strong> and we will arrange this for you.
			</p>
		</div>,
	],

	[
		"Why is my vehicle not appearing as insured on the Motor Insurance Database?",
		<div id="hello12">
			<p>
				Please allow 72 hours for your vehicle to show on askMID. This allows
				for the database to be updated to show your vehicle as being insured.
			</p>
			<p>
				<strong>PLEASE NOTE:</strong> If the start date of your policy is in the
				future, your vehicle will not appear on askMID as insured until that
				date.
			</p>
		</div>,
	],

	//[
	//	"Where can I find my Legal Expenses Cover contact number?",
	//	<div id="hello26">
	//		<p>
	//			If you are wanting to make a claim with your Legal Expenses cover,
	//			please call <strong>0800 1300 442</strong>.
	//		</p>
	//	</div>,
	//],

	//[
	//	"Where can I find my Excess Protection contact number?",
	//	<div id="hello13">
	//		<p>
	//			If you are wanting to make a claim with your excess protection cover,
	//			the contact number is stated within your policy wording.
	//		</p>
	//		<p>
	//			If your insurance policy start date was before the 02/09/2018, please
	//			call <strong>0844 324 8420.</strong>
	//		</p>
	//		<p>
	//			If your insurance policy start date was after the 02/09/2018, please
	//			call <strong>0203 503 0500.</strong>
	//		</p>
	//		<p>
	//			You may be asked to provide your scheme code which can be found within
	//			your policy wording.
	//		</p>
	//	</div>,
	//],

	//[
	//	"Where can I find my Replacement Hire Vehicle contact number?",
	//	<div id="hello14">
	//		<p>
	//			If you are wanting to make a claim with your replacement hire vehicle
	//			cover, the contact number is stated within your policy wording.
	//		</p>
	//		<p>
	//			If your insurance policy start date was before the 01/09/2019, please
	//			call <strong>0800 073 3091.</strong>
	//		</p>
	//		<p>
	//			If your insurance policy start date was on or after the 01/09/2019,
	//			please call <strong>0345 543 9931.</strong>
	//		</p>
	//	</div>,
	//],

	//[
	//	"Where can I find my Key Cover contact number?",
	//	<div id="hello15">
	//		<p>
	//			If you are wanting to make a claim with your key cover, please call{" "}
	//			<strong>0203 794 9309.</strong>
	//		</p>
	//	</div>,
	//],

	//[
	//	"Where can I find my Licence Defence contact number?",
	//	<div id="hello16">
	//		<p>
	//			If you are wanting to make a claim with Licence Defence, the contact
	//			number is stated within your policy wording. Please call{" "}
	//			<strong>0330 024 1727</strong> and quote{" "}
	//			<strong>“Licence Defence”</strong>.
	//		</p>
	//	</div>,
	//],

	//[
	//	"Where can I find my Tools In Transit Cover contact number?",
	//	<div id="hello17">
	//		<p>
	//			If you are wanting to make a claim under your tools in transit cover,
	//			the contact number can be found within your policy wording.
	//		</p>
	//		<p>
	//			If your insurance policy start date was before the 31/10/2018, please
	//			call <strong>0344 893 1022</strong>.
	//		</p>
	//		<p>
	//			If your insurance policy start date was on or after the 01/11/2018,
	//			please call <strong>0203 794 9305</strong>.
	//		</p>
	//		<p>
	//			If your insurance policy start date was on or after the 17/06/2019,
	//			please call <strong>0203 794 9305</strong>.
	//		</p>
	//	</div>,
	//],

	//[
	//	"Where can I find my Breakdown Cover contact number?",
	//	<div id="hello4">
	//		<p>
	//			If you find yourself in the unfortunate circumstance of your vehicle
	//			breaking down, you can always call the 24 hour Breakdown Control Centre
	//			on <strong>01245 408 492</strong> or <strong>0330 123 0724</strong>.
	//			Please ensure that you have the following information available:
	//		</p>
	//		<ul>
	//			<li>
	//				The document number and the person named as 'the insured’ in the
	//				policy schedule.
	//			</li>
	//			<li>The phone number you are calling from.</li>
	//			<li>
	//				The vehicle details such as registration number, make, model, colour,
	//				transmission type etc.
	//			</li>
	//			<li>
	//				The location of the broken down vehicle, including road numbers or
	//				names and landmarks.
	//			</li>
	//			<li>
	//				Any vehicle modifications which may be relevant to the recovery of the
	//				vehicle.
	//			</li>
	//		</ul>
	//		<p>
	//			The contact number for your Breakdown Cover is also available within
	//			your policy wording.
	//		</p>
	//	</div>,
	//],
];

export default data;
