import React from "react";

import Carousel from "./Carousel";
import InsurerGroup from "./InsurerGroup";

const InsurersCarousel = (props) => {

	const { policyType = "ALL", showIndicators = true } = props;
    
    const data = require("siteAssets/datafiles/insurerData").default(policyType, 3);

    return (
        <>
            <h2 className="text-purple h3 mb-4">Our panel of insurers</h2>

            <Carousel
                carouselId={`insurerCarousel${policyType}`}
                className="insurer-carousel"
                slides={(
                    data.map((insurerGroup, i) => {
                        return (
                            <div className="container">
                                <InsurerGroup insurerData={insurerGroup} />
                            </div>
                        );
                    })
                )}
            />
        </>
        );

};

export default InsurersCarousel;