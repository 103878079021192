import React from "react";

const InsurerGroup = (props) => {
	const { insurerData, insurerId } = props;

	if (insurerData == undefined) {
		return null;
    }

    return (
        <div className="row justify-content-center">
            {insurerData.map((insurer, i) => {
                return (
                    <div key={i} className="col-md-4 mb-3 d-flex align-items-center justify-content-center">
                        <img src={insurer.logoImage} alt={insurer.logoImageAlt} className="carousel-img img-fluidx" />
                    </div>
                );
            })}
        </div>
	);
};

export default InsurerGroup;