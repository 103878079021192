import React from "react";

const TwoStylePageTitle = (props) => {
	const {
		leftText,
		rightText,
		icon,
		iconImage,
		leftTextClass = "",
		rightTextClass = "",
	} = props;

	if (icon) {
		return (
			<span className={`fw-600 ${leftTextClass}`}>
				<span className="d-inline-block rounded-icon me-3">
					<span className={`icon ${icon}`} />
				</span>

				<span className="me-2">
					{leftText}
					<small className={`ms-2 fw-200 ${rightTextClass}`}>{rightText}</small>
				</span>
			</span>
		);
	}

	if (iconImage) {
		return (
			<span className={`fw-600 ${leftTextClass}`}>
				<span className="d-inline-block icon-image-container me-3">{iconImage}</span>
				<span className="me-2">
					{leftText}
					<small className={`ms-2 fw-200 ${rightTextClass}`}>{rightText}</small>
				</span>
			</span>
		);
	}

	return (
		<span className={`fw-600 ${leftTextClass}`}>
			{leftText}&nbsp;
			<small className={`fw-200 ${rightTextClass}`}>{rightText}</small>
		</span>
	);
};

export default TwoStylePageTitle;
