import React from "react";

import Layout from "siteLayouts/PageLayouts/OpexInfoPage";

const assets = require("siteAssets/pageManager").default(
	"motor-prosecution-defence"
);

const MotorProsecutionDefence = (props) => {
	return <Layout assets={assets} />;
};

export default MotorProsecutionDefence;
