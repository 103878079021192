import React from "react";

import { HyperlinkGoto, ExternalLink } from "siteComponents";

const ClaimTableRow = (props) => {

	const { productType, contactNumber, email, contactUrl, contactUrlText, moreInfoRoute, moreInfoText, previousClaimNumbers } = props;

	return (
		<>
			<dt className="col-md-5">{productType}</dt>
			<dd className="col-md-7">
				{contactNumber && (
					<span className="d-block">
						{contactNumber}
					</span>
				)}

				{email && (
					<span className="d-block">
						<a href={`mailto:${email}`}>{email}</a>
					</span>
				)}

				{contactUrl && (
					<span className="d-block">
						<ExternalLink href={contactUrl} label={contactUrlText} />
					</span>
				)}

				{moreInfoRoute && (
					<span className="d-block">
						<HyperlinkGoto className="card-link" route={moreInfoRoute}>
							{moreInfoText ? moreInfoText : "More info"}
						</HyperlinkGoto>
					</span>
				)}

			</dd>
			{previousClaimNumbers && (
				<dd className="col-12 alert alert-info small">
					<span className="mt-1">For policies purchased before {previousClaimNumbers.endDate}, please call </span>
					{previousClaimNumbers.claimNumbers.map((num, i) => {
						return (
							<span key={i} className="">
								{i > 0 ? " or " : ""}{num}
							</span>
						);
					})}
				</dd>
			)}
		</>
	);

}

export default ClaimTableRow;