import React from "react";
import history from "siteHistory";
import { Hyperlink } from "siteComponents";
const gotoRoute = (route) => history.push(route);

const Breadcrumb = (props) => {

    const { routes, hideOnSingle = true, includesCurrent = false } = props;

    if (!routes)
        return null;

    if (routes.length < 1)
        return null;

    if (routes.length === 1 && hideOnSingle)
        return null;

    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {routes.map((x, i) => {
                    const isActive = i === (routes.length - 1);
                    
                    if (isActive && includesCurrent) {
                        <li key={i} className={`breadcrumb-item ${isActive ? "active" : ""}`} aria-current="page">{x.label}</li>
                    }

                    return (                        
                        <li key={i} className="breadcrumb-item">
                            <Hyperlink
                                key={x.label}
                                label={x.label}
                                onClick={() => gotoRoute(x.route)}
                            />
                        </li>
                    );
                })}                
            </ol>
        </nav>
    );
};

export default Breadcrumb;
