import React from "react";
import { ThreeDots } from "react-loading-icons";

const AnimatedDots = (props) => {
	const { colour = "#06bcee", strokeWidth = 100, width, height } = props;

	return (
		<ThreeDots
			// stroke={"#CCC"}
			fill={colour}
			strokeWidth={strokeWidth}
			width={width}
			height={height}
		/>
	);
};

export default AnimatedDots;
