import React from "react";

export * from "libraryComponents/Cards";


import * as LibComponents from "libraryComponents/Cards";

import { HyperlinkRoute } from "./Buttons";

export const OpexSummary = (props) => {
    
    const { data } = props;
    
    return (
        <div className={"opex-summary row g-3"}>
            {data.map((opex, i) => {

                const _className = ["col-12", opex.summaryCard.layoutClass].filter(Boolean).join(" ");

                return (
                    <div key={opex.groupCode} className={_className}>
                        <LibComponents.OpexCard
                            opexId={opex.groupCode}
                            isImageOverlay={false}
                            opexData={opex}
                            findOutMore={
                                <HyperlinkRoute className="card-link" route={opex.route}>
                                    {/* To make the whole card clickable, add the class "stretched-link" */}
                                    Find out more
                                </HyperlinkRoute>
                            }
                        />
                    </div>
                )
            })}
        </div>
    )

}

   