import React from "react";

import { ExternalLink } from "siteComponents";

const CenterInCol = "d-flex flex-row align-items-center justify-content-center";

const BenefitTick = (props) => {

	const { isIncluded, coverLevelId, className } = props;

	const _className = [
		"col-3",
		coverLevelId,
		className,
	].filter((x) => x).join(" ");

	if (!isIncluded) {
		return (
			<div className={`${_className} ${CenterInCol}`}>
				<span className="cross-start"></span>
			</div>
		);
	}

	return (
		<div className={`${_className} ${CenterInCol}`}>
			<span className="tick-start"></span>
		</div>
	);
}

const BenefitRow = (props) => {

	const { data, isShaded } = props;
	const { id, name, levelIncludes, info } = data;

	const infoId = id + "_info";
	const shadedClass = isShaded ? " shaded" : "";

	return (
		<>
			<div className={[
				info ? "col-2" : "col-3",
				"benefit-text",
				shadedClass,
			].filter((x) => x).join(" ")}>
				<span type="button" data-bs-toggle="collapse" data-bs-target={`#${infoId}`} aria-expanded="false" aria-controls={infoId}>{name}</span>
			</div>
			{info && (
				<div className={`col-1 px-0 ${CenterInCol} ${shadedClass}`}>
					<span className="ms-1 bi bi-info-circle collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${infoId}`} aria-expanded="false" aria-controls={infoId}>
					</span>
				</div>
			)}

			{levelIncludes.map((inc, i) => {
				return <BenefitTick key={i} isIncluded={inc.included} coverLevelId={inc.coverLevelId} className={shadedClass} />
			})}

			{info && (
				<div id={infoId} className={"col-12 benefit-info alert-info collapse"}>
					<span>{info}</span>
				</div>
			)}

		</>
	);
}

const DocumentRow = (props) => {
	const { doc } = props;

	const showDateRow = doc.endDate;

	const rowClass = showDateRow ? "shaded" : "";

	return (
		<>
			{showDateRow && (
				<div className={"col-12 py-2 " + rowClass}>
					<small>If your insurance policy {doc.endDateText ? doc.endDateText : "start date was"} <strong>before {doc.endDate}</strong>:</small>
				</div>
			)}

			<div className={"col-12 col-sm-3 py-1 fw-bold d-flex flex-row align-items-center " + rowClass}>Policy Documents</div>
			<div className={"col-3 d-sm-none " + rowClass}>{/* SPACER DIV */}</div>
			<div className={"col-9 py-3 " + rowClass}>
				<div className="row">
					{doc.ipids.map((ipid, idx) => {
						return (
							<div key={idx} className="col-4 text-center pb-1 border-bottom">
								<small><ExternalLink href={ipid} label={<span>Product Information</span>} nowrap={false} /></small>
							</div>
						);
					})}

					{doc.policyWordings.map((pw, idx) => {
						return (
							<div key={idx} className="col-4 text-center pt-1">
								<small><ExternalLink href={pw} label="Policy Wording" nowrap={false} /></small>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

const OpexBreakdownTable = (props) => {

	const { coverLevels, benefitsData, documents } = props;

	return (

		<div className="row breakdown-table">

			{/* The title row */}
			<div className="col-3">{/* SPACER DIV */}</div>
			<div className="col-3 title text-center">
				{coverLevels[0].name}
			</div>
			<div className="col-3 title text-center">
				{coverLevels[1].name}
			</div>
			<div className="col-3 title text-center">
				{coverLevels[2].name}
			</div>

			{benefitsData.map((data, i) => {
				return (
					<BenefitRow key={i} data={data} isShaded={(i % 2) != 0} />
				);
			})}

			{/*
			<div className="col-3 price py-2 border-top border-bottom">Price</div>

			{coverLevels.map((coverLevel) => {

				return (
					<div key={coverLevel.id} className={`col-3 price py-2 text-center border-top border-bottom`}>
						<span className="money-value">{coverLevel.price}</span>
					</div>
				);
			})}
			*/}

			{documents && (
				<>
					{documents.map((doc, i) => {
						return <DocumentRow doc={doc} key={i} />
					})}
				</>
			)}

		</div>
	);
};

export default OpexBreakdownTable;