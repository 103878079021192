// common components
import React, { useState, useCallback } from "react";
import _DatePickerTextBox from "@library/common/components/base/form/DatePicker/Textbox";
import _TextBox from "@library/common/components/base/form/TextBox";
import _ModalLoading from "@library/common/components/base/form/Modal/Loading";
import _ButtonOrig from "@library/common/components/base/form/Button";
import LibraryDropDownType from "@library/common/components/base/form/DropDown/Type";
import LibraryRadioButtons from "@library/common/components/base/form/RadioButtons";

import PanelLoading from "./Panel/Loading";

export * from "./custom";
export const AlertError = require("./Alert/Error").default;
export const AlertErrorSmall = require("./Alert/Error").AlertErrorSmall;

export const AlertWarning = require("./Alert/Warning").default;
export const AlertSuccess = require("./Alert/Success").default;

const generateClass = (array = []) => array.filter((x) => x).join(" ");

export const Help = require("./Help").default;

export const Button = (props) => {
	const { className = "", classNameDisabled = "", ...otherProps } = props;

	const _className = ["btn", className].filter((x) => x).join(" ");

	const _classNameDisabled = [classNameDisabled].filter((x) => x).join(" ");

	return (
		<ButtonOrig
			className={_className}
			classNameDisabled={_classNameDisabled}
			{...otherProps}
		/>
	);
};

export const RadioButtons = (props) => {
	const { className, itemData, showGap = true, forceYesNo = false } = props;

	const isYesNo =
		itemData &&
		((itemData[0].label == "Yes" && itemData[1].label == "No") || forceYesNo);

	// outer DIV className
	const _className = generateClass([
		"lctr-radiobuttons-container",
		//"row",  // ** Commmented out 07/10 to fix mobile wiggle **
		// "fsg-btn-group",
		"fb-radio-container",
		isYesNo ? "yes-no" : "",
		showGap ? "space-buttons" : "",
		className,
	]);

	// individual item classes
	const _itemClassName = generateClass(["btn", "fb-radio-btn"]);
	const _itemClassNameSelected = generateClass(["btn-outline-fb"]);
	const _itemClassNameUnselected = generateClass(["btn-outline-fb"]);

	return (
		<div className={_className}>
			<LibraryRadioButtons
				itemClassName={_itemClassName} // the label
				itemClassNameSelected={_itemClassNameSelected}
				itemClassNameUnselected={_itemClassNameUnselected}
				{...props}
			/>
		</div>
	);
};

export const TextBox = (props) => {
	const { className, ...otherProps } = props;

	const _className = generateClass(["form-control", className]);

	return <_TextBox className={_className} {...otherProps} />;
};

export const DatePickerTextBox = (props) => {
	const { className, ...otherProps } = props;

	const _className = ["lctr-library-datePicker-text", "row", className]
		.filter((x) => x)
		.join(" ");

	return (
		<div className={_className}>
			<_DatePickerTextBox
				{...otherProps}
				TextBox={TextBox}
				classNameDay="col-4 col-md-2 pe-md-0 pe-xl-3"
				classNameMonth="col-4 col-md-2 pe-md-0 pe-xl-3"
				classNameYear="col-4 col-md-3 col-xl-2"
			/>
		</div>
	);
};

export const ModalLoading = (props) => {
	const { children: headingText, ...otherProps } = props;

	const [currentMessage, setCurrentMessage] = useState();
	const messages = useCallback(
		[
			"Please wait a moment...",
			"Sorry, this is taking longer than expected. Please wait...",
		],
		[]
	);

	const className = [
		"modal-main",
		"p-0",
		"m-0",
		"col-10",
		"col-md-6",
		"col-lg-5",
		"bg-transparent",
	].join(" ");
	const overlayClassName = ["modal-overlay"].join(" ");

	return (
		<_ModalLoading
			className={className}
			overlayClassName={overlayClassName}
			onMessageChange={(m) => setCurrentMessage(m)}
			cycleDelay={15000}
			cycleLoop={false}
			messages={messages}
			{...otherProps}
		>
			<PanelLoading header={headingText}>{currentMessage}</PanelLoading>
		</_ModalLoading>
	);
};

export const ModalLoadingOpened = (props) => (
	<ModalLoading {...props} isOpen={true} />
);

export const TextArea = (props) => {
	const {
		value,
		onChange,
		rows = "10",
		cols = "40",
		className,
		...otherProps
	} = props;

	const _className = ["form-control", className].filter((x) => x).join(" ");
	const _value = value === undefined ? "" : value; // Changed "undefined" to "", else <textarea /> doesn't recognise the change

	return (
		<textarea
			value={_value}
			onChange={(e) => {
				e.preventDefault();
				onChange(e.target.value);
			}}
			rows={rows}
			cols={cols}
			className={_className}
			{...otherProps}
		/>
	);
};

const dropdownStyle = {
	container: (base) => ({
		...base,
		paddingLeft: 0,
		paddingRight: 0,
		//zIndex: 10,
		// flex: 1,
	}),
	groupHeading: (base) => ({
		...base,
		color: "green",
		fontWeight: "bold",
	}),
	menu: (base) => ({
		...base,
		zIndex: 20, // make sure it's on top of everything else
	}),
	// option: (provided, state) => ({
	// 	...provided,
	// 	borderBottom: "1px dotted pink",
	// 	color: state.isSelected ? "red" : "blue",
	// 	padding: 20,
	// 	zIndex:200,
	// }),
};
export const DropDownType = (props) => {
	const { className = "", ...otherProps } = props;

	const _className = generateClass([className, "fb-select", "align-middle"]);

	return (
		<LibraryDropDownType
			className={_className}
			classNamePrefix="dropdown-type"
			styles={dropdownStyle}
			isClearable
			{...otherProps}
		/>
	);
};

export const DropDown = (props) => {
	// use the dropDownType/react-select component
	return <DropDownType {...props} isSearchable={false} isClearable={true} />;
};
