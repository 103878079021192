import React from "react";
import { InfoCircleFill } from "react-bootstrap-icons";

const AlertWarning = (props) => {
	const { children } = props;

	return (
		<div className="row">
			<div className="col">
				<div className="alert alert-primary">
					<div className="row">
						<div className="col-auto my-auto">
							<InfoCircleFill size={40} />
						</div>
						<div className="col my-auto"> {children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlertWarning;
