import React from "react";

import PageLayout from "siteLayouts/Page";
import Layout from "siteLayouts/SidePanelRight";
import PanelCallback from "siteLayouts/sidepanels/Callback";

import siteServices from "siteManagers/services";

// callback: {
// 	getDates: {},
// 	sendRequest: {},
// 	getProductTypeList: {},
// },
import { Section } from "siteComponents";

const SidePanelCallback = (
	<PanelCallback
		//defaultProduct={["PL", "EL"]}
		//defaultProduct="PL"
		headertext="Request a callback"
		fnGetDates={siteServices.callback.getDates}
		fnSendRequest={siteServices.callback.sendRequest}
		fnGetProductTypeList={siteServices.callback.getProductTypeList}
	/>
);

const TestView = (props) => {
	return (
		<PageLayout pageTitle="Test" headingText="TEST PAGE">
			<Section>
				<div className="col-12 py-3">
					<Layout
						breakpoint="lg"
						sidecontent={SidePanelCallback}
						leftClass="pe-lg-5"
					>
						<h3>Test Page</h3>
						<p>Test blurb</p>
					</Layout>
				</div>
			</Section>
		</PageLayout>
	);
};

export default TestView;
