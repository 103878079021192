import React from "react";
import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ListItemsFlex } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("about-us");

const AboutUsView = (props) => {
	return (
		<PageLayout
			pageTitle={assets.pageTitle}
			headingText={assets.headingText}
		>
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection>
						<div>
							Freedom Brokers was created in 2017 to offer our fantastic services
							directly to consumers. Our objectives are to make the insurance
							process as effortless as possible, giving you the time to get on with
							the more important things in life.
						</div>
						<div>
							Freedom represents independence – adopting new ideas and doing things
							differently from the rest of the insurance industry.
						</div>
						<div>
							We want to provide services to those overlooked by traditional
							insurers, giving a fair and high-quality service to a wide and
							inclusive customer base.
						</div>
						<div>
							We know what we're talking about, too. We're built on the foundations
							of Action 365, an expert in insurance services for almost two decades.
							Our CEO Sam White is a unique voice in the industry and a champion of
							new ideas and bold initiatives.
						</div>
						<div>
							We want to give you the freedom to relax, to grow and to focus on what
							matters.
						</div>
					</PanelSection>
					
					<PanelSection heading="Why choose Freedom Brokers?">
						<ListItemsFlex
							className=""
							bullet={<span className="bi bi-circle-fill"></span>}
							listData={
								[
									"Straight-forward, uncomplicated insurance with a first class claims service.",
									"Policies tailor-made for you with access to a range of insurers.",
									"Access to your personal online account to make changes to your policy and view your policy documents, 24 hours a day, 365 days a year.",
									"A fantastic range of optional cover, including roadside breakdown, for added peace of mind.",
									"24-hour claims helpline.",
								]
							}
						/>
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default AboutUsView;
