import PropTypes from "prop-types";
import React from "react";

const Help = (props) => {
	const { value, children, className = "", id } = props;

	if (!value && !children) return null;

	const _className = [
		"form-text",
		"text-teal",
		"help-text",
		className,
	].join(" ");

	return (
		<div id={id} className={_className}>
			{value || children}
		</div>
	);
};

export default Help;
Help.displayName = "Help";
Help.propTypes = {
	className: PropTypes.string,
	value: PropTypes.any,
};
