import React from "react";

import { Panel, PanelSection } from "siteComponentsLibrary/Layout";

// https://codepen.io/haniotis/pen/KwvYLO
const PaymentSuccess = (props) => {

	const { panelHeader } = props;
	
	return (
		<Panel header={panelHeader}>
			<PanelSection>
				<div className="d-flex justify-content-center mb-3">
					<svg
						className="checkmark"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 52 52"
					>
						<circle
							className="checkmark__circle"
							cx="26"
							cy="26"
							r="25"
							fill="none"
						/>
						<path
							className="checkmark__check"
							fill="none"
							d="M14.1 27.2l7.1 7.2 16.7-16.8"
						/>
					</svg>
				</div>
				<div className="d-flex justify-content-center">
					<h4 className="text-success">
						Thank you! Your payment has been successful.
					</h4>
				</div>
			</PanelSection>
		</Panel>
	);
};

export default PaymentSuccess;
