import React, { Fragment, Suspense } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";

import history from "siteHistory";

import ScrollToTop from "./ScrollToTop";
import LayoutSite from "siteLayouts/Site";
// import routeDataOracle from "./routeDataOracle";

// export it, for the siteMap etc.
export const routeData = require("./routeData").default;

const Routes = (props) => {

	const goToRoute = (route) => history.push(route);
	
	return (
		<Fragment>
			<ScrollToTop />
			<Switch>
				//** Main routes */
				{routeData
					.filter((x) => x.component && x.route)
					.map((x, i) => {
						const LazyComponent = React.lazy(<x.component goToRoute={goToRoute} />);
						return (
							<Route
								path={x.route}
								exact={"exact" in x ? x.exact : true}
								key={`main_${i}`}
								render={() => (
									<LayoutSite siteClassName={x.siteClassName} headerImages={x.headerImages} dataCy={x.pageDataCy}>
										<x.component goToRoute={goToRoute} />
										{/* <LazyComponent /> */}
									</LayoutSite>
								)}
							/>
						);
					})}
				//** oracleRoutes */
				{/* {routeDataOracle} */}
				//** default redirect */
				<Route>
					<Redirect to="/" />
				</Route>
			</Switch>
		</Fragment>
	);
};

/* <LayoutSite>
								<x.component />
							</LayoutSite> */

/*

						<Route
							path={x.route}
							exact={"exact" in x ? x.exact : true}
							key={`main_${i}`}
						>
							<LayoutSite>
								<x.component />
							</LayoutSite>
						</Route>
*/
Routes.propTypes = {
	history: PropTypes.any,
	name: PropTypes.string,
};
Routes.defaultProps = { name: "Routes" };
export default Routes;
