import React from "react";

const TickList = (props) => {
	const { listData, className = "" } = props;

	return (
		<div className={`list-style-ticks d-flex flex-column ps-1 ${className}`}>
			{listData.map((item, i) => {
				return (
					<div key={i} className="d-flex">
						<span className="icon icon-tick text-success pe-2 align-self-stretch"></span>
						<div className="d-inline">
							{item}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default TickList;