import React from "react";

import CookieConsentLibrary from "@library/common/components/base/misc/CookieConsent";

import { HyperlinkRoute } from "siteComponentsLibrary/Buttons";
import { CookieBannerFullWidth } from "siteComponentsLibrary/Layout";

const CookieConsent = () => {
	return (
		<CookieConsentLibrary
			expireTime={30}
			cookieId="CookieConsent"
			text={
				<span>
					Our website uses cookies to deliver you the best possible experience.
					By continuing to use this website you are agreeing to the use of
					cookies. For further details, please{" "}
					<HyperlinkRoute
						route="/cookie-policy"
						label={"review our cookie policy"}
						data-cy={"route-link:cookie-policy"}
						className=""
					/>
					.
				</span>
			}
			buttonText={<span>Okay</span>}
			buttonClassName="btn btn-primary"
			enabled={true}
			Container={CookieBannerFullWidth}
		/>
	);
};

export default CookieConsent;
