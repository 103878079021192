import React from "react";

import BaseCard from "./BaseCard";
import PolicyTierCard from "./PolicyTierCard";

const CoverLevelsCard = (props) => {

	const data = require("siteAssets/datafiles/coverLevelsData").default("ALL");

	return (
		<>
			<h2 className="text-purple h3">Our Cover Options</h2>
			<BaseCard
				cardClasses="px-md-3 border-0"
				addBottomMargin={false}
				hasShadow={false}
				centerTitle={false}
				cardContent={(
					<div className="row">
						{data.map((tier, i) => {
							return (
								<div key={tier.id} className="col-6 col-lg-3">
									<PolicyTierCard tierId={tier.id} tierData={tier} />
								</div>
							)
						})}
					</div>
				)}
			/>
		</>
	);

}

export default CoverLevelsCard;