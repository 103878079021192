import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { ListItemsFlex, PortalLinks } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("my-account");

const portalLogin = require("config").default.links.portal.login;
const portalRegister = require("config").default.links.portal.register;

const MyAccount = (props) => {
	return (
		<PageLayout
			pageTitle={assets.pageTitle}
			headingText={assets.headingText}
		>
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection>
						<div>
							We realise that time is precious, especially when you're trying to
							juggle work and family commitments, and that you'd like to be able
							to make changes to your insurance cover or access your policy documents
							at a time that’s convenient for you.
						</div>
						<div>
							That's why as a Freedom Brokers car or van customer, you will have access to your
							own personal online account giving you more freedom to spend time doing
							the things that you love.
						</div>
						<div>
							A great feature of your online account is the ability to upload
							documents that we have asked for as part of our validation process, such
							as proof of No Claims Document or a copy of your licence.
						</div>
					</PanelSection>

					<PanelSection heading="Key Benefits of your online account">
						<ListItemsFlex
							className=""
							bullet={<span className="bi bi-circle-fill"></span>}
							listData={
								[
									{ content: "Access your account 24 hours a day, 365 days a year" },
									{ content: "Make changes to your policy, such as a change of vehicle or address" },
									{ content: "Upload important information such as proof of your No Claims Discount or a copy of your driving licence" },
									{ content: "Access your insurance policy documentation" },
									{ content: "Useful information including frequently asked questions" },
									{ content: "Purchase optional cover" },
									{ content: "Contact information" },
								]
							}
						/>					
					</PanelSection>

					<PanelSection>
						<PortalLinks login={portalLogin} register={portalRegister} />
					</PanelSection>

					<PanelSection heading="Manage your Direct Debits">
						<div>
							If you have any questions about your Direct Debit, or would like to
							change the date of your instalments, then please{" "}
							<ExternalLink
								href="https://digital.premiumcredit.com/"
								label="contact Premium Credit"
							/>, as they are the administrators of your Direct Debit agreement.
						</div>
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default MyAccount;
