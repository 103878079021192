import React from "react";

import Layout from "siteLayouts/PageLayouts/OpexInfoPage";

const assets = require("siteAssets/pageManager").default("excess-protection");

const ExcessProtection = (props) => {
	return (
		<Layout assets={assets} />
	);
};

export default ExcessProtection;
