import React from "react";

import { BaseCard, ExternalLink } from "siteComponents";

const GapCoverCard = (props) => {
	const { gapData, gapId } = props;

	const data =
		gapData != undefined
			? gapData
			: require("siteAssets/datafiles/gapCoverData").default(gapId);

	const cardClass = "h-100";

	return (
		<BaseCard
			cardClasses={cardClass}
			hasShadow={true}
			headerContent={data.headerText}
			cardContent={
				<>
					<p>{data.description}</p>

					{data.ipidUrl && (
						<p>
							For further information, please refer to your{" "}
							<ExternalLink
								href={data.ipidUrl}
								label={<strong>Insurance Product Information Document</strong>}
								nowrap={false}
							/>
							.
						</p>
					)}

					{(data.ipidUrlCar || data.ipidUrlVan) && (
						<p>
							For further information, please see below:
							<br />
							{data.ipidUrlCar && (
								<ExternalLink
									href={data.ipidUrlCar}
									label={
										<strong>
											Insurance Product Information Document for Car
										</strong>
									}
									nowrap={false}
									className="d-block"
								/>
							)}
							{data.ipidUrlVan && (
								<ExternalLink
									href={data.ipidUrlVan}
									label={
										<strong>
											Insurance Product Information Document for Goods Carrying
											Vehicles
										</strong>
									}
									nowrap={false}
									className="d-block"
								/>
							)}
						</p>
					)}

					{data.policyWordingUrl && (
						<p>
							For full details on what is covered with this service, including
							the terms, conditions and exclusions, please see the{" "}
							<ExternalLink
								href={data.policyWordingUrl}
								label={<strong>Policy Wording</strong>}
								nowrap={false}
							/>
							.
						</p>
					)}
					{(data.policyWordingUrlCar || data.policyWordingUrlVan) && (
						<p>
							For full details on what is covered with this service, including
							the terms, conditions and exclusions, please see below:
							<br />
							{data.policyWordingUrlCar && (
								<ExternalLink
									href={data.policyWordingUrlCar}
									label={<strong>Policy Wording for Car</strong>}
									nowrap={false}
								/>
							)}
							{data.policyWordingUrlVan && (
								<ExternalLink
									href={data.policyWordingUrlVan}
									label={
										<strong>Policy Wording for Goods Carrying Vehicles</strong>
									}
									className="d-block"
									nowrap={false}
								/>
							)}
						</p>
					)}
				</>
			}
		/>
	);
};

export default GapCoverCard;
