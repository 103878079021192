import React from "react";

const Panel = (props) => {
	const {
		children,
		header,
		className,
		classNameBody,
		classNameHeader,
		hasBorder = true,
	} = props;

	if ("heading" in props)
		throw `Error in Panel -- found a prop "heading". Did you mean "header"?`;

	const _classOuter = [
		"lctr-panel",
		"card",
		"mb-4",
		hasBorder ? "panel-border border-2 border-purple" : undefined,
		className,
	]
		.filter((x) => x)
		.join(" ");

	const _classNameHeader = [
		"lctr-panel-header",
		"fw-bold",
		"fs-5",
		"mb-0",
		//"mx-0",
		"card-header",
		"bg-purple",
		"text-white",
		classNameHeader,
	]
		.filter((x) => x)
		.join(" ");

	const _classNameBody = [
		"lctr-panel-body",
		"card-body",
		// "p-3",
		// "m-0",
		// "p-2",
		classNameBody,
	]
		.filter((x) => x)
		.join(" ");

	return (
		<div className={_classOuter}>
			{header && <div className={_classNameHeader}>{header}</div>}
			<div className={_classNameBody}>{children}</div>
		</div>
	);
};

export default Panel;
