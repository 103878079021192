import React, { useState, useEffect, useCallback } from "react";
import config from "config";
import composeValidation from "@library/common/components/composers/validation";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";

import { Panel, PanelSection, FormGroup } from "siteComponentsLibrary/Layout";
import { HyperlinkRoute, Button } from "siteComponentsLibrary/Buttons";
import { ErrorWithIcon } from "siteComponentsLibrary/Feedback";

import {
	TextBox,
	DatePickerTextBox,
} from "siteComponents";


// ********************************************************************
// Error wrappers
// ********************************************************************

const FormGroupBase = (props) => {
	const { children, id, label, helpText, className, classNameContent, errMsg, isError } = props;
	
	return (
		<FormGroup
			className={className}
			classNameContent={classNameContent}
			labelFor={id}
			label={label}
			helpText={helpText}
			isError={isError}
			errorMessage={errMsg}
		>
			{children}
		</FormGroup>	
	);
}

const {
	ErrorContainerDateOfBirth,
	ErrorContainerPostcode,
	ErrorContainerWebReference,
	generateIsValid,
} = composeValidation({
	// generateErrMsgName: "generateErrMsg",
	generateIsValidName: "generateIsValid",
	configData: [
		{
			componentName: "ErrorContainerDateOfBirth",
			propIdValue: "value",
			// fnValidate: (v) => (v ? true : false),
			type: "GENERAL",
			errorClass: "has-error",
			propIdIsError: "isError",
			propIdErrMsg: "errMsg",
			Component: FormGroupBase,
			fnErrMsg: (v) => {
				if (!v) return undefined;
				return "Invalid date of birth. Please check and try again.";
			},
			errPriority: 3,
			errDataKey: "dateOfBirth",
			autoShowError: false,
		},
		{
			componentName: "ErrorContainerPostcode",
			propIdValue: "value",
			type: "POSTCODE",
			errorClass: "has-error",
			propIdIsError: "isError",
			propIdErrMsg: "errMsg",
			Component: FormGroupBase,
			fnErrMsg: (v) => {
				if (!v) return undefined;
				return "Invalid postcode. Please check and try again.";
			},
			errPriority: 2,
			errDataKey: "postcode",
			autoShowError: false,
		},
		{
			componentName: "ErrorContainerWebReference",
			propIdValue: "value",
			type: "WEBREFERENCE",
			errorClass: "has-error",
			propIdIsError: "isError",
			propIdErrMsg: "errMsg",
			Component: FormGroupBase,
			fnErrMsg: (v) => {
				if (!v) return undefined;
				return "Invalid  web reference. Please check and try again.";
			},
			errPriority: 1,
			errDataKey: "reference",
			autoShowError: false,
			fnWhitelist: (v) => {
				// #2366 -- return FALSE if NOT dev or staging
				if (!(config.isDev || config.isStage)) return false;

				if (!v) return false;
				if (v.includes("|")) return true;
			},
		},
	],
});

// ********************************************************************
// ITEMS
// ********************************************************************

const PaymentLandingForm = (props) => {
	const {
		panelHeader,
		
		onContinue: _onContinue,
		errorMessage,
		defaultWebReference,
		defaultPostcode,
		defaultDateOfBirth,
	} = props;

	const [reference, setReference] = useState(defaultWebReference);
	const [postcode, setPostcode] = useState(defaultPostcode);
	const [dateOfBirth, setDateOfBirth] = useState(defaultDateOfBirth);
	const [formErrorMessage, setFormErrorMessage] = useState();

	const [showError, setShowError] = useState({});

	const showAllFormErrors = formErrorMessage ? true : false;
	
	// console.log("Errors", { showError, showAllFormErrors, formErrorMessage });

	const fnShowError = (key) => {
		setShowError((x = {}) => ({ ...x, [key]: true }));
	};
	const fnShowErrorResetAll = () => setShowError({});

	const isFormValid = useCallback(
		generateIsValid({
			postcode,
			dateOfBirth,
			reference,
		}),
		[postcode, dateOfBirth, reference]
	);

	useEffect(() => {
		if (isFormValid) {
			setFormErrorMessage(undefined);
			fnShowErrorResetAll();
		}
	}, [isFormValid]);

	const onContinue = () => {
		if (!isFormValid) {
			setFormErrorMessage("Please complete the above required information");
			return;
		}
		setFormErrorMessage(undefined);
		fnShowErrorResetAll();

		const payload = {
			WebReference: reference,
			Postcode: postcode,
			DateOfBirth: dateOfBirth,
		};

		_onContinue(payload);
	};

	// console.log("DEBUG", isFormValid, reference, postcode, dateOfBirth);

	const baseId = "PAYMENT";

	return (
		<Panel 
			className="payment-form"
			header={panelHeader}
			footer={
				<div className="px-3 pb-3">
					{formErrorMessage && <ErrorWithIcon>{formErrorMessage}</ErrorWithIcon>}
				   	<div>
					   	<Button
						   	className="btn-primary btn-lg"
						   	onClick={onContinue}
					   	>
						   	Continue
					   	</Button>
				   	</div>
				   	{config.isDev && (
					   	<div className="mt-3">
						   	<Button
							   	className={["btn-outline-info"]}
							   	onClick={() => {
								   	setReference("89659-49816-85390");
								   	setPostcode("CF82 8AA");
								   	setDateOfBirth(new Date("31 JULY 1967"));
							   	}}
						   	>
							   	Dev fill in payment form
						   	</Button>
					   	</div>
				   	)}
				</div>
			}
		>
			<PanelSection>

				{errorMessage && <div className="mb-3"><ErrorWithIcon>{errorMessage}</ErrorWithIcon></div>}

				<div>
					You can use this service to make a payment for an outstanding balance
					on your account.
				</div>
				<div>
					If you have a car or van policy and you want to make any other changes
					to your policy, e.g. change address or change your car, then please
					log in to{" "}
					<HyperlinkRoute route="/my-account">your online account</HyperlinkRoute>.
				</div>

			</PanelSection>

			<PanelSection
				heading="Please enter your details below"
				relatesToFormGroup={true}
			>
			</PanelSection>

			<ErrorContainerWebReference
				showErrors={showAllFormErrors || showError["reference"]}
				classNameContent="col-md-5"
				value={reference}
				id={`${baseId}_REFERENCE`}
				label="Online reference number"
				helpText="Your reference number will be in one of these formats; 1234-123456-1234 or 12345-12345-12345."
			>
				<TextBox
					id={`${baseId}_REFERENCE`}
					value={reference}
					onChange={(v) => {
						// setShowErrorsReference(true);
						fnShowError("reference");
						if (!v) {
							setReference(v);
							return;
						}
						setReference(v.trim());
					}}
					maxLength={config.isDev || config.isStage ? "25" : "17"}
					autoComplete="off"
					onBlur={() => fnShowError("reference")}
				/>
			</ErrorContainerWebReference>

			<ErrorContainerPostcode
				showErrors={showAllFormErrors || showError["postcode"]}
				classNameContent="col-md-5"
				value={postcode}
				id={`${baseId}_POSTCODE`}
				label="Postcode"
			>
				<TextBox
					id={`${baseId}_POSTCODE`}
					value={postcode}
					onChange={(v) => {
						// setShowErrorsPostcode(true);
						fnShowError("postcode");

						if (!v) {
							setPostcode(v);
							return;
						}
						setPostcode(tidyPostcode(v.toUpperCase()));
					}}
					maxLength="100"
					autoComplete="off"
					onBlur={() => fnShowError("postcode")}
				/>
			</ErrorContainerPostcode>

			<ErrorContainerDateOfBirth
				showErrors={showAllFormErrors || showError["dateofbirth"]}
				classNameContent="col-md-9"
				value={dateOfBirth}
				id={`${baseId}_DATEOFBIRTH`}
				label="Date of birth"
			>
				<DatePickerTextBox
					id={`${baseId}_DATEOFBIRTH`}
					value={dateOfBirth}
					onChange={(v) => {
						// setShowErrorsDateOfBirth(true);
						fnShowError("dateofbirth");
						setDateOfBirth(v);
					}}
					// onBlur={() => fnShowError("dateofbirth")}
				/>
			</ErrorContainerDateOfBirth>

		</Panel>
	);
};

export default PaymentLandingForm;
