Object.defineProperty(Array.prototype, "toClassName", {
  value: function () {
    const newList = this.filter(Boolean);
    if (newList.length === 0) return undefined;
    return newList.join(" ");
  },
});

Object.defineProperty(Array.prototype, "argChecker", {
  value: function (args, description) {
    if (!args) throw `error in argChecker() -- missing argument data.`;

    this.forEach((k) => {
      if (!(k in args)) {
        console.log("argChecker() args: ", args);
        console.log("argChecker() description: ", description);
        throw [description, "error -- missing", k].filter(Boolean).join(" ");
      }
    });

    return this;
  },
});
