import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";

import ClaimTableRow from "./ClaimTableRow";

const assets = require("siteAssets/pageManager").default("make-a-claim");
const opexData = require("siteAssets/datafiles/opexData").default("ALL");

const MakeAClaim = (props) => {
		
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			
			<ContainerMaxWidth>
				
				<Panel header={assets.panelHeader}>
					<PanelSection>
						<div>
							If there's been an incident and you need to make a claim, please
							contact us using the information below.
						</div>
						<div>
							To help us to get you the assistance you need, please be sure to
							choose the correct details for your policy.
						</div>
					</PanelSection>

					<div className="row">
						<div className="col-12 col-lg-6">
							<PanelSection
								heading="Our Insurance Products"
							>
								<dl className="row g-3">
									<ClaimTableRow
										productType="Car Insurance"
										contactNumber={
											require("config").default.claimsContacts.motor.number
										}
										moreInfoRoute="/car-insurance"
									/>

									<ClaimTableRow
										productType="Van & Courier Insurance"
										contactNumber={
											require("config").default.claimsContacts.motor.number
										}
										moreInfoRoute="/van-insurance"
									/>

									<ClaimTableRow
										productType="Telematics Insurance"
										contactNumber={
											require("config").default.claimsContacts.motor.number
										}
										moreInfoRoute="/telematics"
									/>

									<ClaimTableRow
										productType="Home Insurance"
										contactUrl={require("config").default.claimsContacts.home.url}
										contactUrlText="Visit the Homelyfe website"
										// moreInfoRoute="/home-insurance"
									/>

									<ClaimTableRow
										productType="Public Liability Insurance"
										contactNumber={require("config").default.claimsContacts.pl.number}
										moreInfoRoute="/public-liability-employers-liability-insurance"
										email={require("config").default.claimsContacts.pl.email}
									/>

									<ClaimTableRow
										productType="Employers Liability Insurance"
										contactNumber={require("config").default.claimsContacts.pl.number}
										moreInfoRoute="/public-liability-employers-liability-insurance"
										email={require("config").default.claimsContacts.pl.email}
									/>

									{/*
						<ClaimTableRow
							productType="Tradesman Insurance"
							contactNumber={require("config").default.claimsContacts.pl.number}
							moreInfoRoute="/tradesman-insurance"
							email={require("config").default.claimsContacts.pl.email}
						/>
						*/}

									<ClaimTableRow
										productType="GAP Insurance"
										contactNumber={require("config").default.claimsContacts.gap.number}
										previousClaimNumbers={{
											endDate: require("config").default.claimsContacts.gap.historic.date,
											claimNumbers: [require("config").default.claimsContacts.gap.historic.number],
										}}
										moreInfoRoute="/gap-insurance"
										email={require("config").default.claimsContacts.gap.email}
									/>

									<ClaimTableRow
										productType="Pet Insurance"
										contactUrl={require("config").default.claimsContacts.pet.url}
										contactUrlText="Visit the Healthy Pets website"
										moreInfoRoute="/pet-insurance"
									/>
								</dl>
							</PanelSection>
						</div>

						<div className="col-12 col-lg-6">
							<PanelSection
								heading="Optional Cover"
							>
								<dl className="row g-3">
									{opexData.map((opex, i) => {
										return (
											<ClaimTableRow
												key={opex.groupCode}
												productType={opex.heading}
												contactNumber={opex.claimNumbers.map((num, i) => {
													return (
														<span key={i} className="d-block">
												{num}
											</span>
													);
												})}
												contactUrl={opex.claimUrl}
												contactUrlText="Visit the claim portal"
												previousClaimNumbers={opex.previousClaimNumbers}
												moreInfoRoute={opex.route}
												moreInfoText={
													opex.claimDetails
														? "More info and policy documents"
														: "View policy documents"
												}
											/>
										);
									})}
								</dl>
							</PanelSection>
						</div>
					</div>
					
				</Panel>

			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default MakeAClaim;
