import React from "react";
import { PulseLoader } from "react-spinners";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

// https://www.npmjs.com/package/react-spinners
const AnimatedPulse = (props) => {
	const {
		colour = "#000",
		speedMultiplier = "0.6",
		size = "10px",
		margin = "6px",
		cssOverride = {},
	} = props;

	return (
		<PulseLoader
			className="animated-pulse"
			color={colour}
			speedMultiplier={speedMultiplier}
			size={size}
			margin={margin}
			cssOverride={cssOverride}
			{...generateCypressProps("animated-pulse", props)}
		/>
	);
};

export default AnimatedPulse;
