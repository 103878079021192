import React, { useState, useEffect, useCallback } from "react";
import { useLocation, matchPath } from "react-router-dom";

import siteServices from "siteManagers/services";
import LayoutSite from "siteLayouts/Site";
import PageLayout from "siteLayouts/Page";

import Component_Maintenance from "siteComponents/errors/Maintenance";

const maintenanceBlacklist = [
	// use the exact route, so that we can allow the "thankyou" page
	{ route: "/payments", exact: true },
];

const StatusChecker = (props) => {
	const { children } = props;
	const [isChecking, setIsChecking] = useState(true);
	const [isMaintenance, setIsMaintenance] = useState(false);

	const { pathname } = useLocation();

	useEffect(() => {
		siteServices
			.status()
			.then((status = {}) => {
				const { Maintenance = false } = status;
				// const Maintenance = true;
				setIsMaintenance(Maintenance);
				setIsChecking(false);
			})
			.catch(() => {
				setIsChecking(false);
			});
	}, []);

	const ismaintenanceBlacklist = useCallback(
		maintenanceBlacklist.some((x) =>
			matchPath(pathname, {
				path: x.route,
				strict: "strict" in x ? x.strict : false,
				exact: "exact" in x ? x.exact : false,
			})
		),
		[pathname]
	);

	if (isChecking) return null;

	if (isMaintenance && ismaintenanceBlacklist)
		return (
			<LayoutSite>
				<div className="mt-5">
					<Component_Maintenance />
				</div>
			</LayoutSite>
		);

	return <>{children}</>;
};

export default StatusChecker;
