import React from "react";
import { PulseLoader } from "react-spinners";

// https://www.npmjs.com/package/react-spinners
const AnimatedPulse = (props) => {
	const {
		colour = "#000000",
		speedMultiplier = "0.6",
		size = "10px",
		margin = "6px",
	} = props;

	return (
		<PulseLoader
			color={colour}
			speedMultiplier={speedMultiplier}
			size={size}
			margin={margin}
		/>
	);
};

export default AnimatedPulse;
