import React from "react";

import { Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";

const StepChange = (props) => {
	return (
		<Panel header="Can we help?">
			<PanelSection>
				<div>
					If you have any questions regarding your account please contact us
					using our live chat.
				</div>
				<div>
					If you're worried about making a payment, help is available.{" "}
					<ExternalLink href="https://www.stepchange.org/" label="StepChange" />{" "}
					provides free debt advice to help you deal with your debt and set up a solution.
				</div>
				<div className="col-8 col-sm-6 col-md-4 col-lg-3 col-xl-8">
					<ExternalLink 
						href="https://www.stepchange.org/"
						label={
							<img
								src={require("siteAssets/images/stepchange/stepchange_logo.png")}
								alt="Step Change logo"
								className="img-fluid"
							/>
						}
					/>
				</div>
			</PanelSection>
		</Panel>
	);
};

export default StepChange;
