const purposesData = [
	{
		purpose:
			"In order to perform our contractual obligations to you. This would include our fulfilling your requests for insurance services(including obtaining insurance for you, fulfilling requests for mid - term adjustments and obtaining renewals)",
		basis:
			"The processing is necessary in connection with any contract that you may enter into with us",
	},
	{
		purpose:
			"To administer your account, including financial transactions for insurance broking",
		basis:
			"The processing is necessary in connection with any contract that you may enter into with us",
	},
	{
		purpose:
			"To assist in the prevention and reduction of fraud and other financial crime",
		basis:
			"The processing is necessary in connection with any contract that you may enter into with us",
	},
	{
		purpose:
			"In the interests of security and to improve our service, telephone calls you make to us may be monitored and/or recorded",
		basis:
			"The processing is necessary in connection with any contract that you may enter into with us",
	},
	{
		purpose:
			"To let you know about similar products and services that may be of interest to you",
		basis:
			"The processing is necessary in connection with any contract that you may enter into with us",
	},
	{
		purpose:
			"To make automated decisions, automated profiling and fraud detection",
		basis:
			"The processing is necessary in connection with any contract that you may enter into with us",
	},
];

export default purposesData;
