import React from "react";

const Section = (props) => {

    const { children, outerRowClass = "", innerRowClass = "" } = props;

    return (
        <div className={`row justify-content-center ${outerRowClass}`}>

            <div className="col-12 px-md-4 col-xl-11 px-xl-3 col-xxl-11">

                <div className={`row justify-content-center ${innerRowClass}`}>

                    {children}

                </div>

            </div>

        </div>
    );
};

export default Section;