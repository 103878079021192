import React from "react";
import { ExclamationTriangle } from "react-bootstrap-icons";

export const AlertErrorSmall = (props) => {
	const { children, className } = props;

	const _className = [className, "row"].filter((x) => x).join(" ");
	return (
		<div className={_className}>
			<div className="col text-danger fs-7">{children}</div>
		</div>
	);
};

const AlertError = (props) => {
	const { children } = props;

	return (
		<div className="row">
			<div className="col">
				<div className="alert alert-danger">
					<div className="row">
						<div className="col-auto my-auto">
							<ExclamationTriangle size={40} />
						</div>
						<div className="col my-auto"> {children}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AlertError;
