const globalDebug = false;

const requiredArgs = ["isDev", "google", "callbackData"];

export default (args) => {
	requiredArgs.forEach((x) => {
		if (!x in args) {
			console.log("args:", args);
			throw `Error in base config -- missing ${x}`;
		}
	});

	console.log("config args:", args);

	const isDev = args.isDev || false;
	const isTest = args.isTest || false;
	const isStage = args.isStage || false;
	const isProd = args.isProd || false;
	
	const showFaqs = args.showFaqs || false;

	const liveDisable = (preferedValue) => (!isDev ? false : preferedValue);

	return {
		endPoints: require("./endpoints").default({
			AWSConfig: args.AWSConfig,
			AxiosData: args.AxiosData,
			contactusData: args.contactusData,
			endPointsPayment: args.endPointsPayment,
		}),
		isDev,
		isTest,
		isStage,
		isProd,
		showFaqs,
		google: args.google,
		liveChat: args.liveChat,
		callbackData: args.callbackData,
		contactusData: args.contactusData,
		links: {
			portal: {
				login: "https://portal.freedombrokers.co.uk/freedom/portalrecall.launch?qsid=portal&brandName=default&PolicyType=GV",
				register: "https://portal.freedombrokers.co.uk/freedom/registerportaluser.launch?qsid=portal&brandName=default&PolicyType=GV",
			},
			
			quoteStart: {
				car: "https://quote.freedombrokers.co.uk/car/quote?refid=BRAND",
				van: "https://quote.freedombrokers.co.uk/van/quote?refid=BRAND",
				telematics: "https://quote.freedombrokers.co.uk/car/quote?refid=BRAND&qp=TELE",
				gap: "https://gap.freedombrokers.co.uk?refid=BRAND",
			},
			
			docs: {
				baseUrl: "https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/",
				replacedUrl: "https://amplify-qb-euw1.s3.eu-west-1.amazonaws.com/Documents/Replaced/",
				
				toba: {
					motor: "TOBA-Car-and-Van.pdf",
					telematics: "TOBA-Telematics.pdf",
					gap: "TOBA-GAP.pdf",
					commercial: "TOBA-Commercial.pdf",
					liability: "TOBA-Bespoke.pdf",
				},
				policyWording: {
					commercial: "PW-Public-and-Employers-Liability.pdf"
				}
			}
		},
		phoneNumbers: {
			complaints: "0161 884 3089",
			commercial: "0161 808 9898",
		},
		emailAddresses: {
			customerService: "customerservices@freedombrokers.co.uk",
			complaints: "complaints@freedombrokers.co.uk"
		},
		claimsContacts: {
			motor: {
				number: "0345 5280 197",
			},
			home: {
				url: "https://support.homelyfe.com/hc/en-gb/articles/115004313794",
			},
			pl: {
				number: "0300 030 1865",
				email: "claims@sagic.co.uk",
			},
			gap: {
				number: "0345 528 0255",
				email: "gapclaims@businessanddomestic.co.uk",
				historic: {
					date: "11-11-2022",
					number: "0330 123 0309",
				},
			},
			pet: {
				url: "https://www.healthy-pets.co.uk/existing-clients/make-a-claim/",
			},
		},
		trustpilot: {
			businessUnitId: "5c9e9a640668fb00011f055a",
			fallbackLink: "https://uk.trustpilot.com/review/freedombrokers.co.uk",
			templates: {
				carousel: "53aa8912dec7e10d38f59f36",
				horizontal: "5406e65db0d04a09e042d5fc",
			}
		},
		fos: {
			website: "http://www.financial-ombudsman.org",
			phoneNumbers: [ "0800 023 4567", "0300 123 9123" ],
			openingHours: "between 8.00am to 8.00pm",
			email: "complaint.info@financial-ombudsman.org.uk",
			address: [
				"The Financial Ombudsman Service",
				"Exchange Tower",
				"London",
				"E14 9SR"
			]
		},
		debug: {
			clearConsoleOnRefresh: false,
		},
	};
};
