import _ from "lodash";
import axios from "axios";
import config from "config";
import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";

const callbackApiKey = config.callbackData.apiKey;
const callbackUrl = config.callbackData.endPoint;

const contactusApiKey = config.contactusData.apiKey;
const contactusUrl = config.contactusData.endPoint;

// if (!awsApiKey || !awsUrl)
// 	throw `Error in site services config -- missing AWS config information`;

/*
https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/185/Callback-API


Available methods:
	
GetProductTypeList: GET
GetAvailableDates: GET	
SendCallbackRequest: POST
*/

import { createServiceObj } from "@library/common/helpers/service";

const { endPoints } = config;

const createAwsFunction = (options = {}) => {
	return (_postData = {}) => {
		let additionalData = {};
		const postData = {
			...(options.basePostData ? options.basePostData : {}),
			..._postData,
		};
		switch (options.method) {
			case "POST": {
				additionalData = { data: postData };
				break;
			}
			case "GET": {
				additionalData = { params: postData, data: {} };
				break;
			}
		}
		const axiosPostData = {
			timeout: 30000,
			method: options.method,
			headers: {
				"x-api-key": options.apiKey,
			},
			url: options.url,
			...additionalData,
		};
		console.log("dddd", axiosPostData);
		return axios(axiosPostData).then((response) => response.data);
	};
};

const baseServices = createServiceObj(endPoints);

const siteServices = {
	...baseServices,

	callback: {
		getProductTypeList: createAwsFunction({
			method: "GET",
			url: `${callbackUrl}/GetProductTypeList`,
			apiKey: callbackApiKey,
		}),
		// RequestDate: "2021-07-09T11:22:00"
		getDates: createAwsFunction({
			method: "GET",
			url: `${callbackUrl}/GetAvailableDates`,
			apiKey: callbackApiKey,
		}),
		sendRequest: createAwsFunction({
			method: "POST",
			url: `${callbackUrl}/SendCallbackRequest`,
			apiKey: callbackApiKey,
		}),
	},
};

export default siteServices;
