import React from "react";
import history from "siteHistory";

import { HyperlinkGoto, TickList, ExternalLink } from "siteComponents";
import BaseCard from "./BaseCard";

const TitleContent = (props) => {
	const { data, isLandingPage = true } = props;

	if (isLandingPage) {
		return (
			<HyperlinkGoto
				className="card-link text-decoration-none"
				route={data.moreInfoRoute}
			>
				<span className="d-block rounded-icon">
					{data.icon && <span className={`icon icon-${data.icon}`}></span>}

					{!data.icon && (
						<span className={`icon icon-policytype-${data.policyType}`}></span>
					)}
				</span>
				<span className="text-grey">{data.heading}</span>
			</HyperlinkGoto>
		);
	}

	return data.pageHeading !== undefined ? data.pageHeading : null;
};

const LeadText = (props) => {
	const { data, isLandingPage } = props;

	if (!data.leadText) {
		return null;
	}

	if (data.policyType === "tele" && isLandingPage) {
		return null;
	}

	return <p className="pb-3">{data.leadText}</p>;
};

const FindOutMoreButton = (props) => {
	const { policyType, data, showMoreInfo } = props;

	if (!showMoreInfo) {
		return null;
	}

	if (!data.moreInfoRoute) {
		return null;
	}

	if (data.MoreInfoComponent)
		return (
			<div className="d-block mb-3">
				<data.MoreInfoComponent />
			</div>
		);

	return (
		<div className="d-block mb-3">
			<HyperlinkGoto className="card-link" route={data.moreInfoRoute}>
				Find out more
			</HyperlinkGoto>
		</div>
	);
};

const QuoteButton = (props) => {
	const { policyType, data } = props;

	const quoteBtnClass = "btn btn-quote";

	switch (policyType) {
		case "TELE": {
			if (!data.policyTypes) {
				return null;
			}

			return data.policyTypes.map((policyType, i) => {
				const ptData = require("siteAssets/datafiles/policyTypesData").default(
					policyType.lookup
				);

				if (ptData.quoteUrl) {
					const quoteUrl =
						ptData.quoteUrl +
						(ptData.quoteUrl.includes("?") ? "&" : "?") +
						"qp=TELE";

					return (
						<ExternalLink
							key={i}
							href={quoteUrl}
							label={policyType.quoteText}
							className={quoteBtnClass}
						/>
					);
				}
			});
		}

		case "HC": {
			if (!data.quoteUrl) {
				return null;
			}

			return (
				<HyperlinkGoto
					key={`pt-${policyType}-quote`}
					className={quoteBtnClass}
					route={data.quoteUrl}
				>
					{data.quoteText}
				</HyperlinkGoto>
			);
		}

		// case "GAP":
		case "PL":
		case "TRADE": {
			if (!data.quoteUrl) {
				return null;
			}

			return null;
			// The "quote urls" for these are actually the phone numbers, with a "tel:###" href

			return (
				<>
					<a href={data.quoteUrl} className="btn btn-outline-quote">
						{data.quoteText}
					</a>
				</>
			);

			break;
		}

		default: {
			if (!data.quoteUrl) {
				return null;
			}

			return (
				<ExternalLink
					href={data.quoteUrl}
					label={data.quoteText}
					className={quoteBtnClass}
				/>
			);
		}
	}
};

const GetQuoteButtonArray = (props) => {
	const { policyType, data, showRecall = true, showMoreInfo = false } = props;

	if (policyType === "TELE") {
		return [
			<FindOutMoreButton
				key={`pt-${policyType}-moreinfo`}
				policyType={policyType}
				data={data}
				showMoreInfo={showMoreInfo}
			/>,
			<QuoteButton
				key={`pt-${policyType}-quote`}
				policyType={policyType}
				data={data}
			/>,
			showRecall && data.recallUrl && (
				<ExternalLink
					key="telematics-recall"
					href={data.recallUrl}
					label={data.recallText}
					className={`btn btn-recall ${
						data.recallClass != undefined ? data.recallClass : ""
					}`}
				/>
			),
		];
	}

	if (policyType === "HC") {
		return [
			<FindOutMoreButton
				key={`pt-${policyType}-moreinfo`}
				policyType={policyType}
				data={data}
				showMoreInfo={showMoreInfo}
			/>,
			<QuoteButton
				key={`pt-${policyType}-quote`}
				policyType={policyType}
				data={data}
			/>,
		];
	}

	return [
		<FindOutMoreButton
			key={`pt-${policyType}-moreinfo`}
			policyType={policyType}
			data={data}
			showMoreInfo={showMoreInfo}
		/>,
		<QuoteButton
			key={`pt-${policyType}-quote`}
			policyType={policyType}
			data={data}
		/>,
		showRecall && data.recallUrl && (
			<ExternalLink
				key={`pt-${policyType}-recall`}
				href={data.recallUrl}
				label={data.recallText}
				className={`btn btn-recall ${
					data.recallClass != undefined ? data.recallClass : ""
				}`}
			/>
		),
	];
};

const QuoteButtons = (props) => {
	const { policyType, data, showRecall = true } = props;

	const quoteButtonArray = (
		<GetQuoteButtonArray
			policyType={policyType}
			data={data}
			showRecall={showRecall}
		/>
	);

	return <div className="text-center quote-buttons">{quoteButtonArray}</div>;
};

const FooterContent = (props) => {
	const {
		policyType,
		data,
		showRecall = true,
		isLandingPage = true,
		showMoreInfo = true,
	} = props;

	//if (isLandingPage) {
	//	return null;
	//	//return [
	//	//	showMoreInfo && data.moreInfoRoute && (
	//	//		<HyperlinkGoto key={`pt-${policyType}-moreinfo`} className="card-link" route={data.moreInfoRoute}>
	//	//			Find out more
	//	//		</HyperlinkGoto>
	//	//	)
	//	//];
	//}

	const quoteButtonArray = (
		<GetQuoteButtonArray
			policyType={policyType}
			data={data}
			showRecall={showRecall}
			showMoreInfo={isLandingPage}
		/>
	);

	return quoteButtonArray;
};

const PolicyTypeCard = (props) => {
	const {
		policyType,
		cardClass = "",
		isLandingPage = true,
		showRecall = true,
		showMoreInfo = true,
	} = props;

	const data = require("siteAssets/datafiles/policyTypesData").default(
		policyType
	);
	const cardClasses = `${cardClass} policy-type-card policy-type-${data.policyType}`;

	let baseProps = {};

	if (data.landingFragments && data.landingFragments.header) {
		baseProps.fragmentHeader = data.landingFragments.header;
	} else {
		baseProps.headerContent = (
			<>
				<TitleContent data={data} isLandingPage={isLandingPage} />
			</>
		);
	}

	if (data.landingFragments && data.landingFragments.card) {
		baseProps.fragmentBody = data.landingFragments.card;
	} else {
		baseProps.cardContent = (
			<>
				{data.leadText && (
					<LeadText data={data} isLandingPage={isLandingPage} />
				)}
				{isLandingPage && data.landingContent && (
					<div className="text-center">{data.landingContent}</div>
				)}
				{!isLandingPage && data.bullets && (
					<TickList className="pt-2 mb-0 ps-3 gap-2" listData={data.bullets} />
				)}
			</>
		);
	}

	if (data.landingFragments && data.landingFragments.footer) {
		baseProps.fragmentFooter = data.landingFragments.footer;
	} else {
		baseProps.footerContent = (
			<FooterContent
				policyType={policyType}
				data={data}
				showRecall={showRecall}
				showMoreInfo={showMoreInfo}
				isLandingPage={isLandingPage}
			/>
		);
	}

	return (
		<BaseCard
			cardClasses={cardClasses}
			hasShadow={true}
			centerTitle={true}
			//cardBodyTwo={isLandingPage && (
			//	<QuoteButtons policyType={policyType} data={data} showRecall={showRecall} />
			//)}
			//footerOnClick={isLandingPage ? () => history.push(data.moreInfoRoute) : null}
			footerClasses="pb-3"
			{...baseProps}
		/>
	);
};

export default PolicyTypeCard;
