import React from "react";
import config from "config";

import _logo from "siteAssets/images/FB-logo-footer.png";

import { SiteFooter, SiteFooterLogoLeft } from "siteComponentsLibrary/Layout";

const TemplateSiteFooter = (props) => {
	const { goToRoute, logoFooter = _logo } = props;

	return (
		<>
			<SiteFooterLogoLeft
				brandImage={
					<img className="brand-img" src={logoFooter} alt="Freedom Brokers" />
				}
				copyright={
					<small>
						<strong>
							&copy; {process.env.BUILD_TIME.split(",")[0].split("/")[2]}{" "}
							Freedom Brokers Ltd.
						</strong>
					</small>
				}
				legalFooterText={
					<>
						<div className="small">
							Freedom Brokers Ltd is an appointed representative of Action 365
							Ltd which is authorised and regulated by the Financial Conduct
							Authority (FRN 306011).
						</div>
						<div className="small">
							Freedom Brokers Ltd is registered in England and Wales, company
							number 10399389 and its registered address is
						</div>
						<div className="small">
							BizSpace Cheadle, Cheadle Place, Stockport Road, Cheadle, Cheshire
							SK8 2JX.
						</div>
					</>
				}
				// Links will be output in the footer in the order passed in, so they
				// will need to be alternated in this list to appear in the expected column.
				menuData={[
					{ id: "contact", route: "/contact-us", label: "Contact Us" },
					{ id: "privacy", route: "/privacy-policy", label: "Privacy Policy" },
					{
						id: "support",
						route: "/customer-support",
						label: "Customer Support",
					},
					{ id: "cookie", route: "/cookie-policy", label: "Cookie Policy" },
					{
						id: "toba",
						route: "/terms-of-business",
						label: "Terms of Business",
					},
					{ id: "terms", route: "/terms-of-use", label: "Terms of Use" },
					{
						id: "complaint",
						route: "/make-a-complaint",
						label: "Make a Complaint",
					},
					{ id: "about", route: "/about-us", label: "About Us" },
					config.showFaqs ? { id: "faqs", route: "/faqs", label: "FAQs" } : {},
				]}
				goToRoute={goToRoute}
			/>
			{/*
		<SiteFooter
			brandImage={
				<img
					className="brand-img"
					src={logoFooter}
					alt="Freedom Brokers"
				/>
			}
			copyright={<small>&copy; Freedom Brokers 2024.</small>}
			legalFooterText={
				<>
					<small>
						Freedom Brokers Ltd is an appointed representative of Action 365
						Ltd which is authorised and regulated by the Financial Conduct
						Authority (FRN 306011). Freedom Brokers Ltd is registered in
						England and Wales, company number 10399389 and its registered
						address is BizSpace Cheadle, Cheadle Place, Stockport Road,
						Cheadle, Cheshire SK8 2JX.
					</small>
				</>
			}
			menuData={
				[
					{ id: 'contact', route: "/contact-us", label: "Contact Us"},
					{ id: 'support', route: "/customer-support", label: "Customer Support"},
					{ id: 'toba', route: "/terms-of-business", label: "Terms of Business"},
					{ id: 'privacy', route: "/privacy-policy", label: "Privacy Policy"},
					{ id: 'cookie', route: "/cookie-policy", label: "Cookie Policy"},
					{ id: 'terms', route: "/terms-of-use", label: "Terms of Use"},
					{ id: 'about', route: "/about-us", label: "About Us"},
					{ id: 'complaint', route: "/make-a-complaint", label: "Make a Complaint"},
					{ id: 'faqs', route: "/faqs", label: "FAQs"},
				]
			}
			goToRoute={goToRoute}
			leftClassName="col-xxl-3"
			rightClassName="col-xxl-9"
		/>*/}
		</>
	);
};

export default TemplateSiteFooter;
