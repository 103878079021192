import React from "react";

const PolicyTierCard = (props) => {
	const { tierId, tierData } = props;

	const data = tierData != undefined ? tierData : require("siteAssets/datafiles/coverLevelsData").default(tierId);

	return (
		<div key={tierId} className="card policy-tier-card text-center px-2 px-lg-3">
			<div className="card-body pb-0 px-0">
				<img src={data.image} className="img-fluid" alt={data.imageAlt} />				
			</div>
			<div className="card-body px-0">
				<small className="d-md-none">{data.shortDescription}</small>
				<small className="d-none d-md-block">{data.description}</small>
			</div>
		</div>
	);
};

export default PolicyTierCard;