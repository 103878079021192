import React from "react";
import { Oval } from "react-loading-icons";
import { generateCypressProps } from "componentsLibraryResources/functions/cypress";

const AnimatedOval = (props) => {
	const { colour = "#7975be", strokeWidth = 5, width, height } = props;

	return (
		<Oval
			stroke={colour}
			strokeWidth={strokeWidth}
			width={width}
			height={height}
			{...generateCypressProps("animated-oval", props)}
		/>
	);
};

export default AnimatedOval;
