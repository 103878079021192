import React from "react";

import Layout from "siteLayouts/PageLayouts/OpexInfoPage";

const assets = require("siteAssets/pageManager").default("key-cover");

const KeyCover = (props) => {
	return (
		<Layout assets={assets} />
	);
};

export default KeyCover;
