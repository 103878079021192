import React from "react";

import "siteAssets/css/mainStyle";

const ScreenFinder = (props) => {
    
    const { showFinder, setShowScreenFinder } = props;

    if (!showFinder)
        return null;
    
    return (
        <div
            className="fixed-bottom bg-white p-2 border border-2 border-primary text-center"
            style={{width: "50px", cursor: "pointer"}}
            onClick={() => setShowScreenFinder(false)}
            data-cy="screenfinder"
        >
            <strong>
                <span className="debug-resolution-finder">
                    <span className="d-inline-block d-sm-none">XS</span>
                    <span className="d-none d-sm-inline-block d-md-none">SM</span>
                    <span className="d-none d-md-inline-block d-lg-none">MD</span>
                    <span className="d-none d-lg-inline-block d-xl-none">LG</span>
                    <span className="d-none d-xl-inline-block d-xxl-none">XL</span>
                    <span className="d-none d-xxl-inline-block">XXL</span>
                </span>
            </strong>
        </div>

    );
}

export default ScreenFinder;