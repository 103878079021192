import React from "react";

import { DocsBaseUrl, DocsPreviousBaseUrl } from "siteComponents";

const gapCoverData = {
	["return-to-invoice"]: {
		id: "gaprti",
		headerText: "Return To Invoice",
		description:
			"If you have purchased a vehicle from a dealership within the last 180 days, this policy pays the difference, up to the policy limit, between the motor insurers total loss pay out and either the invoice price or the amount outstanding on finance, whichever is the highest.",
	},
	["contract-hire-lease"]: {
		id: "gapchl",
		headerText: "Contract Hire / Lease",
		description:
			"If you have a vehicle on contract hire or lease, this protection that again ensures if your vehicle is written off, you have no financial impact or liability. It will pay the difference between your own insurance pay out and the amount left owing on the contract hire/ lease agreement.",
	},
	["valuation-agreed-value"]: {
		id: "gapvav",
		headerText: "Return to Value",
		description:
			"If you have purchased your vehicle privately or over 180 days ago, this insurance product will pay the difference between your insurance pay out and the current value of your vehicle at the time your GAP Insurance policy is purchased.",
	},

	// These are required for pre-FIGL GAP policies
	["old-return-to-invoice"]: {
		id: "gaprti",
		headerText: "Return To Invoice",
		description:
			"If you have purchased a vehicle from a dealership within the last 180 days, this policy pays the difference up to the policy limit between the motor insurers total loss settlement and either the invoice price or the amount outstanding on finance whichever is the highest.",
		ipidUrl: DocsPreviousBaseUrl + "IPID-GAP-RTI-Financial-Shortfall.pdf",
		policyWordingUrl:
			DocsPreviousBaseUrl + "PW-GAP-RTI-Financial-Shortfall.pdf",
	},
	["old-contract-hire-lease"]: {
		id: "gapchl",
		headerText: "Contract Hire / Lease",
		description:
			"This product works in the same way as if you were to own your car on finance.It is a form of protection that again ensures if your vehicle is written off, you have no financial impact or liability.It will pay the difference between your own insurance pay out and the amount left owing on the contract hire/ lease agreement. ",
		ipidUrl: DocsPreviousBaseUrl + "IPID-GAP-Contract-Hire-Lease.pdf",
		policyWordingUrl: DocsPreviousBaseUrl + "PW-GAP-Contract-Hire-Lease.pdf",
	},
	["old-valuation-agreed-value"]: {
		id: "gapvav",
		headerText: "Valuation / Agreed Value",
		description:
			"If you have purchased your vehicle privately or over 180 days ago, this insurance product will pay the difference between your insurance pay out and the Glass's Guide retail value of your vehicle at the time your GAP insurance policy is purchased.",
		ipidUrl: DocsPreviousBaseUrl + "IPID-GAP-Valuation.pdf",
		policyWordingUrl: DocsPreviousBaseUrl + "PW-GAP-Valuation.pdf",
	},
	["old-total-loss-protect-car"]: {
		id: "gaptlpc",
		headerText: "Total Loss Protect",
		subHeader: "Car",
		ipidUrl: DocsPreviousBaseUrl + "IPID-GAP-Total-Loss-Protect-Car.pdf",
		policyWordingUrl: DocsPreviousBaseUrl + "PW-GAP-Total-Loss-Protect-Car.pdf",
	},
	["old-total-loss-protect-van"]: {
		id: "gaptlpv",
		headerText: "Total Loss Protect",
		subHeader: "Goods Carrying Vehicles",
		ipidUrl: DocsPreviousBaseUrl + "IPID-GAP-Total-Loss-Protect-Van.pdf",
		policyWordingUrl: DocsPreviousBaseUrl + "PW-GAP-Total-Loss-Protect-Van.pdf",
	},
};

const gapCoverDataManager = (key) => {
	if (key === "ALL") {
		var dataArray = Array.from(Object.values(gapCoverData));
		return dataArray;
	}

	let foundData = gapCoverData[key];

	if (!foundData) {
		const msg = `gapCoverDataManager -- can't find ${key}`;
		throw msg;
	}

	return foundData;
};

export default gapCoverDataManager;
