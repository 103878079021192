import React from "react";

import OpexCard from "./OpexCard";

const PolicyTypeOpexCards = (props) => {

	const { policyType } = props;
	const data = require("siteAssets/datafiles/opexData").default(policyType);

	const showPolicyTypes = policyType === "ALL";

	return (
		<>
			{!showPolicyTypes && (
				<h2 className="text-purple h3 mb-4">Our Policy Upgrades</h2>	
			)}
			
			<div className="row justify-content-center row-cols-1 row-cols-lg-3 row-cols-xxl-4">
				{data.map((opex, i) => {					
					return (
						<div key={opex.groupCode} className="col mb-3">
							<OpexCard opexId={opex.groupCode} opexData={opex} showPolicyTypes={showPolicyTypes} />
						</div>
					)
				})}
			</div>
		</>
	);

}

export default PolicyTypeOpexCards;