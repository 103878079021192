import React from "react";

import purposesData from "./purposesData";

const ProcessingPurposes = (props) => {

    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Purposes for which we process your personal data</th>
                        <th scope="col">The basis on which we can do this (this is what the law allows)</th>
                    </tr>
                </thead>
                <tbody>
                    {purposesData.map((x, i) => (
                        <tr key={i}>
                            <td className="w-50">{x.purpose}</td>
                            <td>{x.basis}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

}

export default ProcessingPurposes;