import React from "react";

const coverLevelsData = {
	["STAN"]: 
	{
		id: "STAN",
		image: require("siteAssets/images/products/STAN-2.png"),
		imageTrim: require("siteAssets/images/products/STAN-trim.png"),
		imageAlt: "Standard product logo",
		description:
			"Our great value insurance with the option to choose from our range of policy upgrades",
		shortDescription: "Our great value insurance",
	},
	["XTRA"]: {
		id: "XTRA",
		image: require("siteAssets/images/products/XTRA-2.png"),
		imageTrim: require("siteAssets/images/products/XTRA-trim.png"),
		imageAlt: "Extra product logo",
		description:
			"Our Extra policy includes legal expenses cover at a discounted price",
		shortDescription: "Includes legal expenses cover",
	},
	["PLUS"]: {
		id: "PLUS",
		image: require("siteAssets/images/products/PLUS-2.png"),
		imageTrim: require("siteAssets/images/products/PLUS-trim.png"),
		imageAlt: "Extra Plus product logo",
		description:
			"Extra Plus also includes legal expenses cover along with Roadside Breakdown, both with great discounts",
		shortDescription: "With legal cover & breakdown",
	},
	["TELE"]: {
		id: "TELE",
		image: require("siteAssets/images/products/TELE-2.png"),
		imageTrim: require("siteAssets/images/products/TELE-trim.png"),
		imageAlt: "Freedom To Drive product logo",
		description:
			"Our new telematics policy. It includes an easy self-fitting device that can help to reduce the cost of your insurance.",
		shortDescription: "Our new telematics policy",
	},
};

const coverLevelsDataManager = (key) => {

	if (key === "ALL") {
		var dataArray = Array.from(Object.values(coverLevelsData));
		return dataArray;
    }

	let foundData = coverLevelsData[key];

	if (!foundData) {
		const msg = `coverLevelsDataManager -- can't find ${key}`;
		throw msg;
	}
	
	return foundData;
};

export default coverLevelsDataManager;