import React, { useRef, useState, useCallback, useEffect } from "react";
import moment from "moment";
import _ from "lodash";

import { Panel, FormGroup } from "siteComponentsLibrary/Layout";
import { Select } from "siteComponentsLibrary/Inputs";

const argChecker = (props, list) =>
	list.forEach((key) => {
		if (!(key in props)) throw `Error -- missing ${key}`;
	});

const getRequestDate = () => moment().format("YYYY-MM-DDTHH:mm:ss");

const ErrorMsg = (props) => (
	<div className={["alert", "alert-info", props.className].join(" ")}>
		{props.children}
	</div>
);

const InfoMsg = (props) => (
	<div className={["alert", "alert-info", props.className].join(" ")}>
		{props.children}
	</div>
);

const Callback = (props) => {
	argChecker(props, ["fnGetDates", "fnSendRequest", "fnGetProductTypeList"]);

	const {
		defaultProduct,
		headerText = "Request a callback",
		headerTextComplete = "Thank you",
		bodyTextComplete = "Thank you. We'll be in touch soon",
		msgPleaseWait = "Please wait..",
		introBlurb = (
			<p>
				Enter your details below and someone from our team will get back to you
				to discuss your requirements.
			</p>
		),
		fnGetDates,
		fnSendRequest,
		fnGetProductTypeList,
		baseId = _.uniqueId("CALLBACK"),
	} = props;

	const constErrorMessage = "Sorry, there's been an error.";

	const [availableDates, setAvailableDates] = useState();
	const [availableProducts, setAvailableProducts] = useState();

	const isSingleProduct = defaultProduct && !_.isArray(defaultProduct);

	const [chosenProduct, setChosenProduct] = useState(
		isSingleProduct ? defaultProduct : ""
	);
	const [chosenName, setChosenName] = useState("");
	const [chosenContactNumber, setChosenContactNumber] = useState("");
	const [chosenDate, setChosenDate] = useState("");
	const [chosenTime, setChosenTime] = useState("");
	const [statusHasSent, setStatusHasSent] = useState(false);
	const [statusIsSending, setStatusIsSending] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const tidyString = (v) => (v ? v.trim() : v);

	const chosenNameClean = tidyString(chosenName);
	const chosenContactNumberClean = tidyString(chosenContactNumber);

	const statusIsInitialised =
		availableDates && (isSingleProduct || availableProducts);

	const isValid =
		chosenProduct &&
		chosenName &&
		chosenNameClean.length >= 2 &&
		chosenContactNumber &&
		chosenContactNumberClean.length >= 2 &&
		chosenTime !== "" &&
		chosenDate !== "";

	//testing
	useEffect(() => {
		return;
		fnGetProductTypeList().then((r) => {
			console.log("fnGetProductTypeList:", r);
		});

		fnGetDates({ RequestDate: getRequestDate() }).then((r) => {
			console.log("fnGetDates:", r);
		});
		return;

		fnSendRequest({
			RequestDate: "2021-07-09T11:22:00",
			Product: "PL",
			SelectedDate: "2021-07-10",
			SelectedTimeSlot: "08:00 - 09:00",
			CustomerName: "SUCCESS_TEST",
			ContactNumber: "07999123456",
		}).then((r) => {
			console.log("fnSendRequest:", r);
		});
	}, []);
	// handlers

	const onChangeHandler = (fn, e) => fn(e.target.value);

	const onSubmit = () => {
		if (!isValid) return;

		const payload = {
			RequestDate: getRequestDate(),
			Product: chosenProduct,
			SelectedDate: chosenDate,
			SelectedTimeSlot: chosenTime,
			CustomerName: chosenNameClean,
			ContactNumber: chosenContactNumberClean,
		};
		console.log("callback submit payload:", payload);

		setErrorMessage("");
		setStatusIsSending(true);

		fnSendRequest(payload)
			.then((r) => {
				const { Status, Message } = r;

				setStatusIsSending(false);
				console.log("fnSendRequest:", r);
				switch (Status) {
					case "SUCCESS": {
						setStatusHasSent(true);
						break;
					}
					// case "NOT_SETUP":
					// case "FAILURE":
					default: {
						setErrorMessage(Message || constErrorMessage);
						refreshAvailableDates();
						break;
					}
				}
			})
			.catch((e) => {
				setStatusIsSending(false);
				setErrorMessage(constErrorMessage);
			});
	};

	const refreshAvailableDates = () =>
		fnGetDates({ RequestDate: getRequestDate() })
			.then((r = {}) => {
				// console.log("fnGetDates:", r);
				const { Items = [] } = r;
				if (Items.length === 0) setAvailableDates(undefined);
				else setAvailableDates(Items);
			})
			.catch((e) => {
				setErrorMessage(constErrorMessage);
			});

	const refreshAvailableProductTypeList = () =>
		fnGetProductTypeList()
			.then((r = {}) => {
				const { Products = [] } = r;
				//console.log("fnGetProductTypeList:", r);
				if (Products.length === 0) setAvailableProducts(undefined);
				else {
					//console.log("Default Product:", defaultProduct);
					//console.log("Products:", Products);
					if (_.isArray(defaultProduct)) {
						var fProducts = Products.filter((item) =>
							defaultProduct.includes(item.Value)
						);
						//console.log("Filtered Products", fProducts);
						setAvailableProducts(fProducts);
					} else {
						setAvailableProducts(Products);
					}
				}
			})
			.catch((e) => {
				setErrorMessage(constErrorMessage);
			});

	// Lookups
	const lookupProducts = useCallback(
		!availableProducts
			? undefined
			: [
					{ label: "Choose a cover type", value: "" },
					...availableProducts.map(({ Value, Display }) => ({
						label: Display,
						value: Value,
					})),
			  ]
					//remove the "choose a product" entry if the user has chosen a product
					.filter(({ value }) => (chosenProduct === "" ? true : value !== "")),
		[!availableProducts, chosenProduct]
	);

	const lookupDates = useCallback(
		!availableDates
			? undefined
			: [
					{ label: "Choose a date", value: "" },
					...availableDates.map(({ Date }) => ({
						label: moment(Date, "YYYY-MM-DDTHH:mm:ss").format(
							"dddd DD MMMM YYYY"
						),
						value: Date,
					})),
			  ]
					//remove the "choose a date" entry if the user has chosen a date
					.filter(({ value }) => (chosenDate === "" ? true : value !== "")),
		[!availableDates, chosenDate === ""]
	);

	const lookupTimes = useCallback(
		!availableDates
			? undefined
			: [
					{ label: "Choose a time", value: "" },
					...availableDates
						.filter((x) => x.Date === chosenDate) // find the specific entry
						.filter((x) => x.AvailableTimes !== undefined)
						.flatMap((x) => x.AvailableTimes)
						.map(({ Value, Display }, i) => ({
							value: `${Value}`,
							label: `${Display}`,
						})),
			  ]
					//show the "choose a time" entry if there's no chosenDate
					.filter(({ value }) => (chosenDate === "" ? value === "" : true))
					//remove the "choose a time" entry if the user has chosen a time
					.filter(({ value }) => (chosenTime === "" ? true : value !== "")),

		[!availableDates, chosenTime === "", chosenDate]
	);

	//NOTE: needs to be *after* "lookupTimes" is declared
	const isTimeControlDisabled = !(
		chosenDate &&
		lookupTimes &&
		lookupTimes.filter((x) => x.value !== "").length >= 1
	);

	// console.log("ddddd lookupTimes", lookupTimes);
	// **************************************
	// ** effects
	// **************************************
	// INIT
	useEffect(() => {
		refreshAvailableDates();
		if (!isSingleProduct) refreshAvailableProductTypeList();
	}, []);

	// reset the chosenTime
	useEffect(() => {
		setChosenTime("");
	}, [chosenDate]);

	const CardBody = !lookupDates ? undefined : (
		<form autoComplete="off">
			{introBlurb}

			{!isSingleProduct && lookupProducts && (
				<FormGroup
					label="Cover type"
					labelFor={`${baseId}_PRODUCT`}
				>
					<Select
						id={`${baseId}_PRODUCT`}
						value={chosenProduct}
						onChange={(e) => onChangeHandler(setChosenProduct, e)}
						itemData={lookupProducts}
					/>
				</FormGroup>
			)}

			<FormGroup
				label="Your name"
				labelFor={`${baseId}_NAME`}
			>
				<input
					id={`${baseId}_NAME`}
					className="form-control"
					value={chosenName}
					onChange={(e) =>
						onChangeHandler((v) => {
							const re = /^[a-zA-Z ]*$/;
							if (!re.test(v)) return;
							setChosenName(v);
						}, e)
					}
					maxLength="40"
				/>
			</FormGroup>

			<FormGroup
				label="Contact number"
				labelFor={`${baseId}_NUMBER`}
			>
				<input
					id={`${baseId}_NUMBER`}
					className="form-control"
					value={chosenContactNumber}
					onChange={(e) =>
						onChangeHandler((v) => {
							const re = /^[0-9\b]*$/;
							if (!re.test(v)) return;
							setChosenContactNumber(v ? v.trim() : v);
						}, e)
					}
					type="tel"
					maxLength="11"
				/>
			</FormGroup>

			{/* Date picklist  */}
			<FormGroup
				label="Date"
				labelFor={`${baseId}_DATE`}
			>
				<Select
					id={`${baseId}_DATE`}
					value={chosenDate}
					onChange={(e) => onChangeHandler(setChosenDate, e)}
					itemData={lookupDates}
				/>
			</FormGroup>

			{/* time picklist  */}
			<FormGroup
				label="Preferred time"
				labelFor={`${baseId}_TIME`}
				className={isTimeControlDisabled ? "disabled" : ""}
			>
				<Select
					id={`${baseId}_TIME`}
					value={chosenTime}
					onChange={(e) => onChangeHandler(setChosenTime, e)}
					itemData={lookupTimes}
					disabled={isTimeControlDisabled}
				/>
			</FormGroup>

		</form>
	);

	const CardFooter = useCallback(
		<>
			{statusIsSending ? (
				<InfoMsg className="mb-3">{msgPleaseWait}</InfoMsg>
			) : (
				<button
					className={["btn", "btn-success", "mb-3", !isValid ? "disabled" : ""]
						.filter((x) => x)
						.join(" ")}
					onClick={onSubmit}
					disabled={!isValid}
				>
					Continue
				</button>
			)}
			{errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
		</>,
		[isValid, errorMessage, statusIsSending]
	);

	// console.log("debug info:", {
	// 	name: chosenName,
	// 	date: chosenDate,
	// 	time: chosenTime,
	// });

	if (!statusIsInitialised && errorMessage)
		return (
			<Panel className="has-border" header={headerText}>
				<ErrorMsg>{errorMessage}</ErrorMsg>
			</Panel>
		);

	if (!statusIsInitialised)
		return (
			<Panel className="has-border" header={headerText}>
				<div>{msgPleaseWait}</div>
			</Panel>
		);

	if (statusHasSent)
		return (
			<Panel className="has-border" header={headerTextComplete}>
				<div>{bodyTextComplete}</div>
			</Panel>
		);

	return (
		<Panel
			className="has-border"
			header={headerText}
			footer={<div className="px-3">{CardFooter}</div>}
		>
			{CardBody}
		</Panel>
	);
};

export default Callback;
