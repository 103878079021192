import React from "react";
import { InfoCircleFill, ExclamationTriangle } from "react-bootstrap-icons";

export * from "libraryComponents/Feedback";

import * as LibComponents from "libraryComponents/Feedback";

export const WarningWithIcon = (props) => {
    
    const { children, icon = <InfoCircleFill size={40} /> } = props;
    
    return (
        <LibComponents.Warning>
            <div className="d-flex gap-3">
                <div className="col-auto my-auto">
                    {icon}
                </div>
                <div className="col my-auto"> {children}</div>
            </div>
        </LibComponents.Warning>
    )
}

export const ErrorWithIcon = (props) => {

    const { children, icon = <ExclamationTriangle size={40} /> } = props;

    return (
        <LibComponents.Error>
            <div className="d-flex gap-3">
                <div className="col-auto my-auto">
                    {icon}
                </div>
                <div className="col my-auto"> {children}</div>
            </div>
        </LibComponents.Error>
    )
}
