import React from "react";
import { Oval } from "react-loading-icons";

const AnimatedOval = (props) => {
	const { colour = "#06bcee", strokeWidth = 5, width, height } = props;

	return (
		<Oval
			stroke={colour}
			strokeWidth={strokeWidth}
			width={width}
			height={height}
		/>
	);
};

export default AnimatedOval;
