import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { EmailLink } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("green-card");

const GreenCard = (props) => {
	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection
						heading="Important notice about driving in Europe with effect from 2nd August 2021"
						className="panel-header"
					>
						<div>
							The good news is that if planning to travel abroad from 2nd August
							the European Commission has implemented a new change to motoring
							law, whereby drivers of UK registered vehicles, including those from
							Northern Ireland, will no longer need a Green Card to drive their
							car in EU countries.
						</div>
					</PanelSection>
					
					<PanelSection
						heading="Are you are planning to use your vehicle to travel to EU countries?"
					>
						<div>
							If yes, all we ask is that you give us notice of at least 14 days
							prior to your travel date so we can check you have sufficient
							coverage in place. Simply{" "}
							<EmailLink
								emailAddress={require("config").default.emailAddresses.customerService}
								altLabel="email customer services"
							/>
							{" "}
							with the dates of your travel, the countries you are travelling to and
							whether you will be towing a caravan or trailer and we will do the
							rest.
						</div>
					</PanelSection>

					<PanelSection
						heading="What documents will you need when driving abroad?"
					>
						<div>
							After 2nd August, a certificate of insurance will cover drivers
							travelling between other EU/EAA countries. In addition to your
							certificate, we would also recommend taking with you, your driving
							licence, V5C logbook and display a UK sticker on your vehicle.
						</div>
						<div>
							If you would like further information on using your vehicle abroad,
							please{" "}
							<ExternalLink
								href="https://www.gov.uk/visit-eu-switzerland-norway-iceland-liechtenstein"
								label="read the guidance on the gov.uk website"
							/>.
						</div>
					</PanelSection>
				</Panel>

			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default GreenCard;
