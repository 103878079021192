import React from "react";
import Base from "@library/common/components/styled/standard/errorPaths/TemplateBase";
import BuildIcon from "@material-ui/icons/Build";
import scrollToTop from "@library/common/components/base/functions/scrollToTop";

const onLoad = () => {
	scrollToTop();
};

import Panel from "siteComponents/common/Panel";

const Component = (props) => {
	return (
		<Base
			headerText="Site Maintenance"
			pageTitle="Site Maintenance"
			ErrorType="Maintenance"
			onLoad={onLoad}
			{...props}
		>
			<Panel header="Site Maintenance">
				<div className="text-center col-12">
					<BuildIcon />
				</div>

				<div className="text-center mt-3">
					The site is currently down for scheduled maintenance.
				</div>
				<div className="text-center mt-3">We'll be back soon!</div>
			</Panel>
		</Base>
	);
};

export default Component;
