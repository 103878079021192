import React, { useState } from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink, HyperlinkRoute, Button } from "siteComponentsLibrary/Buttons";
import { TelephoneNumber, PortalLinks } from "siteComponentsLibrary/Display";

import ContactForm from "./ContactForm";

const portalLogin = require("config").default.links.portal.login;
const portalRegister = require("config").default.links.portal.register;

const assets = require("siteAssets/pageManager").default("contact-us");
const opexData = require("siteAssets/datafiles/opexData").default("ALL");

const ListItem = (props) => {
	const { children, heading, iconClassName } = props;
	return (
		<div className="row pt-3 pb-4 pb-md-3">
			<div className="col col-md-auto text-center">
				<span className="d-inline-block rounded-icon me-3">
					<span className={`contact-us-icon icon ${iconClassName}`} />
				</span>
			</div>
			<div className="col-12 col-md">
				<div className="h4 text-center text-md-start">{heading}</div>
				<div className="info">
					{children}
				</div>
			</div>
		</div>
	);
};

const ContactUsView = (props) => {
	const [showSendMessage, setShowSendMessage] = useState(false);

	const bd = opexData.find((x) => x.groupCode === "BD");

	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>
			
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection>
						<div>
							If you need to get in touch with us to make changes to your policy
							or just ask a question, we're here to help.
						</div>
						<div>
							There's a number of options to choose from depending on what you
							need.
						</div>						
					</PanelSection>
					<PanelSection className="contact-us">
						<ListItem heading="Emergency contacts" iconClassName="icon-sos">
							<div>
								If you've purchased one of our breakdown products and are in
								need of roadside assistance, please call us on{" "}
								<TelephoneNumber
									phonenumber={bd.claimNumbers[0]}
									isLink={true}
									isStrong={false}
								/>.
							</div>
							<div>
								If your insurer is{" "}
								<span className="fw-semibold">Pukka Services</span> and you need to
								make a claim on your motor insurance, then please call{" "}
								<TelephoneNumber
									phonenumber={"0345 528 0262"}
									isLink={true}
									isStrong={false}
								/>.
							</div>
							<div>
								If your insurer is not{" "}
								<span className="fw-semibold">Pukka Services</span>, please check
								your <span className="fw-semibold">policy wording</span> for the
								claims telephone number (you can check who your insurer is on
								your certificate of motor insurance).
							</div>
							<div>
								To start a claim with any of our other products, please visit
								the{" "}
								<HyperlinkRoute className="card-link" route="/make-a-claim">
									make a claim page
								</HyperlinkRoute>.
							</div>
						</ListItem>
					</PanelSection>
					
					<PanelSection className="contact-us">
						<ListItem
							heading="Use your online account"
							iconClassName="icon-laptop"
						>
							<div>
								If you're an existing customer with a car or van policy, your
								best option is to use your online account. It's available 24/7
								and you'll be able to make changes to your policy (like change
								your vehicle or address), view and upload documents or ask a
								question.
							</div>

							<div className="row">
								<PortalLinks login={portalLogin} register={portalRegister} />								
							</div>
						</ListItem>
					</PanelSection>

					<PanelSection className="contact-us">
						<ListItem
							heading="Talk to us on Live chat"
							iconClassName="icon-bubbles"
						>
							<div>
								Our live chat option is also available for you to ask a question.
								Look to the bottom right of this page and click on the button to
								start.
							</div>
						</ListItem>
					</PanelSection>

					<PanelSection className="contact-us">
						<ListItem
							heading="Payment enquiries"
							iconClassName="bi bi-currency-pound"
						>
							<div>
								If you need to make a payment, you can do this using{" "}
								<HyperlinkRoute className="card-link" route="/payments">
									our online payment process
								</HyperlinkRoute>
								.
							</div>
							<div>
								If you have any questions about your Direct Debit, or would like
								to change the date of your instalments, then please{" "}
								<ExternalLink
									href="https://digital.premiumcredit.com/"
									label="visit Premium Credit"
									nowrap={false}
								/>{" "}
								or call them on{" "}
								<TelephoneNumber
									phonenumber="0344 736 9836"
									isLink={true}
									isStrong={false}
								/>
								, as they are the administrators of your Direct Debit agreement.
							</div>
						</ListItem>
					</PanelSection>

					<PanelSection className="contact-us">
						<ListItem heading="Message us" iconClassName="bi bi-card-text">
							<div>
								If you have a car or van policy and haven't yet registered for
								your online account, you can send us a message and our team will
								respond to your query as quickly as possible.
							</div>

							<div className="pt-1">
								{!showSendMessage && (
									<Button
										onClick={(e) => {
											e.preventDefault();
											setShowSendMessage(true);
										}}
										className="btn-link btn-link-inline"
									>
										Send us a message
									</Button>
								)}
								{/* NOTE: The "isEnabled" approach is used so that <ContactForm /> can preload the end point data */}
								<ContactForm isEnabled={showSendMessage} />
							</div>
						</ListItem>
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default ContactUsView;
