// import axios from "axios";
// import { createServiceObj } from "@library/common/helpers/service";
import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";

const requiredArgs = ["AWSConfig", "AxiosData", "contactusData"];

const generateEndpoints = (args = {}) => {
	requiredArgs.forEach((x) => {
		if (!x in args) {
			console.log("args:", args);
			throw `Error in base config endPoints -- missing ${x}`;
		}
	});

	const { AxiosData, endPointsPayment, AWSConfig, dateHelpers, contactusData } =
		args;

	return {
		contactus: {
			getLookupList: {
				postProcessAxios: [
					postProcessAxiosFunctions.JsonOptions,
					postProcessAxiosFunctions.JSONParse,
					postProcessAxiosFunctions.convertLabels,
				],

				axiosOptions: {
					method: "POST",
					headers: {
						"x-api-key": contactusData.apiKey,
					},
					url: `${contactusData.endPoint}/GetFrontEndLists`,
					data: {
						Operation: "single",
						ListName: "contact-us-types",
					},
				},
			},
			sendRequest: {
				axiosOptions: {
					method: "POST",
					headers: {
						"x-api-key": contactusData.apiKey,
					},
					url: `${contactusData.endPoint}/SendContactRequest`,
					// validateStatus: (status) => {
					// 	if (status === 400) return true; // Invalid request -- pass it to the front end
					// 	return status >= 200 && status < 300;
					// },
				},
			},
		},

		status: {
			axiosOptions: {
				method: "POST",
				headers: {
					"x-api-key": AxiosData.apiKey,
				},
				url: `${AxiosData.endPoint}/StatusCheck`,
			},
		},

		getSiteAlerts: {
			axiosOptions: {
				method: "POST",
				headers: {
					"x-api-key": AxiosData.apiKey,
				},
				url: `${AxiosData.endPoint}/GetSiteAlerts`,
			},
		},

		payment: {
			GetPolicyBalance: {
				retryCount: 0,
				axiosOptions: {
					method: "POST",
					headers: {
						"x-api-key": AxiosData.apiKey,
					},
					url: `${AxiosData.endPoint}/GetPolicyBalance`,
				},
			},
			RegisterAdhocPayment: {
				retryCount: 0,
				axiosOptions: {
					method: "POST",
					headers: {
						"x-api-key": endPointsPayment.apiKey,
					},
					url: `${endPointsPayment.baseUrl}/RegisterPayment`,
				},
			},
		},
		vehicle: {
			transmission: {
				// cache: true,

				postProcessAxios: [
					postProcessAxiosFunctions.JsonOptions,
					postProcessAxiosFunctions.JSONParse,
					postProcessAxiosFunctions.convertLabels,
				],
				retryCount: 0,
				axiosOptions: {
					method: "POST",
					data: {
						ListName: "vehicle-transmission",
						Operation: "single",
					},
					headers: {
						"x-api-key": AxiosData.apiKey,
					},

					url: `${AxiosData.endPoint}/GetFrontEndLists`,
				},
			},
			fueltype: {
				// cache: true,

				postProcessAxios: [
					postProcessAxiosFunctions.JsonOptions,
					postProcessAxiosFunctions.JSONParse,
					postProcessAxiosFunctions.convertLabels,
					(response) => {
						if (!response) return response;
						return response.map((x) => {
							let newValue = x.value;
							switch (x.value) {
								case "FuelPetrol":
									newValue = "P";
									break;
								case "FuelDiesel":
									newValue = "D";
									break;
								case "FuelElec":
									newValue = "E";
									break;
							}
							return { ...x, value: newValue };
						});
					},
				],
				retryCount: 0,
				axiosOptions: {
					method: "POST",
					data: {
						ListName: "vehicle-fueltype",
						Operation: "single",
					},
					headers: {
						"x-api-key": AxiosData.apiKey,
					},

					url: `${AxiosData.endPoint}/GetFrontEndLists`,
				},
			},
		},
	};
};

export default generateEndpoints;
