import React from "react";
import { matchPath, useLocation } from "react-router-dom";

import { HyperlinkGoto } from "siteComponents";

const CheckCurrentPage = (routepath) => {
    const { pathname } = useLocation();
    const isCurrentPage = matchPath(pathname, {
        path: routepath,
        exact: true,
        strict: false,
    });

    if (isCurrentPage) {
        return { ariaCurrent: "page", isCurrent: true };
    }

    return { ariaCurrent: undefined, isCurrent: false };
}  

const NavLink = (props) => {

    const { label, routepath, className, dataBsToggle, dataBsTarget } = props;

    const linkClassName = !className ? "nav-link" : className;
    
    var currentPage = CheckCurrentPage(routepath);
    const linkClass = `${linkClassName} ${currentPage.isCurrent ? "active" : ""}`;

    return (
        <HyperlinkGoto
            label={label}
            route={routepath}
            className={linkClass}
            ariaCurrent={currentPage.ariaValue}
            dataBsToggle={dataBsToggle}
            dataBsTarget={dataBsTarget}
        />
    );
}

export default NavLink;