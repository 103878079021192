import React from "react";
import { ExternalLink, HyperlinkGoto } from "siteComponents";
import SiteBanner from "siteComponents/common/SiteBanner";
import { ContainerMaxWidth } from "siteComponentsLibrary/Layout";

const PageHeader = (props) => {
	const { mainHeading, subHeading, introText, firstRowCards, secondRowCards } =
		props;

	return (
		<>
			<ContainerMaxWidth>
				<div className="row justify-content-center page-header">

					<SiteBanner siteType="brand" />

					{mainHeading && (
						<div className={`col-12 main-heading text-center text-sm-start`}>
							<h1 className="h2">{mainHeading}</h1>
						</div>
					)}
					
				</div>
			</ContainerMaxWidth>
			{/*
			<div className="row page-header justify-content-center">
				<div className={`col-12 px-md-4 col-xl-11 px-xl-3 col-xxl-11`}>
					<div className="row justify-content-center heading-row">
					
						<SiteBanner siteType="brand" />

						{mainHeading && (
							<div
								className={`col-12 heading-col main-heading text-center text-sm-start`}
							>
								<h1 className="">{mainHeading}</h1>
							</div>
						)}

						{subHeading && (
							<div className={`col-12 heading-col sub-heading`}>
								<h2 className="fs-3 fw-bold text-white ms-lg-5 ps-lg-5">
									{subHeading}
								</h2>
							</div>
						)}

						{introText && (
							<div className={`col-12 heading-col leadtext`}>
								<p className="lead fw-bold text-white">{introText}</p>
							</div>
						)}
					</div>

				</div>
			</div>
			*/}
		</>
	);
};

export default PageHeader;
