import React from "react";

import SiteBanner from "siteComponents/common/SiteBanner";
import { ContainerMaxWidth } from "siteComponentsLibrary/Layout";
import { ExistingCustomersButton, MakeAClaimButton } from "siteComponentsLibrary/Buttons";
import { TrustBox } from "siteComponentsLibrary/Misc";

const PageHeader = (props) => {
	
	return (
		<>
			<ContainerMaxWidth className="py-4">
				<div className="row">
					<div className="col-12">
						<SiteBanner siteType="brand" />
					</div>
					
					<div className="col-12 text-center">
						{/* <!-- TrustBox widget - Horizontal --> */}
						<TrustBox
							businessUnitId={require("config").default.trustpilot.businessUnitId}
							templateId={require("config").default.trustpilot.templates.horizontal}
							fallbackLinkUrl={require("config").default.trustpilot.fallbackLink}
						/>
						{/* <!-- End TrustBox widget --> */}
					</div>
					<div className="d-lg-none col-12 pt-3 d-flex gap-3 justify-content-center">
						<ExistingCustomersButton isDiv={true} navItemClass="menu-float" />
						<MakeAClaimButton />
					</div>
				</div>
			</ContainerMaxWidth>
		</>
	);
};

export default PageHeader;
