import "core-js/stable";
import "regenerator-runtime/runtime";

import React from "react";
import { Router } from "react-router";
import { Route, Switch, Redirect } from "react-router-dom";

import "moveToLibrary/prototypes";

import history from "siteHistory";
import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.css";
import "libraryCss/brands/freedom";
//import "siteAssets/css/mainStyle";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap-icons/font/bootstrap-icons.css";

// 	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css">
//	<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/js/bootstrap.bundle.min.js" integrity="sha384-gtEjrD/SeCtmISkJkNUaaKMoLD0//ElJ19smozuHV6z3Iehds+3Ulb9Bn9Plx0x4" crossorigin="anonymous"></script>



import Routes from "../Routes";
import RouteLogger from "./data/RouteLogger";
import StatusChecker from "./data/StatusChecker";
import useLiveChat from "./useLiveChat";

// import LiveChat from "./LiveChat";

const App = () => {
	useLiveChat();

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Freedom Brokers</title>
			</Helmet>
			<React.StrictMode>
				<Router history={history}>
					<RouteLogger>
						<StatusChecker>
							<Switch>
								<Route>
									<Routes />
									{/* <LiveChat /> */}
								</Route>
							</Switch>
						</StatusChecker>
					</RouteLogger>
				</Router>
			</React.StrictMode>
		</>
	);
};

export default App;
