import React from "react";

import Layout from "siteLayouts/PageLayouts/OpexInfoPage";

const assets = require("siteAssets/pageManager").default("optional-cover");

const OptionalCover = (props) => {
	return (
		<Layout assets={assets} />
	);
};

export default OptionalCover;
