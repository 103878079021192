import config from "config";
const debugShowVar = config.isDev === false ? false : true;
const debugDefaultVar = config.isDev === false ? false : false;

import React, { useState, useEffect } from "react";
import siteServices from "siteManagers/services";

import { FormGroup, PanelSection } from "siteComponentsLibrary/Layout";
import { TextBox, DropDownType, TextArea } from "siteComponentsLibrary/Inputs";
import { Button }  from "siteComponentsLibrary/Buttons";
import { ErrorWithIcon, Success, WarningWithIcon } from "siteComponentsLibrary/Feedback";
import { Pulse } from "siteComponentsLibrary/Animated";

// import * as regExConstants from "@library/common/constants/regEx";
import { tidyPostcode } from "@library/common/helpers/misc/postcode";
import { isPostcode, isWebReference } from "@library/common/helpers/validation";


// *************************************************
// COMPONENTS
// *************************************************
const Wrapper = (props) => {
	return <div className="contact-form">{props.children}</div>;
};

const ListItem = ({
	children,
	heading,
	helpText,
	helpTextFurther,
	id,
	isError = false,
	className,
	classNameItem = "col-md-7",
	hasErrorNoExclamation = false,
	errMessage,
}) => {

	return (
		<FormGroup
			label={heading}
			labelFor={id}
			helpText={helpText}
			helpTextFurther={helpTextFurther}
			hasError={isError}
			errorMessage={errMessage}
			className={className}
			classNameContent={classNameItem}
		>
			{children}
		</FormGroup>
	);	
};

const CharCount = (props) => {
	const {
		value,
		charLimit,
		warning = "You have exceeded the character limit",
		showLimitWarning = true
	} = props;

	if (charLimit === 0) return null;
	if (!charLimit) return null;
	// console.log(value, typeof value)
	if (value && typeof value !== "string") return null;

	const curLength = typeof value === "string" ? value.length : 0;

	const Wrapper = ({ children, hasError }) => (
		<span
			className={["char-count", hasError && "has-error"]
				.filter((x) => x)
				.join(" ")}
		>
			{children}
		</span>
	);

	if (showLimitWarning) {
		if (curLength > charLimit)
			return <Wrapper hasError={true}>{warning}</Wrapper>;

		if (curLength === charLimit - 1)
			return <Wrapper>{charLimit - curLength} character remaining</Wrapper>;

		if (curLength === charLimit)
			return <Wrapper>You have reached the character limit</Wrapper>;

		return <Wrapper>{charLimit - curLength} characters remaining</Wrapper>;
	}
	if (!showLimitWarning) {
		if (curLength > charLimit)
			return (
				<span className="char-count has-error">
					{curLength} / {charLimit} characters
					{warning && <span> ({warning})</span>}
				</span>
			);

		return (
			<span className="char-count">
				{curLength} / {charLimit} characters
			</span>
		);
	}
};
// *************************************************
// STATIC variables
// *************************************************
const valueVariableList = [
	"Policytype",
	"Yourname",
	"Postcode",
	"Webref",
	"Details",
	"Category",
];

const policyTypeList = [
	{ label: "Car", value: "PC" },
	{ label: "Van", value: "GV" },
	{ label: "Gap", value: "GAP" },
	{ label: "Public Liability", value: "PUBLICLIABILITY" },
	{ label: "Employers Liability", value: "EMPLOYERSLIABILITY" },
	{ label: "Tradesman", value: "TRADESMAN" },
];

const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
const fnCleanValue = (r = "") => {
	return r.trim();
};

const PleaseWaitComponent = () => <Pulse colour="#000" />;

//**********************************************
// MAIN FORM
//**********************************************
const ContactForm = (props) => {
	const {
		// onSubmit: fnOnSubmit = () => null,
		baseId = "CONTACTFORM",
		isEnabled = true,
	} = props;

	const [valuePolicytype, setValuePolicytype] = useState();
	const [valueYourname, setValueYourname] = useState();
	const [valuePostcode, setValuePostcode] = useState();
	const [valueWebref, setValueWebref] = useState();
	const [valueDetails, setValueDetails] = useState();
	const [valueCategory, setValueCategory] = useState();

	const [validPolicytype, setValidPolicytype] = useState();
	const [validYourname, setValidYourname] = useState();
	const [validPostcode, setValidPostcode] = useState();
	const [validWebref, setValidWebref] = useState();
	const [validDetails, setValidDetails] = useState();
	const [validCategory, setValidCategory] = useState();

	const [showErrorPolicytype, setShowErrorPolicytype] = useState();
	const [showErrorYourname, setShowErrorYourname] = useState();
	const [showErrorPostcode, setShowErrorPostcode] = useState();
	const [showErrorWebref, setShowErrorWebref] = useState();
	const [showErrorDetails, setShowErrorDetails] = useState();
	const [showErrorCategory, setShowErrorCategory] = useState();

	const detailsMaxLength = 4000;

	const [showErrorsPage, setShowErrorsPage] = useState();

	const [lookupData, setLookupData] = useState(undefined);
	const [errMessage, setErrMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	const [isSent, setIsSent] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const validPage =
		validYourname &&
		validPostcode &&
		validWebref &&
		validDetails &&
		validCategory;

	const isInit = lookupData !== undefined;
	const isDisabled = isSaving;
	const showSubmitButton = !isSaving;
	const showFormSectionStatus = (function () {
		if (!isInit) return undefined;

		return "FORM";

		// BELOW is disabled
		if (!valuePolicytype) return undefined;

		if (["PC", "GV"].includes(valuePolicytype)) return "FORM";

		return "LIVECHAT";
	})();

	// ******************************************************
	// FUNCTIONS
	// ******************************************************
	const fnGenerateStartAgainLink = (msg = "Click here to start again.") => (
		<Button
			onClick={(e) => {
				e.preventDefault();
				fnResetPage();
			}}
			className="btn-link btn-link-inline"
		>
			{msg}
		</Button>
	);

	const fnResetForm = () => {
		valueVariableList.forEach((k) => {
			const keyCapitalised = capitalizeFirstLetter(k);

			const fnSetValue = eval(`setValue${keyCapitalised}`);
			const fnSetValid = eval(`setValid${keyCapitalised}`);
			const fnSetShowError = eval(`setShowError${keyCapitalised}`);

			const newValue = undefined;

			fnSetValue(newValue);
			fnSetValid(fnValidateItem(k, newValue));
			fnSetShowError(false);
		});
		setShowErrorsPage(false);
	};

	const fnResetPage = () => {
		fnResetForm();
		setIsSent(false);
		setErrMessage("");
		setSuccessMessage("");
	};

	const fnOnItemChange = (key, v) => {
		const fnValue = eval(`setValue${key}`);
		const fnShowError = eval(`setShowError${key}`);

		// tidyPostcode

		switch (key) {
			case "Postcode": {
				if (!v) fnValue(v);
				else fnValue(tidyPostcode(v.toUpperCase()));

				break;
			}
			case "Webref": {
				if (!v) fnValue(v);
				else fnValue(v.trim());

				break;
			}
			default: {
				fnValue(v);
			}
		}

		fnShowError(true);
	};

	const fnValidateItem = (key, value) => {
		//Required check (maybe filter by key?)
		if (value === undefined) return false;
		if (value === "") return false;

		if (key === "Details" && value.length > detailsMaxLength) return false;
		if (key === "Webref" && !isWebReference(value)) return false;
		if (key === "Postcode" && !isPostcode(value)) return false;

		return true;
	};

	const fnSubmit = () => {
		const fnGenerateError = (msg = "Oops. Something went wrong.") => {
			setErrMessage(msg);
			setIsSaving(false);
			setIsSent(false);
		};

		const fnGenerateSuccess = (msg = "Thank you.") => {
			setSuccessMessage(msg);
			setIsSaving(false);
			setIsSent(true);
			fnResetForm();
		};

		setShowErrorsPage(true);

		if (!validPage) {
			setErrMessage("Please correct the errors above.");
			return;
		}

		setIsSaving(true);
		setErrMessage(undefined);
		setSuccessMessage(undefined);

		const payLoad = {
			CustomerName: fnCleanValue(valueYourname),
			Postcode: fnCleanValue(valuePostcode),
			WebReference: fnCleanValue(valueWebref),
			Message: fnCleanValue(valueDetails),
			ContactType: fnCleanValue(valueCategory),
		};

		// MOCKING
		if (false) {
			const isSuccess = Math.floor(Math.random() * 2) === 1;
			console.log(payLoad, isSuccess);

			if (isSuccess) {
				setIsSent(true);
				fnResetForm();
			} else setErrMessage("Oops. Something went wrong.");

			return;
		}

		siteServices.contactus
			.sendRequest(payLoad)
			.then((response = {}) => {
				const { Status, Message, MinSLA, MaxSLA, SLA } = response;
				console.log(response);

				switch (Status) {
					case "SUCCESS": {
						const newMessage =
							Message ||
							[
								`Thank you.`,

								(function () {
									const fnGenerateText = (SLATxt) =>
										`We'll be in touch within ${SLATxt} hours.`;

									const foundMinSLA = MinSLA !== undefined && MinSLA !== 0;
									const foundMaxSLA = MaxSLA !== undefined && MaxSLA !== 0;

									if (!foundMinSLA && !foundMaxSLA) return;
									if (!foundMinSLA) return fnGenerateText(MaxSLA);
									if (!foundMaxSLA) return fnGenerateText(MinSLA);
									if (MinSLA === MaxSLA) return fnGenerateText(MaxSLA);

									return fnGenerateText(`${MinSLA} to ${MaxSLA}`);
								})(),

								fnGenerateStartAgainLink("Click here to start again."),
							]
								.filter((x) => x)
								.map((x) => <span className="pe-2">{x}</span>);

						fnGenerateSuccess(<span>{newMessage}</span>);
						return;
					}
					case "RECALL_FAIL":
					case "INVALID_WEB_REF":
					case "RISK_MATCH_FAIL":
					case "INVALID_POSTCODE":
					default: {
						fnGenerateError(Message);
						return;
					}
				}
			})
			.catch((e) => {
				fnGenerateError(
					"Oops. There's been an error. Please check your details and try again."
				);
			});
	};

	if (debugShowVar) {
		console.groupCollapsed("Variables");
		{
			valueVariableList.forEach((k) => {
				const keyCapitalised = capitalizeFirstLetter(k);
				// console.log(keyCapitalised);
				console.log(
					k,
					":",
					"value:",
					eval(`value${keyCapitalised}`),
					"valid:",
					eval(`valid${keyCapitalised}`),
					"show error:",
					eval(`showError${keyCapitalised}`)
				);
			});
		}

		console.groupEnd();
	}

	// ******************************************************
	// Effects
	// ******************************************************

	// Set the defaults for variables
	useEffect(() => {
		const defaultValues = Object.fromEntries(
			valueVariableList.map((k) => [k, undefined])
		);

		if (debugDefaultVar) {
			defaultValues["Policytype"] = "PC";
			defaultValues["Yourname"] = "Test Name";
			defaultValues["Postcode"] = "CF82 8AA";
			defaultValues["Webref"] = "88094-11766-54399";
			defaultValues["Category"] = "GENERAL";
			defaultValues["Details"] = "Test Description";
		}

		valueVariableList.forEach((k) => {
			const keyCapitalised = capitalizeFirstLetter(k);

			const fnSetValue = eval(`setValue${keyCapitalised}`);
			const fnSetValid = eval(`setValid${keyCapitalised}`);
			const fnSetShowError = eval(`setShowError${keyCapitalised}`);

			const v = defaultValues[k];

			fnSetValue(v);
			fnSetValid(fnValidateItem(k, v));
			fnSetShowError(false);
		});
	}, []);

	// Create effects for each value
	valueVariableList.forEach((k) => {
		const keyCapitalised = capitalizeFirstLetter(k);

		useEffect(() => {
			// console.log("updated", k, eval(k));

			const newValue = eval(`value${keyCapitalised}`);
			const fnSetValid = eval(`setValid${keyCapitalised}`);
			fnSetValid(fnValidateItem(k, newValue));
		}, [eval(`value${keyCapitalised}`)]);
	});

	//Lookups
	useEffect(() => {
		Promise.all([siteServices.contactus.getLookupList()])
			.then((response = []) => {
				const [category] = response;
				setLookupData({ category, policytype: policyTypeList });
			})
			.catch((e) => {
				setErrMessage(
					"Oops. There's been an error. Please check your details an try again."
				);
			});
	}, []);

	// ******************************************************
	// NOT INIT
	// ******************************************************
	// This is done so that the services can be initilizsed before we show the form.
	if (!isEnabled) return null;

	// ******************************************************
	// NOT INIT
	// ******************************************************

	if (!isInit)
		return (
			<Wrapper>
				<PleaseWaitComponent />
			</Wrapper>
		);

	// ******************************************************
	// Main section
	// ******************************************************
	{
		const dataStartform = (function () {
			if (isSent) return null;

			return null;
			{/* 
			// BELOW is disabled.
			return (
				<ListItem
					heading="Please choose your policy type"
					id={`${baseId}_POLICYTYPE`}
					isError={!validPolicytype && (showErrorsPage || showErrorPolicytype)}
					// className="mx-1"
					classNameItem="col-12 col-xxl-7"
				>
					<RadioButtons
						id={`${baseId}_POLICYTYPE`}
						value={valuePolicytype}
						onChange={(v) => fnOnItemChange("Policytype", v)}
						autoComplete="off"
						disabled={isDisabled}
						itemData={lookupData.policytype}
					/>
				</ListItem>
			);
			*/}
		})();

		const dataMainform = (function () {
			if (isSent) return null;
			if (showFormSectionStatus === "LIVECHAT") return null;
			if (showFormSectionStatus !== "FORM") return null;
			return (
				<>
					<ListItem
						heading="Your name"
						id={`${baseId}_YOURNAME`}
						isError={!validYourname && (showErrorsPage || showErrorYourname)}
					>
						<TextBox
							id={`${baseId}_YOURNAME`}
							value={valueYourname}
							onChange={(v) => fnOnItemChange("Yourname", v)}
							autoComplete="off"
							disabled={isDisabled}
						/>
					</ListItem>

					<ListItem
						heading="Online reference number"
						helpText="Your reference number will be in one of these formats; 1234-123456-1234 or 12345-12345-12345."
						id={`${baseId}_WEBREF`}
						isError={!validWebref && (showErrorsPage || showErrorWebref)}
						//errMessage={
						//	valueWebref
						//		? "Invalid reference number. Please check and try again."
						//		: ""
						//}
					>
						<TextBox
							id={`${baseId}_WEBREF`}
							value={valueWebref}
							onChange={(v) => fnOnItemChange("Webref", v)}
							autoComplete="off"
							disabled={isDisabled}
						/>
					</ListItem>

					<ListItem
						heading="Postcode"
						helpText={`We'll only use your postcode to verify the policy you want to ask a question about. It won't be used for any other purpose.`}
						id={`${baseId}_POSTCODE`}
						isError={!validPostcode && (showErrorsPage || showErrorPostcode)}
						//errMessage={
						//	valuePostcode
						//		? "Invalid postcode. Please check and try again."
						//		: ""
						//}
					>
						<TextBox
							id={`${baseId}_POSTCODE`}
							value={valuePostcode}
							onChange={(v) => fnOnItemChange("Postcode", v)}
							autoComplete="off"
							disabled={isDisabled}
						/>
					</ListItem>

					<ListItem
						heading="What can we help you with?"
						id={`${baseId}_CATEGORY`}
						isError={!validCategory && (showErrorsPage || showErrorCategory)}
					>
						<DropDownType
							id={`${baseId}_CATEGORY`}
							value={valueCategory}
							onChange={(v) => fnOnItemChange("Category", v)}
							autoComplete="off"
							disabled={isDisabled}
							itemData={lookupData.category}
						/>
					</ListItem>

					<ListItem
						heading="Your message"
						id={`${baseId}_DETAILS`}
						isError={!validDetails && (showErrorsPage || showErrorDetails)}
						hasErrorNoExclamation={true}
					>
						<TextArea
							id={`${baseId}_DETAILS`}
							value={valueDetails}
							onChange={(v) => fnOnItemChange("Details", v)}
							rows="8"
							disabled={isDisabled}
						/>
						<CharCount value={valueDetails} charLimit={detailsMaxLength} />
					</ListItem>
					
					<div className="row">
						<div className="col-12">
							<Button
								isLoading={isSaving}
								onClick={fnSubmit}
								className="btn-primary"
							>
								Submit
							</Button>
						</div>
					</div>

				</>
			);
		})();

		const dataAlerts = (function () {
			if (showFormSectionStatus === "LIVECHAT")
				return (
					<PanelSection className="mt-3" classNameChildren="col-md-7">
						<WarningWithIcon>
							Please use our live chat option to send us a message.
						</WarningWithIcon>
					</PanelSection>
					
				);

			return (
				<div>
					{errMessage && (
						<PanelSection className="mt-3" classNameChildren="col-md-7">
							<ErrorWithIcon>{errMessage}</ErrorWithIcon>
						</PanelSection>
					)}
					{successMessage && (
						<PanelSection className="mt-3" classNameChildren="col-md-7">
							<Success>{successMessage}</Success>
						</PanelSection>
					)}
				</div>
			);
		})();

		return (
			<Wrapper>
				{dataStartform}
				{dataMainform}
				{dataAlerts}
			</Wrapper>
		);
	}
};

export default ContactForm;
