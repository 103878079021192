import React from "react";

export const insurerData = {
	["pukka"]: {
		insurerName: "Pukka",
		logoImage: require("siteAssets/images/insurers/pukka.png"),
		logoImageAlt: "Pukka",
		policyTypes: ["PC", "GV"],
	},
	["ageas"]: {
		insurerName: "Ageas",
		logoImage: require("siteAssets/images/insurers/ageas.png"),
		logoImageAlt: "Ageas",		
		policyTypes: ["PC", "GV"],
	},
	["covea"]: {
		insurerName: "Covea Insurance",
		logoImage: require("siteAssets/images/insurers/covea.png"),
		logoImageAlt: "Covea Insurance",
		policyTypes: ["PC", "GV"],
	},
	["financial-legal"]: {
		insurerName: "Financial & Legal",
		logoImage: require("siteAssets/images/insurers/financial-legal.png"),
		logoImageAlt: "Financial & Legal",
		policyTypes: ["PC", "GV"],
	},
	["lexelle"]: {
		insurerName: "Lexelle",
		logoImage: require("siteAssets/images/insurers/lexelle.png"),
		logoImageAlt: "Lexelle",
		policyTypes: ["PC", "GV"],
	},
	["motor-prosecution-defence"]: {
		insurerName: "Motor Prosecution Defence",
		logoImage: require("siteAssets/images/insurers/motor-prosecution-defence.png"),
		logoImageAlt: "Motor Prosecution Defence",
		policyTypes: ["PC", "GV"],
	},
	["markerstudy"]: {
		insurerName: "Markerstudy",
		logoImage: require("siteAssets/images/insurers/markerstudy.png"),
		logoImageAlt: "Markerstudy",
		policyTypes: ["PC", "GV"],
	},
	["premier"]: {
		insurerName: "Premier Underwriting",
		logoImage: require("siteAssets/images/insurers/premier.png"),
		logoImageAlt: "Premier Underwriting",
		policyTypes: ["PC", "GV"],
	},
	
	["sabre"]: {
		insurerName: "Sabre",
		logoImage: require("siteAssets/images/insurers/sabre.png"),
		logoImageAlt: "Sabre",
		policyTypes: ["PC", "GV"],
	},
	["southern-rock"]: {
		insurerName: "Southern Rock",
		logoImage: require("siteAssets/images/insurers/southern-rock.png"),
		logoImageAlt: "Southern Rock",
		policyTypes: ["PC", "GV"],
	},
	["strategic"]: {
		insurerName: "Strategic Insurance Services",
		logoImage: require("siteAssets/images/insurers/strategic.png"),
		logoImageAlt: "Strategic Insurance Services",
		policyTypes: ["PC", "GV"],
	},
	["supercover"]: {
		insurerName: "Supercover",
		logoImage: require("siteAssets/images/insurers/supercover.png"),
		logoImageAlt: "Supercover",
		policyTypes: ["PC", "GV"],
	},
	["trinity-lane"]: {
		insurerName: "Trinity Lane",
		logoImage: require("siteAssets/images/insurers/trinity-lane.png"),
		logoImageAlt: "Trinity Lane",
		policyTypes: ["PC", "GV"],
	},
	["zenith"]: {
		insurerName: "Zenith Insurance",
		logoImage: require("siteAssets/images/insurers/zenith.png"),
		logoImageAlt: "Zenith Insurance",
		policyTypes: ["PC", "GV"],
	},
};

const splitIntoGroups = (dataArray, group) => {
	if (group === 0)
		return dataArray;

	var rtnAllArray = [];
	while (dataArray.length) {
		rtnAllArray.push(dataArray.splice(0, group));
	}

	return rtnAllArray;
}

const insurerDataManager = (key, group = 0) => {

	if (key === "ALL") {
		var dataArray = Array.from(Object.values(insurerData));
		return splitIntoGroups(dataArray, group);
	}

	if (key === "PC" || key === "GV") {
		var dataArray = Array.from(Object.values(insurerData))
			.filter(data => data.policyTypes.includes(key));
		return splitIntoGroups(dataArray, group);
	}

	let foundData = insurerData[key];

	if (!foundData) {
		const msg = `insurerDataManager -- can't find ${key}`;
		throw msg;
	}

	return foundData;
};

export default insurerDataManager;