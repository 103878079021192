import React, { useState } from "react";
import _ from "lodash";

import { Panel, PanelSection, FormGroup } from "siteComponentsLibrary/Layout";
import { WarningWithIcon } from "siteComponentsLibrary/Feedback";
import { InputGroupPound } from "siteComponentsLibrary/Inputs";
import { Button } from "siteComponentsLibrary/Buttons";

import {
	TextBox,
} from "siteComponents";

const formatBalance = (v = 0) => {
	return Intl.NumberFormat("en-GB", {
		style: "currency",
		currency: "GBP",
	}).format(v);
};

const convertPoundToPence = (num) => Math.round(num * 100);
const convertPenceToPound = (num) => (num / 100).toFixed(2);
const convertToNumber = (str) => Number(str);

const VehicleDetails = (props) => {
	const {
		RegNumber,
		Manufacturer,
		ModelDescription,
		PolicyType,
		Doors,
		Fuel,
		Transmission,
		Cc,
		lookups,
	} = props;

	const vehicleDescription = (function () {
		switch (PolicyType) {
			case "PC":
				return (
					<>
						<strong className="fs-6">
							{Manufacturer} {ModelDescription}
						</strong>
						<div className="m-0">
							{Doors} door, {(Fuel && lookups.fueltype[Fuel]) || Fuel}
							{Transmission && `, ${lookups.transmission[Transmission]}`}
							{Cc && Cc > 0 && `, ${Cc}cc`}
						</div>
					</>
				);
			case "GV":
				return (
					<>
						<strong className="fs-6">
							{Manufacturer} {ModelDescription}
						</strong>
						<div className="m-0">
							{(Fuel && lookups.fueltype[Fuel]) || Fuel}
							{Transmission && `, ${lookups.transmission[Transmission]}`}
							{Cc && Cc > 0 && `, ${Cc}cc`}
						</div>
					</>
				);
		}
	})();

	return (
		<div className="row my-2">
			<div className="col-12 col-md-auto">
				<span className="vehicle-registration text-center d-inline-block overflow-wrap-normal">
					{RegNumber}
				</span>
			</div>
			<div className="col-12 col-md-8 mt-2 mt-md-0">{vehicleDescription}</div>
		</div>
	);
};


const PaymentLandingBalance = (props) => {
	const {
		fnGotoPayment,
		panelHeader,
		balance,
		lookups,
		radiobuttonName = _.uniqueId("PAYMENT_AMOUNT_RADIO"),
	} = props;

	if (!balance) throw `Error in Payment Balance -- missing balance data`;

	// const { AllowPartPayments: isPartPayments, MinPayment: minPaymentAmount } =
	// 	balance;

	const isPartPayments = balance.AllowPartPayments;
	const minPaymentAmount = convertPenceToPound(
		convertToNumber(balance.MinPayment)
	);
	const blankPaymentAmount = "";

	const maxPaymentAmount = convertPenceToPound(
		-1 * convertToNumber(balance.FoundBalance)
	);

	const [chosenPaymentAmount, setChosenPaymentAmount] =
		useState(maxPaymentAmount);
	const [paymentType, setPaymentType] = useState("FULL");
	const [paymentTypeErrorFeedback, setPaymentTypeErrorFeedback] = useState();

	const isPaymentFull = paymentType === "FULL";
	const isPaymentPartial = paymentType === "PARTIAL";

	if (!lookups) return null; // May not be ready yet in parent Component

	const policyDescription = (function () {
		if (balance.PolicyType === "PC") return "car";
		if (balance.PolicyType === "GV") return "van";
		return "";
	})();

	const heading = ["Your", policyDescription, "insurance", "policy"]
		.filter((x) => x)
		.join(" ");

	const onMakePayment = () => {
		if (paymentTypeErrorFeedback) return;

		const [_errorMsg, _newValue] = validateNewValue(chosenPaymentAmount);

		if (_errorMsg) {
			setPaymentTypeErrorFeedback(_errorMsg);
			setChosenPaymentAmount(_newValue);
			return;
		}

		const response = fnGotoPayment(_newValue);

		if (!response)
			setPaymentTypeErrorFeedback("Sorry, something's gone wrong.");

		// const _valueInPence = convertPoundToPence(_newValue);
		// // console.log("ddddd", _newValue, _valueInPence);
		// // return;
		// fnGotoPayment(_valueInPence);
	};

	const onChangePaymentType = (_newPaymentType) => {
		// If not changed, then exit early
		if (_newPaymentType === paymentType) return;

		if (_newPaymentType === "FULL") {
			setChosenPaymentAmount(maxPaymentAmount);
		}
		if (_newPaymentType === "PARTIAL") {
			setChosenPaymentAmount(blankPaymentAmount);
		}
		setPaymentTypeErrorFeedback(undefined);

		setPaymentType(_newPaymentType);
	};

	const validateNewValue = (testValue) => {
		if (testValue === blankPaymentAmount) {
			return ["Please enter an amount", blankPaymentAmount];
		}
		if (testValue === undefined) {
			return ["Please enter an amount", blankPaymentAmount];
		}
		if (testValue === "") {
			return ["Please enter an amount", blankPaymentAmount];
		}

		// check it's numeric with two decimal places
		if (!/^[0-9]*\.[0-9]{2}$/.test(testValue) && !/^[0-9]*$/.test(testValue)) {
			return ["Please enter a valid amount with pound and pence", testValue];
		}

		const _valueNumber = convertToNumber(testValue);

		if (_valueNumber < minPaymentAmount) {
			console.log("MIN ERROR", _valueNumber, "<", minPaymentAmount);

			return [
				`Please make a minimum payment of ${formatBalance(minPaymentAmount)}`,
				testValue,
			];
		}

		if (_valueNumber > maxPaymentAmount) {
			console.log("MAX ERROR", _valueNumber, ">", maxPaymentAmount);
			return [
				`Please make a maximum payment of ${formatBalance(maxPaymentAmount)}`,
				testValue,
			];
		}

		return [undefined, _valueNumber];
	};

	const onChangePaymentAmount = (newValue) => {
		const [_errorMsg, _newValue] = validateNewValue(newValue);

		if (_errorMsg) {
			setPaymentTypeErrorFeedback(_errorMsg);
		} else {
			setPaymentTypeErrorFeedback(undefined);
		}

		setChosenPaymentAmount(_newValue);
	};

	console.log("DEBUG", "chosen amount:", chosenPaymentAmount);
	console.log("DEBUG", "maxPaymentAmount:", maxPaymentAmount);

	console.log("DEBUG", "paymentType:", paymentType);

	// *****************************************************************
	// In DEBT
	// *****************************************************************

	if (balance.FoundBalance < 0)
		return (
			<Panel
				header={panelHeader}				
				footer={
					<div className="px-3 pb-3">
						<Button
							className="btn-primary"
							onClick={onMakePayment}
						>
							Make an online payment
						</Button>
					</div>
				}
			>
				<PanelSection className="panel-header" heading={heading}>
					<div>
						<VehicleDetails
							{...balance.FoundVehicle}
							PolicyType={balance.PolicyType}
							lookups={lookups}
						/>
					</div>
				</PanelSection>

				<PanelSection>
					<div className="d-flex">
						<div>Your account balance is:</div>
						<div className="h5 text-danger ps-2">
							{formatBalance(maxPaymentAmount)} in debit
						</div>
					</div>
				</PanelSection>
				
				{isPartPayments && (
					<FormGroup
						label="Choose your amount to pay"
					>
						<div
							className="form-check ms-3 my-2"
							onClick={() => onChangePaymentType("FULL")}
						>
							<input
								className="form-check-input"
								type="radio"
								name={radiobuttonName}
								id={`${radiobuttonName}_FULL`}
								checked={isPaymentFull}
								readOnly
							/>
							<label
								className="form-check-label"
								htmlFor={`${radiobuttonName}_FULL`}
							>
								Full balance - {formatBalance(maxPaymentAmount)}
							</label>
						</div>

						<div
							className="form-check ms-3 my-2"
							onClick={() => onChangePaymentType("PARTIAL")}
						>
							<input
								className="form-check-input"
								type="radio"
								name={radiobuttonName}
								id={`${radiobuttonName}_PARTIAL`}
								checked={isPaymentPartial}
								readOnly
							/>
							<label
								className="form-check-label"
								htmlFor={`${radiobuttonName}_PARTIAL`}
							>
								Other amount
							</label>
						</div>
						{isPaymentPartial && (
							<div className="col-6 col-sm-3 ps-3 mt-3">
								<InputGroupPound>
									<TextBox
										value={chosenPaymentAmount}
										onChange={onChangePaymentAmount}
										maxLength={8}
										onFocus={() => onChangePaymentType("PARTIAL")}
										regEx={/(^$)|([0-9]+([.][0-9]*)?|[.][0-9]+)$/}
									/>
								</InputGroupPound>
							</div>
						)}

						{paymentTypeErrorFeedback && (
							<div className="text-danger mt-3">{paymentTypeErrorFeedback}</div>
						)}
					</FormGroup>
				)}

			</Panel>
		);

	// *****************************************************************
	// In CREDIT
	// *****************************************************************

	return (
		<Panel header={panelHeader}>
			<PanelSection className="panel-header" heading={heading}>
				<div>
					<VehicleDetails
						{...balance.FoundVehicle}
						PolicyType={balance.PolicyType}
						lookups={lookups}
					/>
				</div>
			</PanelSection>
			<PanelSection>
				<WarningWithIcon>
					<div>
						Sorry, we're unable to confirm the outstanding balance on your{" "}
						{policyDescription} insurance policy at this time.
					</div>
					<div>
						Please use our online chat service, where one of our customer
						service advisors will be happy to help.
					</div>
				</WarningWithIcon>
			</PanelSection>
		</Panel>
	);
};

export default PaymentLandingBalance;
