import React from "react";

import PageLayout from "siteLayouts/PageV2";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { MakeAClaimDisplay } from "siteComponentsLibrary/Display";
import { OpexSummary } from "siteComponentsLibrary/Cards";
import { ExternalLink } from "siteComponentsLibrary/Buttons";

const assets = require("siteAssets/pageManager").default("motor-insurance");

const quoteStartCar = require("config").default.links.quoteStart.car;
const quoteStartVan = require("config").default.links.quoteStart.van;

const TierItem = (props) => {
	const { imageSrc, imageAlt, text } = props;

	return (
		<div className="col">
			<div className="mb-3">
				<img src={imageSrc}
					 alt={imageAlt}
					 className="product-logo"
				/>
			</div>
			<div className="product-text">
				{text}
			</div>
		</div>
	);
}

const MotorInsurance = (props) => {

	const opexData = require("siteAssets/datafiles/opexData").default(assets.policyType);
	
	return (
		<PageLayout
			showReturnHome={false}
			pageTitle={assets.pageTitle}
			pageClass={assets.pageClass}
			pageId={assets.pageId}
		>

			<div className="container-fluid white-full-width py-4">
				<ContainerMaxWidth>
					<h2 className="mb-3 h3">Our motor insurance products</h2>
					<div className="row mb-3">
						<div className="col-12">
							Freedom Brokers can offer you great value insurance from our panel of insurers and a
							wide variety of option cover to meet your needs.
						</div>
					</div>

					<Panel
						className="has-border panel-bg"
						classNameBody=""
						footer={
							<div className="d-flex flex-column flex-sm-row gap-2 justify-content-center">
								<ExternalLink
									href={quoteStartVan}
									label="Get a VAN quote"
									className="btn btn-primary"
									data-cy={`ext-link:van`}
								/>
								<ExternalLink
									href={quoteStartCar}
									label="Get a CAR quote"
									className="btn btn-primary"
									data-cy={`ext-link:car`}
								/>
							</div>
						}
					>
						<PanelSection
							classNameChildren="d-flex gap-3 flex-column flex-md-row"
						>
							<TierItem
								imageSrc={require("siteAssets/images/FB-STANDARD.png")}
								imageAlt="Freedom Standard logo"
								text={
									<span>
									Our great value insurance with a range of optional cover to choose from.
								</span>
								}
							/>

							<TierItem
								imageSrc={require("siteAssets/images/FB-EXTRA.png")}
								imageAlt="Freedom Extra logo"
								text={
									<span>
									Our Extra policy includes Legal Expenses cover at a discounted price.
								</span>
								}
							/>

							<TierItem
								imageSrc={require("siteAssets/images/FB-EXTRAPLUS.png")}
								imageAlt="Freedom Extra Plus logo"
								text={
									<span>
									Extra Plus also includes Legal Expenses cover along with Roadside Breakdown, both 
									with great discounts.
								</span>
								}
							/>
						</PanelSection>

					</Panel>
				</ContainerMaxWidth>
			</div>

			<div className="py-4">
				<ContainerMaxWidth>
					<h2 className="mb-3 h3">Optional cover</h2>
					<OpexSummary data={opexData}/>
				</ContainerMaxWidth>

				<ContainerMaxWidth className="mt-5">
					<MakeAClaimDisplay claimType="MOTOR-PANEL"/>
				</ContainerMaxWidth>
			</div>

		</PageLayout>
	);
};

export default MotorInsurance;
