import React, { useState, useEffect, useMemo } from "react";

import { ExternalLink } from "./Buttons";

export const AffiliateWelcome = (props) => {
	const { affilate, sessionStorageKey, database } = props;

	["affilate", "database", "sessionStorageKey"].forEach((k) => {
		if (!(k in props)) {
			throw `Error in AffiliateWelcome -- missing "${k}" in props`;
		}
	});

	const [isModalOpen, setIsModalOpen] = useState(false);

	const configData = useMemo(
		function () {
			if (!affilate) return undefined;
			const searchKey = affilate.toLowerCase();

			if (!database[searchKey]) return undefined;

			return database[searchKey];
		},
		[affilate]
	);

	// EFFECT for enabling the MODAL
	useEffect(() => {
		if (!affilate) return;
		if (!configData) return;

		const sessionStoreageValue = JSON.parse(
			sessionStorage.getItem(sessionStorageKey) || "{}"
		);

		if (
			sessionStoreageValue?.affiliate === affilate &&
			sessionStoreageValue?.disableModal
		)
			return;

		setIsModalOpen(true);

		// Log to session storage that we DON'T want to show the modal again
		sessionStorage.setItem(
			sessionStorageKey,
			JSON.stringify({
				...sessionStoreageValue,
				affiliate: affilate,
				disableModal: true,
			})
		);
	}, [affilate]);

	if (isModalOpen && configData?.Modal) {
		const Modal = configData?.Modal;
		return (
			<Modal onClick={() => setIsModalOpen(false)}>
				{configData.modalData}
			</Modal>
		);
	}

	return null;
};

export const TrustBox = (props) => {
	// https://support.trustpilot.com/hc/en-us/articles/115011421468--Add-a-TrustBox-to-a-single-page-application
	// Create a reference to the <div> element which will represent the TrustBox
	const ref = React.useRef(null);
	React.useEffect(() => {
		// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
		// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
		// When it is, it will automatically load the TrustBox.
		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);

	const { businessUnitId, templateId, theme = "light", fallbackLinkUrl, fallbackLinkText, dataHeight } = props;
	
	if (!businessUnitId || !templateId)
	{
		console.log("TRUSTBOX ERROR: Missing Business Unit Id or Template Id");
		return null;
	}

	// TrustBox widgets: https://businessapp.b2b.trustpilot.com/trustbox/
	
	switch (templateId)
	{
		// Carousel
		case "53aa8912dec7e10d38f59f36": {
			return (
				<div
					ref={ref} // We need a reference to this element to load the TrustBox in the effect.
					className="trustpilot-widget"
					data-locale="en-GB"
					data-template-id={templateId}
					data-businessunit-id={businessUnitId}
					data-style-height={dataHeight ?? "300px"} 
					data-style-width="100%" 
					data-theme={theme} 
					data-stars="4,5" 
					data-review-languages="en"
				>	
					<ExternalLink href={fallbackLinkUrl} label={fallbackLinkText ?? "View our Trustpilot reviews"} />
				</div>
			
			);
		}
		
		// Horizontal
		case "5406e65db0d04a09e042d5fc": {
			return (
				<div
					ref={ref} // We need a reference to this element to load the TrustBox in the effect.
					className="trustpilot-widget"
					data-locale="en-GB"
					data-template-id={templateId}
					data-businessunit-id={businessUnitId}
					data-style-height={dataHeight ?? "28px"}
					data-style-width="100%"
					data-theme={theme}
				>
					<ExternalLink href={fallbackLinkUrl} label={fallbackLinkText ?? "See our Trustpilot score"} />
				</div>
			);
		}

		default:
			return null;
	}
}