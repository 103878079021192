import React from "react";
export * from "libraryComponents/Layout";

import * as LibComponents from "libraryComponents/Layout";

//**** Custom && Overrides (replaces the export above) ****

export const FormGroup = ({ className, ...otherProps }) => (
	<LibComponents.FormGroup
		className={[className].filter(Boolean).join(" ")}
		classNameHasError="has-error"
		{...otherProps}
	/>
);
