import React from "react";

import PageLayout from "siteLayouts/PageV2";

import { ContainerMaxWidth, Panel, PanelSection, PanelHeader } from "siteComponentsLibrary/Layout";
import { HyperlinkRoute } from "siteComponentsLibrary/Buttons";
import { TelematicsCard } from "siteComponentsLibrary/Cards";
import { ListItemsFlex, AppLinks } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("telematics");

const TelematicsLanding = (props) => {

	return (
		<PageLayout pageTitle={assets.pageTitle} headingText={assets.headingText}>

			<ContainerMaxWidth>
				<Panel 
					header={
						<PanelHeader
							leftContent="What is Freedom to Drive?"
							rightContent={
								<img
									className="img-fluid"
									src={assets.logoImage}
									alt={assets.imageAlt}
								/>
							}
						/>
					}
				>
					<PanelSection>
						<div>
							With our 'black box' telematics policy, we'll offer you a competitive premium
							in exchange for reviewing your driving behaviour whilst you're insured with us.
						</div>
						<div>
							You'll need to install a small device or give us access to your car data directly{" "}
							<strong>for the duration of your insurance policy</strong>.
						</div>
					</PanelSection>
					<PanelSection
						className="panel-header"
						heading="Here's how it works"
					>
						<div className="row align-items-md-stretch mb-4">
							{assets.data.howItWorks.map((step, i) => {
								return (
									<TelematicsCard
										key={step.stepId}
										title={`Step ${step.stepId}`}
										data={step}
									/>
								);
							})}

						</div>

					</PanelSection>

					<PanelSection>
						<div>
							<div className="mb-2"><strong>Once you get your device delivered:</strong></div>
							<ListItemsFlex
								className="my-3"
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={assets.data.whatToDo}
							/>
						</div>
					</PanelSection>

					<PanelSection
						className="panel-header"
						heading="Some important information about Freedom to Drive"
					>
						<div>
							The device, or car data, sends us regular information about your car which we use to understand how your car is being driven, the
							miles travelled and where it's parked.
						</div>
						<div className="mb-3">
							We protect our premiums by encouraging safer driving. You’ll see hints and tips on the app to improve your score and need to avoid
							high risk scores.
						</div>
						<div>
							<strong className="d-block">
								Safer drivers avoid the high risk score areas by:
							</strong>
							<ListItemsFlex
								className="my-3"
								bullet={<span className="bi bi-circle-fill"></span>}
								listData={assets.data.avoidHighRisk}
							/>
						</div>
						<div>
							It’s important for you to understand that failure to install the telematics device correctly, or to drive safely as outlined, could result in
							the cancellation of your policy. If your policy is cancelled by us, it’s likely you’ll find it more difficult to get reasonably priced insurance
							in the future.
						</div>
					</PanelSection>

					<PanelSection
						className="panel-header"
						heading="Get the app"
					>
						<div className="mb-2">
							Click on the link below to get the app for your device.
						</div>
						<div className="d-flex">
							<AppLinks
								apple={assets.appLinks.apple}
								google={assets.appLinks.google}
							/>
						</div>
					</PanelSection>
				</Panel>

				<Panel
					className="has-thick-border"
				>
					<PanelSection>
						When you buy Freedom to Drive you'll also be able to choose from our great range of
						optional products. Take a look at our{" "}
						<HyperlinkRoute
							route="/optional-cover"
							className="btn btn-link btn-link-inline">
							Optional Cover
						</HyperlinkRoute>.
					</PanelSection>
				</Panel>
			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default TelematicsLanding;
