import React from "react";

import { NavLink, ExternalLink } from "siteComponents";

const NavDropdownContent = (props) => {
	const { linkText, className, items, id } = props;

	const linkClassName =
		!className || className === undefined
			? "nav-link dropdown-toggle"
			: `dropdown-toggle ${className}`;

	return (
		<>
			<a
				className={linkClassName}
				href="#"
				id={id}
				role="button"
				data-bs-toggle="dropdown"
				aria-expanded="false"
			>
				{linkText}
			</a>
			<ul className="dropdown-menu text-end" aria-labelledby={id}>
				{items.map((x, i) => {
					if (x.label === "---") {
						return (
							<li key={i}>
								<hr className="dropdown-divider" />
							</li>
						);
					}

					if (x.type === "heading") {
						return (
							<li key={i} className="nav-section-header text-uppercase px-3">
								{x.label}
							</li>
						);
					}

					if (x.routepath.indexOf("http") > -1) {
						return (
							<li key={i} className="">
								<ExternalLink
									href={x.routepath}
									label={x.label}
									className="dropdown-item"
								/>
							</li>
						);
					}

					return (
						<li key={i}>
							<NavLink
								label={x.label}
								routepath={x.routepath}
								className="dropdown-item"
							/>
						</li>
					);
				})}
			</ul>
		</>
	);
};

const NavDropdown = (props) => {
	const { isDiv, navItemClass, linkText, className, items, id } = props;
	if (!id) throw `Error in NavDropdown -- missing "id"`;

	const navClass =
		!navItemClass || navItemClass === undefined
			? "nav-item dropdown"
			: `nav-item dropdown ${navItemClass}`;

	if (isDiv) {
		return (
			<div className={navClass}>
				<NavDropdownContent
					linkText={linkText}
					className={className}
					items={items}
					id={id}
				/>
			</div>
		);
	}

	return (
		<li className={navClass}>
			<NavDropdownContent
				linkText={linkText}
				className={className}
				items={items}
				id={id}
			/>
		</li>
	);
};

export default NavDropdown;
