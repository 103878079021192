// https://dev.azure.com/FreedomServicesGroup/Odyssey/_workitems/edit/1620/
import siteServices from "siteManagers/services";

//
const sampleRequest = {
	PolicyType: "PC",
	WebReference: "12345-12345-12345",
	TokenisationId: "123456",
	Amount: 100,
	Redirects: {
		PaymentSuccess: "http://localhost/success",
		Back: "http://localhost/back",
		PaymentError: "http://localhost/error",
	},
};

const convertToStr = (num) => String(num);

const paymentLauncher = (args) => {
	if (!args) return;
	const {
		balance,
		credentials,
		amount,
		urlPaymentSuccess,
		urlBack,
		urlPaymentError,
		sourceOfEnquiry,
	} = args;

	const IsPartPayment = -1 * balance.FoundBalance !== amount;

	const urlOptionalRedirects = Object.fromEntries(
		[
			["refused", "urlRefused"],
			["timeout", "urlTimeout"],
			["referred", "urlReferred"],
			["declined", "urlDeclined"],
			["technicalError", "urlTechnicalError"],
		]
			.map(([_key, _arg]) => (_arg in args ? [_key, args[_arg]] : undefined))
			.filter((x) => x)
	);

	console.log("DEBUG", "paymentLauncher args", args);

	//FoundAddress
	const payload = {
		RequestType: "ADHOC",
		PolicyType: balance.PolicyType,
		WebReference:
			"WebReference" in balance
				? balance.WebReference
				: credentials.WebReference,
		TokenisationId: balance.TokenisationId,
		AmountToPay: amount,
		IsPartPayment,
		ProposerId: balance.ProposerId,
		PolicyId: balance.PolicyId,
		PolicyStatus: balance.PolicyStatus,
		SourceOfEnquiry: sourceOfEnquiry,
		Redirects: {
			PaymentSuccess: urlPaymentSuccess,
			Back: urlBack,
			PaymentError: urlPaymentError,
			...urlOptionalRedirects,
		},
		CardholderAddress: {
			HouseNameOrNumber: balance.FoundAddress.HouseNameOrNumber,
			AddressLine1: balance.FoundAddress.AddressLine1,
			AddressLine2: balance.FoundAddress.AddressLine2,
			Postcode: balance.FoundAddress.Postcode,
		},
	};

	console.log("DEBUG", "payload", payload);
	return siteServices.payment.RegisterAdhocPayment(payload);
};

export default paymentLauncher;
