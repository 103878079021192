import React, { useEffect, useState } from "react";
import siteServices from "siteManagers/services";
import { useLocation } from "react-router-dom";

import { Markup } from "interweave";

const SiteBanner = (props) => {
	const {
		siteType,		
	} = props;

	if (!siteType) throw `Error in SiteBanner -- missing siteType`;

	const [bannerData, setBannerData] = useState();
	const { pathname } = useLocation();

	useEffect(() => {
		// return;
		const payLoad = { SiteType: siteType };
		siteServices.getSiteAlerts(payLoad).then((response) => {
			if (!response) return;
			if (!response.ShowAlert) return;
			if (!response.Alerts) return;
			if (response.Alerts.length === 0) return;

			const { Alerts: bannerData } = response;

			setBannerData(bannerData);
		});
	}, [pathname]);

	if (!bannerData) return null;

	return (
		<div className="lctr-banner site-alerts">
			{bannerData.map((x, i) => {
				const alertType = ["alert", `alert-${x.MessageType}`]
					.filter((x) => x)
					.join(" ");

				return (
					<div className={alertType} key={i}>
						<Markup content={x.MessageContent} />
					</div>
				);
			})}
		</div>
	);
};

export default SiteBanner;
