import React from "react";

import PageLayout from "siteLayouts/Page";

import { ContainerMaxWidth, Panel, PanelSection } from "siteComponentsLibrary/Layout";
import { ExternalLink } from "siteComponentsLibrary/Buttons";
import { TelephoneNumber, EmailLink } from "siteComponentsLibrary/Display";

const assets = require("siteAssets/pageManager").default("make-a-complaint");

const FosInfoLine = (props) => {
	const { title, children } = props;
	
	return (
		<>
			<dt className="col-5 col-sm-3 col-lg-2">{title}</dt>
			<dd className="col-7 col-sm-9 col-lg-10">
				{children}
			</dd>
		</>
	);
}

const FosInfo = (props) => {
	const fos = require("config").default.fos;

	return (
		<dl className="row g-2">
			<FosInfoLine title="Website">
				<ExternalLink
					href={fos.website}
					label={(<strong>{fos.website}</strong>)}
				/>
			</FosInfoLine>			
			<FosInfoLine title="Telephone">
				Call <strong>{fos.phoneNumbers[0]}</strong> or <strong>{fos.phoneNumbers[1]}</strong>{" "}
				{fos.openingHours}.
			</FosInfoLine>			
			<FosInfoLine title="Email">
				<EmailLink emailAddress={fos.email} />
			</FosInfoLine>			
			<FosInfoLine title="Write">
				<address>
					{fos.address.map((a, i) => {
						return <span key={i} className="d-block">{a}</span>
					})}
				</address>
			</FosInfoLine>			
		</dl>
	)
}

const MakeAComplaintView = (props) => {
	return (
		<PageLayout
			pageTitle={assets.pageTitle}
			headingText={assets.headingText}
		>
			<ContainerMaxWidth>
				<Panel header={assets.panelHeader}>
					<PanelSection heading="How to make a complaint">
						<div>
							Freedom Brokers Ltd aim to provide you with the highest standards of
							service, however, there may be an occasion when you feel you have
							cause for complaint. If this is the case, please be assured our
							staff will do all they possibly can to resolve the problem and
							ensure you receive a prompt and fair response to your complaint.
						</div>
						<div>
							We have a formal complaint handling procedure in place.
						</div>
					</PanelSection>

					<PanelSection heading="Our promise to you">
						<div>
							Our complaints procedure is designed to ensure that each complaint
							is dealt with fairly and to your satisfaction. We aim to
							acknowledgement your complaint within 5 working days of your
							complaint being received.
						</div>
						<div>
							We are committed to resolving your complaint whenever possible
							through our complaints procedure, however, if our investigations
							have not been completed within 8 weeks, we will outline our findings
							to you in writing and explain why we are unable to resolve your
							complaint, as well as highlighting your right to refer your
							complaint to the Financial Ombudsman Service.
						</div>
						<div>
							Should you wish to discuss any concerns you may have, please
							telephone us on{" "}
							<TelephoneNumber 
								phonenumber={require("config").default.phoneNumbers.complaints} 
								isLink={false} 
							/>
							, or alternatively, email us at{" "}
							<EmailLink 
								emailAddress={require("config").default.emailAddresses.complaints} 
							/>.
						</div>
						
					</PanelSection>
					<PanelSection>
						<div>
							The Financial Ombudsman Service can investigate complaints up to six
							years from the date of the problem happening or within three years
							of when you found out about the problem.
						</div>
						<div>
							If you wish to refer your complaint to the Financial Ombudsman
							Service, this must be done within six months of our final response
							to your complaint. If you would like more information about the
							Financial Ombudsman Service their contact details are as follows:
						</div>

						<div className="pt-3">
							<FosInfo />							
						</div>

						<div>
							Insurance policies administered by Freedom Brokers Ltd are covered
							by the Financial Services Compensation Scheme (FSCS). You may be
							entitled to compensation from the scheme if the insurance
							underwriter cannot meet its obligations. Further information about
							compensation scheme arrangements is available from the FSCS website{" "}
							<ExternalLink
								href="http://www.fscs.org.uk"
								label="www.fscs.org.uk"
							/> or write to{" "}
							<strong>
								Financial Services Compensation Scheme, 10th Floor Beaufort House,
								15 St Botolph Street, London, EC3A 7QU
							</strong>
						</div>
					</PanelSection>
				</Panel>

			</ContainerMaxWidth>

		</PageLayout>
	);
};

export default MakeAComplaintView;
