import PropTypes from "prop-types";
import React from "react";

const Button = (props) => {
	const {
		label,
		enabled = true,
		className = "",
		classNameDisabled = "disabled",
		type = "button",
		...otherProps
	} = props;

	if (!label) return null;

	return (
		<button
			disabled={!enabled}
			type={type}
			className={`${className} ${enabled ? "" : classNameDisabled}`}
			{...otherProps}
		>
			{label}
		</button>
	);
};

// const Button = (props) => {
// 	const {
// 		label = "",
// 		onClick,
// 		className,
// 		classNameDisabled,
// 		style,
// 		enabled = true,
// 		type = "button",
// 		title,
// 	} = props;

// 	//const basicStyle = { cursor: "pointer", ...style };

// 	// if (!enabled)
// 	// 	return (
// 	// 		<button
// 	// 			type="button"
// 	// 			className={`${className} ${classNameDisabled}`}
// 	// 			title={title}
// 	// 		>
// 	// 			{label}
// 	// 		</button>
// 	// 	);

// 	return (
// 		<button
// 			disabled={!enabled}
// 			type={type}
// 			onClick={onClick}
// 			className={className}
// 			style={basicStyle}
// 			title={title}
// 		>
// 			{label}
// 		</button>
// 	);
// };

export default Button;
Button.displayName = "Button";
Button.propTypes = {};
