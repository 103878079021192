import React, { useState } from "react";
import config from "config";
import history from "siteHistory";

import "siteAssets/css/siteStyles";
import logo from "siteAssets/images/logo.png";

import SiteMap from "./sidepanels/Sitemap";
import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import ScreenFinder from "./ScreenFinder";

import { CookieConsent } from "siteComponents";

const SiteLayout = (props) => {
	const { children, siteClassName, headerImages, dataCy } = props;

	const [showScreenFinder, setShowScreenFinder] = useState(config.isDev);

	const goToRoute = (route) => history.push(route);
	
	const _className = [
		"layout-container flex-shrink-0 d-flex flex-column min-vh-100",
		"layout-wide",
		siteClassName
	]
		.filter(Boolean)
		.join(" ");
	
	return (
		<>
			<main className={_className} data-cy={dataCy}>

				<CookieConsent />

				<SiteHeader goToRoute={goToRoute} headerImages={headerImages} />
				
				<div className="layout-main" data-cy="layout-main">
					<div className="container-fluid">
						{children}
					</div>

					<ScreenFinder showFinder={showScreenFinder} setShowScreenFinder={setShowScreenFinder} />					
				</div>

				<SiteFooter goToRoute={goToRoute} />

			</main>
		</>
);
};

export default SiteLayout;
SiteLayout.displayName = "Site Layout";
// Layout.propTypes = {
// 	children: PropTypes.any.isRequired,
// };
