const baseArgs = {
	isProd: true,	
	showFaqs: false,	
	google: { gtmId: "GTM-N3KK6Q6" },
	callbackData: {
		endPoint: "https://ag5sh97b30.execute-api.eu-west-1.amazonaws.com/Prod",
		apiKey: "YSP2W2Z11p3N6AZu9oC395NdQExrPiePakWR00QP",
	},
	contactusData: {
		endPoint:
			"https://4qbnjdxz37.execute-api.eu-west-1.amazonaws.com/freedom-live",
		apiKey: "jZvVUf4Mws6VUFXwTexUe6xn14T4bBVE1j2BbVmA",
	},
	AxiosData: {
		endPoint:
			"https://4qbnjdxz37.execute-api.eu-west-1.amazonaws.com/freedom-live",
		apiKey: "jZvVUf4Mws6VUFXwTexUe6xn14T4bBVE1j2BbVmA",
		dateHelpers: require("@library/common/helpers/misc/date").default,
	},
	liveChat: {
		useLiveChat: true,
		environment: "prod-euw2",
		deploymentId: "61ec1566-01d4-47cd-adbd-09f7971e2ed6",
	},
	endPointsPayment: {
		baseUrl: `https://1z6xkavxp8.execute-api.eu-west-1.amazonaws.com/freedom-live`,
		apiKey: "8bVlyNUXPSH2uGMNzU3B1DGCw0kZOPm18yaTzkG4",
	},
};
const baseConfig = require("../base").default(baseArgs);

export default baseConfig;
