import React from "react";
import { Helmet } from "react-helmet";

import PageHeader from "./PageLayouts/PageHeader";

const PageLayout = (props) => {
	const {
		children,
		showReturnHome,
		pageTitle,
		pageClass,
		headingText,
		subHeadingText,
		introText,
		firstRowCards,
		secondRowCards,
		className,
	} = props;
	
	const _pageClass = [
		pageClass
	]
		.filter(Boolean)
		.join(" ");

	const _className = [
		"main-panel",
		!headingText ? "pt-3 pt-md-4" : "",
		className
	]
		.filter(Boolean)
		.join(" ");


	return (

		<div className={_pageClass}>

			<Helmet>
				<title>{!pageTitle ? headingText : pageTitle}</title>
			</Helmet>

			{headingText && (
				<PageHeader
					mainHeading={headingText}
					subHeading={subHeadingText}
					introText={introText}
					firstRowCards={firstRowCards}
					secondRowCards={secondRowCards}
				/>
			)}
			
			
			<div className={_className}>			
				{children}
			</div>
		</div>
	);
};

export default PageLayout;
