import React from "react";

import { ExternalLink, HyperlinkRoute } from "siteComponentsLibrary/Buttons";
import { EmailLink } from "siteComponentsLibrary/Display";

const ClaimTableRow = (props) => {

    const { 
        productType, 
        contactNumber, 
        email, 
        contactUrl, 
        contactUrlText, 
        moreInfoRoute, 
        moreInfoText, 
        previousClaimNumbers 
    } = props;

    return (
        <>
            <dt className="col-md-5">{productType}</dt>
            <dd className="col-md-7">
                {contactNumber && (
                    <span className="d-block mb-1">
						{contactNumber}
					</span>
                )}

                {email && (
                    <span className="d-block mb-1">
                        <EmailLink emailAddress={email} />
					</span>
                )}

                {contactUrl && (
                    <span className="d-block mb-1">
						<ExternalLink href={contactUrl} label={contactUrlText} />
					</span>
                )}

                {moreInfoRoute && (
                    <span className="d-block">
						<HyperlinkRoute className="card-link" route={moreInfoRoute}>
							{moreInfoText ? moreInfoText : "More info"}
						</HyperlinkRoute>
					</span>
                )}

            </dd>
            {previousClaimNumbers && (
                <dd className="col-12">
                    <div className="alert alert-success thin">
                        <div className="small">
                            <span className="mt-1">For policies purchased before {previousClaimNumbers.endDate}, please call </span>
                            {previousClaimNumbers.claimNumbers.map((num, i) => {
                                return (
                                    <span key={i} className="">
								    {i > 0 ? " or " : ""}{num}
							    </span>
                                );
                            })}
                        </div>
                    </div>
                    
                </dd>
            )}
        </>
    );

}

export default ClaimTableRow;